.btn-search-modalAR-container
{
    display: flex;
    justify-content: space-between;
    .btn-search-modalAR
    {
        width:200px ;
        height:40px ;
        background:#036402 ;
        border-radius: 10px;
        color: white;
        font-weight: bold;
        font-size: 12px;
        border: none;
        margin-bottom: 28px;
        
    }
}
