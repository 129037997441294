.cj-people-managment
{
    // @media (max-width:1024px) {
    //     .top-box
    //     {
    //         display: flex;
    //         flex-direction: column!important;
    //     }
    // }
    @media (max-width:926px) {
        .date-container
        {
            flex-direction: column;
        }
         
        .top-box
        {
            
            flex-direction: column!important;
            justify-content: center!important;
            .date{
                flex-direction: column;
                .card
                {
                    width: 100%!important;
                }
            } 
            
        }
        .selects-container
        {
            flex-direction: column;
            select
            {
                width: 100%!important;
                margin-left: 0px!important;
                margin-right: 0px!important;
                
            }
            button
            {
                width: 100%!important;
                margin-left: 0px!important;
                margin-right: 0px!important;
            }
            .input-wrapper
            {
                width: 100%!important;
                margin-left: 0px!important;
                margin-right: 0px!important;
            } 
            .title
            {margin-right: 0px;
            }
            .date-container{
                margin-left: 5px;
                margin-right: 5px;
            }
            .card
            {
                width: 100%!important;

            }

        }
    }
    .top-box
    {
        justify-content:flex-start;
        width: 100%;
        overflow-x: scroll;
        display: flex;
        flex-direction: row;
        .date
        {
            display: flex;
            align-items: center;
            .title
            {
                margin-right: 50px;
                display: flex;
                justify-content: center;
                font-weight: 500;
                font-size: 14px;
                width: 100px;
            }
            .card
            {
                width: auto;
                height: auto;
                .date-container
                {
                    padding:21px ;
                    display: flex;
                    .calendar-container
                    {
                        display: flex;
                        .text
                        {
                            color:#308ac9 ;
                            padding-right: 10px;
                        }
                        input
                        {
                            border:none;
                            font-weight: 500;
                            font-size: 16px;
                            width: 120px;
                        }
                    }
                }
            }
            
            
        }
        
        // selects container
        .container-flex
        {
            

            .selects-container
            {
                padding-bottom: 26px;
                display: flex;
                //IMPUT SEARCH
                .input-wrapper {
                    position: relative;
                    width: 180px;
                    height: 40px;
                    margin-left: 20px;
                    margin-right: 20px;
                        .input {
                            box-sizing: border-box;
                            color: #191919;
                           
                            width: 100%;
                            height: 40px;
                        }
                        .input-icon {
                            color: #191919;
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            left: unset;
                            right: 12px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                  }
                  
                    select
                    {
                        border:1px solid #e3e3e3;
                        border-radius: 10px;
                        height: 40px;
                        width: 180px;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    button
                    {
                        width:180px;
                        height: 40px;
                        color:white;
                        border-radius: 10px;
                        border: none;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    display: flex;
                  }
        }
        
              
        
    
    }
    .btn
    {
        color:white;
        border-radius:10px;
        width: 80px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
    .profile-image
    {
        
        width: 40px;
        height: 40px;
        img
        {
            border-radius:150px;
            width: 40px;
            height: 40px;
            object-fit: cover;
        }
    }
    .rowdata
    {
        font-size: 11px;
        text-align: center;
    }
    .header-table
    {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
    }
    .p-datatable 
    {
        .p-datatable-tbody > tr > td
        {
            text-align: center;
        }
    }
}