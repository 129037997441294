.position-main
{
    display: flex;
    justify-content: center;

    .main-email
    {
        
        width: 812px;
        
        border: 1px solid #1d1d1b;
        .content
        {
            padding-left: 124px;
            padding-right: 124px;
        }
        .header
        {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding-top: 56px;
            .logo
            {
                
                width: 225px;
                height: 53px;
                clip-path: inset(0 0 0 0);
                object-fit: none;
            }
            .brand
            {
                width: 95px;
                height: 77px;
                // object-fit: none;
            }
        }
        .body
        {
            width: 466px;
            .title
            {
                font-weight: 500;
                color:#1d1d1b;
                font-size: 24px;
                margin-top:58px ;
                margin-bottom: 43px;
            }
            .text
            {
                font-size: 12px;
                color: #1d1d1b;
            }
            .url
            {
                margin-top:32px ;
                margin-bottom: 32px;
                color: red;
                font-size: 12px;
                width:519px!important ;
            }
        }
        .footer
        {
        
            
            margin-top: 96px;
            background: white;
            .footer-content
            {
                background:#0271c1 ;
                height: 33px;
                width: 100%;
            }
        }

    }
}