
.cardActivation{
    margin-top:64px;
    width:300px;
    height: 150px;
    padding-left:31px;
    padding-right:31px;
    padding-top:35px;
    padding-bottom:35px;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.1);

}

.titleCard{
    font-size:20px;
}

.boldText{
    font-size:30px;
    font-weight:bold;
}

.flexCard{
    display:flex;
    justify-content:space-between;

    img{
        width:30px;
        height:30px;
    }
}
