
.Modal-container-contract
{
    .title-container-contractExpiration
    {
        display: flex;
        justify-content: space-between;
    }
    .title-contractExpiration
    {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
    }
    .profile-image
    {
        display: flex;
        align-items: center;

        height: 40px;
        margin-bottom: 40px;
        img
        {
            border-radius:150px;
            width: 40px;
            height: 40px;
            object-fit: cover;
            
        }
        .text-image
        {
            margin-left: 20px;
        }
    }
    .contaier-inputs
    {
        display: flex;
        min-width:800px ;
        margin-bottom: 20px;
        .input-label-modal
        {
            margin-right: 20px;
            .text-top-input
            {
                font-size: 12px;
                font-weight: bold;
                padding-bottom: 10px;
            }
            .input-blocked
            {
                height: 40px;
                border: 1px solid #e3e3e3;
                background:#f8f8fc;
                min-width: 250px;
                border-radius: 10px;
                padding-left:20px ;
                color:#b3b3b3;

            }
            .input-clasic
            {
                height: 40px;
                border: 1px solid #e3e3e3;
                min-width: 250px;
                border-radius: 10px;
                padding-left:20px ;
                color:#b3b3b3;
                padding-right: 10px;
            }
            select
            {
                height: 40px;
                border: 1px solid #e3e3e3;
                min-width: 250px;
                border-radius: 10px;
                padding-left:20px ;
                padding-right: 10px;
            }
        }
    }
}