@import '../../../../sass/main.scss';

.div-selects-activations{
    margin-top: 20px;
    margin-bottom: 32px;
}

.div-details-activations{
    text-align: right;
    padding-top: 7px;
}

.sub-selects-activations .container-select-absolute{
    margin-top: 22px;
}

.div-buttons-activations{
    position: absolute;
    right: 8px;
    bottom: 0px;
    margin-bottom: 20px;
}

.div-buttons-activations button{
    margin-left: 10px;
}

@media screen and (max-width: 767px) {
    .div-buttons-activations{
        text-align: right;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.inputName-small-word {
    /* font-size: 16px; */
    line-height: 2 !important;
}
.inputSelected-relativeSelect p {
    line-height: 2 !important;
}
