.Modal__background{
    opacity: 0.7;
    margin-left: 0px !important;
    z-index: 3;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
}
.Modal_container{
    z-index: 5;
    position: absolute;
    max-width: 500px;
    width: 100%;
    height: 364px;
    border-radius: 10px;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 30px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .modal-title {
        top: 40px;
        left: 35px;
        position: absolute;
        font-family: Poppins;
        font-size: 20px;
        color: #000000;
        text-decoration: none solid rgb(0, 0, 0);
        font-weight: bold;
    }
    .fila{
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        p:first-child{
            font-size: 14px;
        }
        p:last-child {
            font-size: 18px;
            color: #036402;
            font-weight: bolder;

        }
    }

}