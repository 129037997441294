.header-color
    {
        background: #0600a8!important;
    }
.sub-titles-resources-gestion
{
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 20px;
}
.sub-titles-resources-gestion-container
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn-resources-gestion
{
    width: 40px;
    height: 40px;
    background: #308ac9;
    border-radius: 8px;
    margin-bottom: 20px;
}
.modal-asign-container
{
    padding-left: 75px;
    padding-right: 75px;
    .title
    {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 31px;
    }
    .input-container
    {
        margin-bottom: 20px;
        .sub-title
        {
            font-size:12px;
            font-weight: 600;

        }
        .input
        {
            min-width: 450px;
            width: 100%;
            width: 40px;
        }
        .bloked
        {
            min-width: 450px;
            width: 100%;
            width: 40px;
            color: #e3e3e3!important;
        }
        textarea
        {
            height: 100px;
            width: 93%;
            min-width: 450px
        }
    }
    .btns-container-resources-gestion
    {
        display: flex;
        justify-content: space-between;
        margin-top: 45px;
        button
        {
            width: 200px;
            height: 40px;
            border-radius: 10px;
            font-weight: bold;
            font-size: 12px;
        }
    }
}
@media (max-width:1024px) {
    .modal-asign-container
    {
        padding-top:100px;
        padding-left: 10px;
        padding-right: 10px;
        
    }
}