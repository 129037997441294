@import "../../../../../../sass/main.scss";
.p-paginator {
  display: flex !important;
  width: 100% !important;
}
.p-paginator .p-paginator-last {
  display: none !important;
}
.p-paginator .p-paginator-next {
  min-width: none;
  width: 3rem;
}
.p-paginator-prev{
  width: 3rem;
}

.managementPersons {
  @media (max-width: 480px) {
    width: 100%;
    height: auto;
  }
  height: calc(100vh - 40px);
  max-width: 4000px;
}
.managementPersons-title {
  font-family: Poppins;
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  text-decoration: none solid rgb(0, 0, 0);
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #a80038 0%, #ff2631 100%);
  border-image-slice: 1;
  padding-bottom: 20px;
}
.managementPersons-container {
  @media (max-width: 480px) {
    width: 100%;
  }
  max-width: 4000px;
  padding: 20px;
  border-radius: 10px;
  fill: #ffffff;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.managementPersons-header-principal {
  @media (max-width: 4000px) {
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: auto;
  }
}
.managementPersons-header-principal {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  
}

.managementPersons-input-select {
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.managementPersons-header-principal input {
  width: 150px;
  height: 40px;
  padding: 0px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.managementPersons-header-principal select {
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
  }
  width: 150px;
  height: 40px;
  padding: 0px 8px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 12px;
  color: #121212;
  text-decoration: none solid rgb(18, 18, 18);
  margin-left: 10px;
}
.managementPersons-button-blue {
  @media (max-width: 480px) {
    width: 120px;
  }
  width: 150px;
  height: 40px;
  border-radius: 10px;
  background-color: #308ac9;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #f8f8fc;
  text-decoration: none solid rgb(248, 248, 252);
  text-align: center;
  margin: 0;
}
.managementPersons-button-green {
  @media (max-width: 480px) {
    width: 120px;
  }
  width: 150px;
  height: 40px;
  border-radius: 10px;
  background-color: #036402;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #f8f8fc;
  text-decoration: none solid rgb(248, 248, 252);
  text-align: center;
  margin: 0;
}
.managementPersons-header-principal-button {
  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
  display: flex;
  width: 310px;
  height: 40px;
  justify-content: space-between;
  margin-left: 12px;
  gap: 10px;
}
.managementPersons-header-secundario {
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  max-width: 4000px;
  overflow-x: overlay;
  gap: 20px;
}
.header-secundario-title {
  @media (max-width: 768px) {
    display: flow-root;
  }
  display: flex;
  align-items: center;
}
.managementPersons-header-secundario-date {
  @media (max-width: 768px) {
    width: 100%;
    gap: 10px;
    margin-bottom: 0px;
    justify-content: center;
  }
  width: 350px;
  display: flex;
  align-items: center;
  height: 50px;
  gap: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 30px;
}
.managementPersons-header-secundario-date label {
  @media (max-width: 768px) {
    font-size: 10px;
  }
  font-size: 14px;
  margin: auto 0;
}
.managementPersons-header-secundario-date input {
  @media (max-width: 768px) {
    font-size: 10px;
  }
  font-size: 14px;
  font-weight: 700;
  width: 120px !important;
}
.managementPersons-header-secundario-title label {
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  margin-bottom: 30px;
  width: 80px;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.managementPersons-header-secundario input {
  border: none;
  width: 100px;
}
.managementPersons-header-secundario-select {
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
  }
  display: flex;
  justify-content: right;
}
.managementPersons-header-secundario-select select {
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    margin-bottom: 10px;
  }
  width: 150px;
  height: 40px;
  padding: 0px 8px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 12px;
  color: #121212;
  text-decoration: none solid rgb(18, 18, 18);
  margin-left: 10px;
}
.drop {
  color: #0ec927 !important;
  text-transform: uppercase;
  font-weight: 700;
  margin: auto 0;
}
.high {
  color: #ff2631 !important;
  text-transform: uppercase;
  font-weight: 700;
  margin: auto 0;
}

// @media (max-width:1080px){
//   .parameters-table{
//   font-size: 12px !important;
// }
// }
.parameters-table {
  @media (max-width: 480px) {
    width: 100%;
  }
  font-size: 6px !important;
  max-width: 4000px;
  overflow: auto;
}
.Activo {
  background-color: #0ec927 !important;
  border-radius: 10px;
  color: white;
  margin: auto 0;
  padding: 5px 6px;
  font-weight: 600;
}
.Inactivo {
  background-color: black !important;
  border-radius: 10px;
  color: white;
  margin: auto 0;
  padding: 5px 6px;
  font-weight: 600;
}
.Bloqueado {
  background-color: #ff2631 !important;
  border-radius: 10px;
  color: white;
  margin: auto 0;
  padding: 5px 6px;
  font-weight: 600;
}
.imgTemplate img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
