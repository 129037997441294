.homeFiles {
    .main-bot {
        background-color: #246796 !important;

        div {
            font-size: 12px !important;
            font-weight: 600 !important;
        }
    }

    .prep-bot {
        background-color: #ff263126 !important;

        div {
            font-size: 12px !important;
            font-weight: 600 !important;
            color: black !important;
        }
    }

    .pos-bot {
        background-color: #11ab7726 !important;

        div {
            font-size: 12px !important;
            font-weight: 600 !important;
            color: black !important;
        }
    }

    .dth-bot {
        background-color: #308ac926 !important;

        div {
            font-size: 12px !important;
            font-weight: 600 !important;
            color: black !important;
        }
    }

    .gpon-bot {
        background-color: #f5a72a26 !important;

        div {
            font-size: 12px !important;
            font-weight: 600 !important;
            color: black !important;
        }
    }

    .date-main div {
        font-weight: 600;
    }

    .prep-father div {
        font-weight: 900;
        font-size: 20px;
    }

    .pos-father div {
        font-weight: 900;
        font-size: 20px;
    }

    .dth-father div {
        font-weight: 900;
        font-size: 20px;
    }

    .gpon-father div {
        font-weight: 900;
        font-size: 20px;
    }

    .prep-child div {
        font-weight: 600;
    }

    .pos-child div {
        font-weight: 600;
    }

    .dth-child div {
        font-weight: 600;
    }

    .gpon-child div {
        font-weight: 600;
    }

    .bold-600 {
        font-weight: 600;
    }

    .bot-button {
        height: 40px;
        border-radius: 10px;
        color: #ffffff;
        padding: 8px;
        text-align: center;
    }

    .botcolor-de9000 {
        background-color: #de9000;
    }

    .botcolor-036402 {
        background-color: #036402;
    }

    .botcolor-308ac9 {
        background-color: #308ac9;
    }

    .botcolor-0600a8 {
        background-color: #0600a8;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        text-align: left;
        border: 1px solid #e9ecef;
        border-width: 1px;
        background: #fff;
        padding: 1rem 1rem;
        font-size: 12px;
        text-align: center;
    }
}

.colorGreen {
    color: #036402;
    font-weight: 800;
}

.colorRed {
    color: red;
}

.colorBlue {
    color: #0271c1;
    font-weight: 800;
}

input[type=checkbox]:checked {
    /*CSS after CHECKED*/
    color: #ffd369;
}

input[type="checkbox"]:checked+label::after {
    content: '';
    position: absolute;
    width: 2.2ex;
    height: 1ex;
    background: rgba(0, 0, 0, 0);
    top: .9ex;
    left: 0.4ex;
    border: 3px solid #036402;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

input[type="checkbox"] {
    line-height: 2.1ex;
}

input[type="radio"],
input[type="checkbox"] {
    position: absolute;
    left: -999em;
}

input[type="checkbox"]+label {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.button-header {
    display: flex;
    justify-content: flex-end;
}

@media (max-width:765px) {
    .button-header {
        justify-content: center;
    }

    .btn-header-responcive {
        width: 100% !important;

        .btn-blue {
            width: 100%;

        }

    }
}

.btn-header-responcive {
    width: 200px;
}


input[type="checkbox"]+label::before {
    content: "";
    display: inline-block;
    vertical-align: -25%;
    height: 3ex;
    width: 3ex;
    background-color: white;
    border-radius: 4px;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
    margin-right: 0.5em;
}



