.container-flex
        {
            .filter
            {
                font-weight: bold;
                display: flex;
                align-items: center;
            }

            .selects-container
            {
                padding-bottom: 26px;
                display: flex;
                //IMPUT SEARCH
                .input-wrapper {
                    position: relative;
                    width: 180px;
                    height: 40px;
                    margin-left: 20px;
                    margin-right: 20px;
                        .input {
                            box-sizing: border-box;
                            color: #191919;
                           
                            width: 100%;
                            height: 40px;
                        }
                        .input-icon {
                            color: #191919;
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            left: unset;
                            right: 12px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                  }
                  
                    select
                    {
                        border:1px solid #e3e3e3;
                        border-radius: 10px;
                        height: 40px;
                        width: 180px;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                   
                    display: flex;

                  }
        }
        button
        {
            width:180px;
            height: 40px;
            color:white;
            border-radius: 10px;
            border: none;
            margin-left: 20px;
            margin-right: 20px;
            font-weight: bold;
        }
        .top-container-template-generate
        {
            display: flex;
            justify-content: space-between;
        }