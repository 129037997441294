
.rowsInfo {
    width: 100%;
    display: flex;
    justify-content:space-around;
}

.cardSpace {
    display: flex;
    gap: 150px;
}

@media (max-width: 765px)
{
    .cardSpace {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}
