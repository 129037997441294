
.graphPieContainer{
    margin-top: 31px;
    margin-left: 20px;
    //padding:15px;
    padding-top: 15px;
    padding-left: 25px;
    padding-right:25px;
    width: 313px;
    height: 401px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);


    p{
        text-align:center;
        font-size:18px;
    }

    .centerText{
        position:absolute;
        font-size:20px;
        font-weight: bold;
    }

}

.flexLegend{
    display:flex;
    justify-content: space-between;
    width:100%;
    margin-bottom:-25px;

}

.legendCard{
    width: 103px;
    height: 80px;
    border-radius: 10px;
    background-color: #ffffff;
    border:1px solid #969696;

    p{
        font-size:11px;
    }
}

.nameLegend{
    font-size:11px;
    color:#969696;
    text-align:center;
    margin-top:-5px;
}

.generalCenter{
    width:100%;
    display:flex;
    justify-content:space-between;
}

.iconCircle{
    width:9px;
    height:9px;
    border-radius:50%;
    margin-top: 4px;
    margin-right: 5px;
}

.contentLegend{
    display:flex;
    justify-content:center;
    padding-top:5px;

}

.legendClass{
    width: 103px;
    height: 80px;
    border-radius: 10px;
    background-color: #ffffff;
    border:1px solid #e3e3e3;
}

.circleIcon{
    width:20px;
    height:20px;
}
