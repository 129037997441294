.body
{
  overflow-x: scroll;
  
}
.cp
{
  cursor: pointer;
}
.dashboardrrhh-contain-table-user {
  width: 800px;
  height: 250px;
  padding: 25px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  .title {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
  }
  .table-rrhh th {
    background-color: #308ac9 !important;
    text-align: left !important;
    width: 150px;
    height: 40px;
    font-size: 12px;
    color: #fff !important;
  }
  .table-rrhh th div .p-column-title {
    text-align: left !important;
    font-weight: bold !important;
  }
  .table-rrhh th:first-child {
    border-top-left-radius: 10px;
  }
  .table-rrhh th:last-child {
    border-top-right-radius: 10px;
  }
  .table-rrhh td {
    font-size: 11px;
  }
  .p-datatable .p-column-header-content {
    place-content: normal;
  }
}
.dashboardrrhh-contain-calendar {
  margin-left: 20px;
  width: 750px;
  height: 520px;
  padding: 20px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  .contain-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .contain-icons {
      display: flex;
      align-items: center;
      gap: 10px;
      .icon {
        width: 15px;
        height: 15px;
      }
    }
  }
  .fc-toolbar-chunk h2 {
    font-size: 12px;
    color: #969696;
  }
  .fc-button-group {
    width: 60px;
    height: 30px;
  }
  .fc-button-group span {
    margin-bottom: 10px;
    font-size: 10px !important;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
.dashboardrrhh-contain-table-commercial {
  margin-top: 20px;
  width: 800px;
  height: 250px;
  padding: 25px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  .title {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
  }
  .table-rrhh th {
    background-color: #ff2631 !important;
    text-align: left !important;
    width: 150px;
    height: 40px;
    font-size: 12px;
    color: #fff !important;
  }
  .table-rrhh th div .p-column-title {
    text-align: left !important;
    font-weight: bold !important;
  }
  .table-rrhh th:first-child {
    border-top-left-radius: 10px;
    width: 500px;
  }
  .table-rrhh th:last-child {
    border-top-right-radius: 10px;
  }
  .table-rrhh td {
    font-size: 11px;
  }
  .p-datatable .p-column-header-content {
    place-content: normal;
  }
}
.dashboardrrhh-contain-table-request {
  margin-top: 20px;
  width: 800px;
  height: 250px;
  padding: 25px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  .title {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
  }
  .table-rrhh th {
    background-color: #d500ff !important;
    text-align: left !important;
    width: 150px;
    height: 40px;
    font-size: 12px;
    color: #fff !important;
  }
  .table-rrhh th div .p-column-title {
    text-align: left !important;
    font-weight: bold !important;
  }
  .table-rrhh th:first-child {
    border-top-left-radius: 10px;
    width: 500px;
  }
  .table-rrhh th:last-child {
    border-top-right-radius: 10px;
  }
  .table-rrhh td {
    font-size: 11px;
  }
  .p-datatable .p-column-header-content {
    place-content: normal;
  }
}
.dashboardrrhh-contain-table-rrhh {
  margin: 20px 20px 0 20px;
  width: 750px;
  height: 250px;
  padding: 25px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  .title {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
  }
  .table-rrhh th {
    background-color: #0600a8 !important;
    text-align: left !important;
    width: 150px;
    height: 40px;
    font-size: 12px;
    color: #fff !important;
  }
  .table-rrhh th div .p-column-title {
    text-align: left !important;
    font-weight: bold !important;
  }
  .table-rrhh th:first-child {
    border-top-left-radius: 10px;
    width: 500px;
  }
  .table-rrhh th:last-child {
    border-top-right-radius: 10px;
  }
  .table-rrhh td {
    font-size: 11px;
  }
  .p-datatable .p-column-header-content {
    place-content: normal;
  }
}
.dashboardrrhh-contain-table-vacations {
  margin: 20px 0;
  width: 100%;
  padding: 25px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  .p-datatable .p-datatable-footer {
    background: none;
  }
  .contain-footer-table {
    display: flex;
    align-items: center;
    gap: 20px;
    min-width: 248px;
    .title {
      font-size: 12px;
      color: #4a4f54;
      font-weight: normal;
    }
    .contain-icon {
      width: 40px;
      height: 40px;
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      text-align: center;
      padding: 6px 0;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .contain-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .contain-selects {
    display: flex;
    align-items: center;
    gap: 20px;

    .selectVacations {
      width: 300px;
      height: 40px;
      padding-left: 20px;
      border-radius: 10px;
      border: 1px solid #e3e3e3;
      font-size: 12px;
    }
  }
  @media (max-width:1176px)
  {
    .contain-selects
    {
      flex-direction: column;
    }
  }
  @media (max-width:1140px)
  {
    .contain-selects
    {
      flex-direction: column;
    }
  }
  .contain-selects
    {
     display: flex;
    }
  .table-rrhh th {
    background-color: #0600a8 !important;
    // text-align: left !important;
    width: 150px;
    height: 40px;
    font-size: 12px;
    color: #fff !important;
  }
  .table-rrhh th div .p-column-title {
    // text-align: left !important;
    font-weight: bold !important;
  }
  .table-rrhh th:first-child {
    background-color: #036402 !important;
    border-top-left-radius: 10px;
  }
  .table-rrhh th:nth-child(2),
  .table-rrhh th:nth-child(3),
  .table-rrhh th:nth-child(4),
  .table-rrhh th:nth-child(5) {
    background-color: #036402 !important;
  }
  .table-rrhh th:nth-child(6),
  .table-rrhh th:nth-child(7) {
    background-color: #f8a100 !important;
  }
  .table-rrhh th:nth-child(8),
  .table-rrhh th:nth-child(9),
  .table-rrhh th:nth-child(10) {
    background-color: #0271c1 !important;
  }
  .table-rrhh th:nth-child(11),
  .table-rrhh th:nth-child(12),
  .table-rrhh th:nth-child(13),
  .table-rrhh th:nth-child(14) {
    background-color: #308ac9 !important;
  }
  .table-rrhh th:last-child {
    background-color: #308ac9 !important;
    border-top-right-radius: 10px;
  }
  .table-rrhh td {
    font-size: 11px;
  }
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    text-align: center !important;
  }
}

.imgIconCalendar {
  display: flex;
  gap: 10px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 3px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  max-width: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.imgIconCalendarTooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 10px;
}

.tooltip-inner {
  background-color: #fff;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  color: #000;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.5);
}
.f-container
{
  display: flex;
}
@media (max-width:1200px)
{
  .f-container
{
 flex-direction: column;
}
}