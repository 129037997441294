.Modal-container-RequestVacations
{
    width: 638px;
    .header-modal-container
    {
        display: flex;
        justify-content: space-between;
    }
    .title-box
    {
        display: flex;
        flex-wrap: wrap;
    }
        .title-container
        {
            margin-bottom: 20px;
            display: flex;
            .title
            {
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                
            }
            .sub-title
            {
                font-size: 15px;
                margin-right: 60px;
            }
        }
        .profile-image
    {
        display: flex;
        align-items: center;

        height: 40px;
        margin-bottom: 30px;
        margin-top: 17px;
        img
        {
            border-radius:150px;
            width: 40px;
            height: 40px;
            object-fit: cover;
            
        }
        .text-image
        {
            margin-left: 20px;
        }
    }
    .ta-container
    {
        margin-bottom: 20px;
        width: 600px;
        .title
        {
            font-size: 15px;
            font-weight: bold;
            margin-right: 10px;
            
        }
        textarea
        {
            margin-top: 15px;
            width: 600px;
            height: 150px;
        }
    }
    .button-container
    {
        display: flex;
        justify-content: flex-end;
        .btn
        {
          width: 150px;
          height: 40px;
          color: white;
          border-radius: 10px;  
          margin-left: 30px;    
          font-size: 12px;
          font-weight: bold;
        }
    }
    }
    @media (max-width:1024px)
    {
        .Modal-container-RequestVacations
        {
            padding-top: 121px;
        }
    }
    
    
    

