@import './variables.scss';

.div-container-stand{
    padding: 15px;
    border-radius: 10px;
    fill: #ffffff;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
}

.buttons{
    border-radius: 10px;
    font-family: Poppins;
    font-weight: 700;
    font-size: 12px;
    color: #f8f8fc;
    -webkit-text-decoration: none solid #f8f8fc;
    text-decoration: none solid #f8f8fc;
    text-align: center;
    border: 0px;
    padding: 10px 25px;
    cursor: pointer;
}

.btn-big{
    padding: 10px 55px;
}

.btn-green{
    background-color: $green;
}

.btn-green-light{
    background-color: $greenLight;
}

.btn-blue{
    background-color: $blue;
}

.btn-red{
    background-color: $red;
}

.nopadding{
    padding: 0px !important;
}

.nomargin{
    padding: 0px !important;
}

.w100{
    width: 100%;
}

.group-label{
    width: 100%;
    margin-bottom: 30px;
}

.input{
    width: 100%;
    padding: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    background-size: cover;
    font-family: Poppins;
    font-size: 14px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
}

.input:focus, .input:active{
    outline: none;
}

.input-label{
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    line-height: 15px;
    display: block;
    margin-bottom: 15px;
    font-weight: 700;
}

.select-basic{
    width: 100%;
    padding: 10px;
    border: 1px solid #f8f8fc;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
}

.select-basic:focus, .select-basic:active{
    outline: none;
}

.footer-dashboard{
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.footer-dashboard p{
    font-family: Poppins;
    font-size: 10px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
}