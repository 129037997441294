.commissions {
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .commissions-text {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(90deg, #a80038 0%, #ff2631 100%);
    border-image-slice: 1;
    padding: 20px 0;
    font-family: Poppins;
    font-size: 20px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    font-weight: bold;
  }
  .commissionContainer {
    width: 100%;
    border-radius: 10px;
    fill: #ffffff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;

    .commissionsContext {
      @media (max-width: 480px) {
        flex-direction: column;
        gap: 20px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;

      .commissionsSelect {
        display: flex;
        gap: 20px;
        align-items: center;

        label {
          width: 150px;
          font-family: Poppins;
          font-size: 14px;
          color: #000000;
          text-decoration: none solid rgb(0, 0, 0);
        }
      }
    }
  }
  .btn-Green {
    background-color: #036402;
  }
}
