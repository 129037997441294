.img-pdf-modal
{
    width:549px ;
    padding-top: 261px;
}
.Modal-container
{
    

    height: 600px;
    overflow-y: scroll;
    
    .btn-actions
    {
        width: 20px;
        height: 20px;
        border: none;
        padding: 0;
    
        background: none;
    }
    .headerUsersBlokedFiles
    {
        display: flex;
        justify-content: space-between;
        
        
        .left
        {
            min-width: 715px;
            display: flex;
            
            
            .title-container
            {
                display: flex;
                margin-bottom: 15px;
                margin-left:50px ;
                width: auto;
                .title-
                {
                    font-weight: bold;
                    font-size:15px ;
                    margin-right: 10px;
                    

                }
                .text-
                {
                    font-size:15px ;
                    
                }
            }
        }
        .right
        {
            display: flex;
            flex-direction: column;
            .close
            {
                justify-content: flex-end;
                display: flex;
                padding-right: 21px;
                margin-bottom:32px ;
            }
        }
    }
    .body-general-modal
    {
        min-width: 1143px;
    }
    @media (max-width:1024px) {
        padding-top: 85px;
    }
    .footer-tableW
    {
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .pdf
    {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}