.cj-input-search
{
    .printL__input-container {
        margin: 5px;
        min-width:200px ;
        width: 100%;
        max-width: 700px;
        display: flex;
        flex-wrap: nowrap;
    }
    .printL__btn {
            transition: all .3s ;
            max-width: 78px;
            height: 40px;
            border: 1px solid #969696;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background-color: #969696;
            font-size: 14px;
            color: #FFFFFF;
            text-decoration: none solid rgb(255, 255, 255);
            text-align: center;
            background: #308ac9;
    
        }
        .printL__input {
            max-width: 510px;
            width: 100%;
            height: 40px;
            padding: 0px 8px;
            border: 1px solid #E3E3E3;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: #FFFFFF;
            background-size: cover;
            font-family: Poppins;
            font-size: 14px;
            text-decoration: none solid rgb(0, 0, 0);
           
        }
        .printL__input::placeholder{
            color:#e3e3e3;
        }
}
