.printL__card{
    cursor: pointer;
    width: 230px;
    min-height: 263px;
    border-radius: 5px;
    fill: #ffffff;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
    padding: 0px 20px;
    text-align: center;
    margin: 30px 5px;
    img{
        width: 163px;
        height: 96px;
        fill: #121212;
        margin: auto;
    }
    h3{
        margin-top: -10px;
        margin-bottom: 0px;
        text-align: center;
        font-size: 20px;
        margin-bottom: 9px;
        font-weight: bolder;
    }
    .text{
        margin: 0px;
        font-size: 14px;
        text-decoration: none solid rgb(0, 0, 0);
        text-align: center;
        font-weight: 500;
    }
    .bold{
        font-weight: bold;
    }
}
.printL__input-check {
    cursor: pointer;
    margin-left: 10px;
    margin-top: 30px;
    width: 19px;
    height: 20px;
    border-radius: 2px;
    fill: #ffffff;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.15), inset 1px 1px 5px rgba(0,0,0,0.15);
}
.printL__card-content{
    display: flex;
    justify-content: center;
    
    
}