.footer-tableW
{
    // overflow-x: scroll;
    width: 248px;
    .contain-footer-tableW {
        display: flex;
        align-items: center;
        gap: 20px;
        min-width: 248px;
        .titleW {
          font-size: 12px;
          color: #4a4f54;
          font-weight: normal;
        
        }
        .contain-iconW {
          width: 40px;
          height: 40px;
          border: 1px solid #e3e3e3;
          border-radius: 5px;
          text-align: center;
          padding: 6px 0;
          .iconW {
            width: 20px;
            height: 20px;
          }
        }
      }
}
