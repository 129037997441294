.scroll
{
    height: 634px;
    overflow-y: auto;
}
.Modal__background{
    opacity: 0.7;
    margin-left: 0px !important;
    z-index: 3;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
}
.Modal_container2{
    z-index: 5;
    position: fixed;
    max-width: 600px;
    width: 100%;
    height: 875px;
    border-radius: 10px;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 20px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-title {
        top: 40px;
        left: 35px;
        position: absolute;
        font-family: Poppins;
        font-size: 20px;
        color: #000000;
        text-decoration: none solid rgb(0, 0, 0);
        font-weight: bold;
    }
    .w-80{
        width: 80%;
    }
    .header-color
    {
        background: #0600a8!important;
    }
    .title-container
    {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-left: 45px;
        padding-right: 45px;
        margin-bottom:45px ;
        .title
        {
            font-size: 25px;
            font-weight: bold;
        }
    }
    .sub-title
    {
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .input
    {
        width: 100%;
        border: 1px solid #e3e3e3 ;
        height: 40px;
        border-radius: 10px;
        color: #b5b5b5;
        margin-bottom: 20px;

    }
    .input-date
    {
        width: 100%;
        border: 1px solid #e3e3e3 ;
        height: 40px;
        border-radius: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
      

    }
    .text-area
    {
        width: 100%;
        border: 1px solid #e3e3e3 ;
        height: 100px;
        border-radius: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }
    .btn-container
    {
        display: flex;
        justify-content: space-between;
        .btn
        {
            width: 200px;
            height: 40px;
            background: #e3e3e3;
            font-weight: bold;
            font-size: 12px;
            border-radius: 10px;
            border: none;
            color:#000000;
    
        }
        .btn-entregar
        {
            width: 200px;
            height: 40px;
            color: white;
            background: #308ac9;
            font-weight: bold;
            font-size: 12px;
            border-radius: 10px;
            border: none;
    
        }
    
    }
   
    
    .form
    {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        
    }
    @media (max-width:1024px) {
    
        .title-container
        {
            padding-top: 218px;
        }
    }
}
.dt-modal2
{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 18px;
    margin-bottom:20px ;
}

@media (max-width:1024px) {
    
    .Modal_container2
    {height: 500px;}
}