.img-container-pdf-modal
{
    position: fixed;
    bottom: calc(100vh - 7%);
    z-index: 20;
    display: flex;
    left: calc(100% - 305px);
    width: 170px;
    
    .cirle-pdf-modal
    {
        width: 40px;
        height: 40px;
        background: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

    }
    .cirle-pdf-modal-red
    {
        width: 40px;
        height: 40px;
        background: red;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
}
@media (max-width:1024px)
{
    
    .img-container-pdf-modal
    {
        bottom: calc(100vh - 7%);
        left: 40%;
        right: 60%;
        margin: auto;
    }
}
.PdfModal-background{
    opacity: 0.7;
    margin-left: 0px !important;
    z-index: 3;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
}
.PdfModal
{
    height: 634px;
    overflow-y: auto;


.Pdf-Modal_container{
    z-index: 5;
    position: fixed;
    max-width: fit-content;
    width: 100%;
    height: calc(100vh - 20%);
    border-radius: 10px;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 20px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .w-80{
        width: 80%;
    }
    .header-color
    {
        background: #0600a8!important;
    }
    
    }
   
    
    .formPdf
    {
        width: 100%;
        overflow: scroll;
        
        
    }
   
}
.dt-modalPdf
{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 18px;
    margin-bottom:20px ;
}


