.table-ARH
{
    .p-datatable 
    {
        .color
        {    
            color: black!important;
            background: #f3f3f3;
        }
    }
}
.cj-AttendanceRh
{
    .search-container-cj
    {
        .db
        {
            justify-content: flex-start;
            
        }
    } 
    .select
    {
        font-size: 12px;
        width: 200px;
        height: 40px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding-left: 10px;
        margin-left: 20px;
    }
    .header
    {
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;
        .actual-date
        {
            display: flex;
            margin-left: 30px;
           
            .text
            {
                font-size: 12px;
                margin-right:15px;
                margin-top: 7px;
                width: 100px;
            }
            .date
            {
                color:#0600a8;
                font-size: 20px;
                font-weight: bold;
                width: 170px;
            }
        }
    }
    .select-umbloked
    {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #969696;
        background: white;
    }
    .select-bloked
    {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #969696;
        background: #e3e3e3;
        
    }
    .p-datatable-scrollable 
    {
        .p-frozen-column
        {
            background: white;
        }
    }
    .p-datatable-tbody
    {
        tr:nth-child(2n+1)
        {
            background: white!important;
        }
    } 
}

