.Sidebar__container{
    background: #fff;
    position: fixed;
    width: 275px;
    min-height: 100vh;
    display: flex;
    
    
    
    .Sidebar__redbar{
        min-width: 45px;
        max-width: 45px;
        z-index: 3;
        position: static;
        background: #ff2631;
        min-height: 100vh;
        text-align: center;
        display: flex;
        flex-direction: column;

        .bar-item{
            cursor: pointer;
            width:  45px;
            height: 45px;
            background-color: #ff2631;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .3s ease;
            &:hover{
                background-color: #ff444d;

            }

        }
        .line-white{
            position: absolute;
            left: 0px;
            width: 3px;
            height: 45px;
            background-color: #f0f0f0;
        }

        img.imglogo{
            cursor: pointer;
            width:  25px;
            height: 25px;
            color: #ffffff;
            font-weight: bolder;
        }

        .bar-items-end {
            position: absolute;
            bottom: 0px;
            align-self: flex-end;
        }
    }

    .menu-body{
        min-width: 210px;
        max-width: 210px;
        z-index: 3;
        position: static;
        background: #fff;
        min-height: 100vh;
        text-align: left;
        display: flex;
        flex-direction: column;
        fill: #ffffff;
        box-shadow: 2px 2px 15px rgba(0,0,0,0.1) ;
        
        .group-logo {
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 48px;
            left: 85px;

            .img1{
                width: 100px;
                height: 100px;
                position: absolute;
                top: -80px;
                left: -15px;
            }
            .img2{
                z-index: 1;
                width:  120px;
                height: 30px;
            }
        }

        .menu-body-title{
            margin-top: 15px;
            width: 100%;
            height: 60px;
            font-family: Poppins;
            font-size: 20px;
            color: #000000;
            text-decoration: none solid rgb(0, 0, 0);
            font-weight: bolder;
            text-align: center;

        }
        ul{
            padding: 0px;
            margin: 0px;

        }
        li{
            padding: 0px;
            margin: 0px;

            list-style: none;
        }

        .menu-item{
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 98%;
            min-height: 30px;
            margin: 0px;
            padding: 0px;
            background-color: #fff !important;
            padding: 0px 0px 0px 20px;
            background-color: #ffffff;
            background-size: cover;
            font-family: Poppins;
            font-size: 14px;
            color: #969696;
            text-decoration: none solid rgb(150, 150, 150);
            transition: background-color .3s ease;

            &:hover {
                background-color: #f5f5f5 !important;
            }
        }
        .submenu-group{
            display: none;

        }
        .submenu-items{
            list-style: decimal;
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 98%;
            min-height: 30px;
            margin: 0px;
            padding: 0px;
            background-color: #fff !important;
            padding: 0px 0px 0px 40px;
            background-color: #ffffff;
            background-size: cover;
            font-family: Poppins;
            font-size: 14px;
            color: #969696;
            text-decoration: none solid rgb(150, 150, 150);
            transition: background-color .3s ease;

            &:hover {
                background-color: #f0f0f0 !important;
            }
        }
        .show-submenu{
            display: flex;
            flex-direction: column;
        }
        .active {
            background-color: #ff2631 !important;
            text-decoration: none solid rgb(255, 255, 255);
            color: #f0f0f0;
            &:hover{
                background-color: #ff2631 !important;
                text-decoration: none solid rgb(255, 255, 255);
                color: #f0f0f0;
            }
        }
        
    }
}