
.table-dashboard.gpon th:first-child {
    width: auto !important;
}
.table-dashboard.gpon th:nth-child(2) {
    width: auto !important;
}

.table-dashboard.gpon th:nth-child(3) {
    width: auto !important;
}

.table-dashboard.gpon th:nth-child(4) {
    width: auto !important;
}

.table-dashboard.gpon th:nth-child(5) {
    width: auto !important;
}

.table-dashboard.gpon th:nth-child(9) {
    width: auto !important;
}