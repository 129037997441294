@import '../../../../sass/main.scss';

.container-dashboard{
    padding-left: 36px;

    p{
        margin-top:1em;
    }
}

.div-selects-dashboard{
    padding-top: 8px;
}

.div-buttons-dashboard{
    text-align: right;
    margin-top: 20px;
    margin-bottom: 20px;
}

.div-buttons-dashboard button{
    margin-left: 10px;
}

.footer-dashboard{
    margin-top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.footer-dashboard p{
    font-family: Poppins;
    font-size: 10px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
}


/* */


.cardSpace{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divFile{

    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffffff;
    stroke-width: 1;
    display:flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e3e3e3;

    img{
        width:20px;
        height:20px;

    }
}

.textBold{
    font-weight:bold;
}

.input-file{

    margin-top:40px;

    .input-file-input {
        display:none;
    }

    .btnBlue{
        width: 200px;
        height: 40px;
        border-radius: 10px;
        background-color: #0271c1;
        background-size: cover;
        font-family: Poppins;
        font-size: 12px;
        font-weight:bold;
        color: #f8f8fc;
        text-decoration: none solid rgb(248, 248, 252);
        //text-align: center;
        display:flex;
        justify-content: center;
        align-items: center;
    }
}

.btnFile{
    display:flex;

    input{
        margin-left: 31px;
        width: 210px;
        height: 40px;
        padding: 0px 8px;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        background-color: #ffffff;
        background-size: cover;
        font-family: Poppins;
        font-size: 12px;
        color: #000000;
        text-decoration: none solid rgb(0, 0, 0);
    }
}

.ant-modal-content{
    width:730px;
    //height:270px;
    border-radius:10px !important;
}

.ant-modal-footer {
    border-top:none!important;
}

.sectionProgressBar{
    margin-top:39px;
}

.textUploadFile{
    font-size:20px;
    font-family: Poppins;
}

.btnUpload{
    width: 100px;
    height: 40px;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 12px;
    color: #f8f8fc;
    text-align: center;
    border:none;

}


.tableShadow{

    margin-top:56px;
    padding:22px;
    //width:100%;
    margin-left:34px;
    margin-right: 34px;
    border-radius: 10px;
    fill: #ffffff;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.1);


    th {
        background-color: $red2 !important;
        //padding: 1px 0px !important;
        //border: 1px solid #e9ecef !important;
        border-width: 0 0 1px 0 !important;
        font-weight: 600 !important;
        color: #fff !important;
        transition: box-shadow 0.2s;
        font-size: 12px !important;
        font-family: Poppins;
        height: 55px !important;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        border:none!important;
    }

    tr:nth-child(odd) {background: $grayLight !important;}

    .sizeFirstColumn{
        width:200px !important;
        display:flex;
        justify-content: center;
    }

}


