.Modal__background{
    opacity: 0.8;
    margin-left: -55px !important;
    z-index: 3;
    position: absolute;
    top: 0px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100%;
}
.Modal__modal{
    z-index: 99;
    max-width: 730px;
    width: 100%;
    height: 270px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    top: -450px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 20px;
    overflow-x: auto;
    

    .modal-title {
        top: 40px;
        left: 35px;
        position: absolute;
        font-family: Poppins;
        font-size: 20px;
        color: #000000;
        text-decoration: none solid rgb(0, 0, 0);
        font-weight: bold;
    }
    label{
        max-width: 230px;
        max-height: 40px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 264px;
        top: 108px;
        padding: 0px 8px;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        background-color: #ffffff;
        background-size: cover;
        font-family: Poppins;
        font-size: 12px;
        color: #000000;
        text-decoration: none solid rgb(0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .Modal__close{
        position: absolute;
        top: 30px;
        right: 30px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .file-select {
        position: absolute;
        top: 108px;
        left: 35px;
        display: inline-block;
        width: 160px;
        height: 40px;
        border-radius: 10px;
        cursor: pointer;
      }

      .file-select::before {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #308ac9;
        font-size: 12px;
        color: #f8f8fc;
        border: 1px solid #308ac9;
        transition: background .3s ease;
        content: 'Subir archivo'; /* texto por defecto */
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    
    .button{
        position: absolute;
        right: 30px;
        bottom: 30px;
        width: 100px;
        height: 40px;
        border-radius: 10px;
        background-color: #036402;
        font-size: 12px;
        color: #f8f8fc;
        text-decoration: none solid rgb(248, 248, 252);
        text-align: center;
        border: none;
        &:hover {
            background-color: #079105;
        }
    }

    .modal2{
        min-height: 200px;
        max-height: 270px;

        .modal-title{
            margin-top: 30px;
        }

        small{
            height: 80px;
            margin-left: 10px;
            padding:  8px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            background-color: #ffffff;
            background-size: cover;
            font-family: Poppins;
            font-size: 14px;
            color: #000000;
            text-decoration: none solid rgb(0, 0, 0); 
        }
        //BarProgress
        .p-progressbar .p-progressbar-value{
            background-color: #036402;;
        }
        .p-progressbar-determinate .p-progressbar-label{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .p-progressbar{
            // margin-left: 40px;
            margin: auto;
            width: 80%;
            top: 30px;
            border-radius: 0px;
            height: 40px;

        }
        .complete{
            position: absolute;
            padding:  8px;
            font-size: 12px;
            font-weight: 500;
            bottom: 30px;
            right: 80px;
        }
    }
    .modal3{
        .modal-title{
            margin-top: -17px;
        }
        .button_close{
            position: absolute;
            right: 20px;
            bottom: 10px;
            width: 100px;
            height: 40px;
            border-radius: 10px;
            background-color: #036402 ;
            background-size: cover;
            font-family: Poppins;
            font-size: 12px;
            color: #f8f8fc;
            text-decoration: none solid rgb(248, 248, 252);
            text-align: center;
            border: none;
            transition: background-color .3s ease;
            &:hover{
            background-color: #2f9c2d ;
            }
        }
        .table_modal3{
            margin-top: 40px;
            min-height: 150px;
            .row_table{
                display: flex;
                flex-direction: row;

                &:nth-child(2n+1){
                    background-color: #f8f8fc;
                }
                p{
                    width: 40%;
                    height: 40px;
                    margin-bottom: 0px;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: bolder;
                    display: flex;
                    align-items: center;
                    
                    &:nth-child(2n+1){
                        justify-content: space-around;
                    }
                }
                
            }
    }


    }


      
}

