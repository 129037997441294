select,
.input-style {
  width: 100%;
  height: 40px;
  padding: 0px 8px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
label {
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  font-weight: 700;
}

.branch-office {
  .branch-office-text {
    max-width: 4000px;
    height: 50px;
    border-radius: 10px;
    background-color: #308ac9;
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
  }
}
.branch-ofice-data {
  display: flex;
  justify-content: flex-start;
}
.branch-container-data {
  @media (max-width: 768px) {
    flex-direction: column;
  }
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}
.data-two {
  @media (max-width: 768px) {
    width: 100%;
  }
  display: flex;
  width: 300px;
  flex-direction: column;
}
.option-checkbox {
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
  display: flex;
  gap: 210px;
}
.date-ruc {
  width: 120px;
}
.branch-office-textarea {
  @media (max-width: 768px) {
    margin-left: 0px;
  }
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  margin-left: 20px;
}
.container-textarea textarea {
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 10px;
  }
  margin-left: 20px;
  width: min(98%, 1500px);
  height: 100px;
  padding: 0px 8px 60px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.buttom-branch button {
  background-color: #16de9a;
}
.buttom-branch-text {
  color: #16de9a;
}
