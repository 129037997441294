.personnel {
  @media (max-width: 768px) {
    width: min(100%, 768px);
  }
  margin-top: 20px;
  max-width: 4000px;
  width: 100%;
}
.personne-header {
  @media (max-width: 768px) {
    width: 100%;
  }
  display: flex;
  gap: 20px;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #a80038 0%, #ff2631 100%);
  border-image-slice: 1;
  margin-bottom: 30px;
}
.personne-header p {
  font-size: 14px;
}
.personne-header h3 {
  font-size: 20px;
  font-weight: 700;
}
.personnel-container {
  @media (max-width: 768px) {
    width: min(100%, 768px);
  }
  max-width: 4000px;
  max-height: auto;
  border-radius: 10px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  padding: 50px 20px;
  margin-bottom: 101px;
}
.personnel-container-text {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #a80038 0%, #ff2631 100%);
  border-image-slice: 1;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.personnel-container-quest {
  max-width: 4000px;
  height: 50px;
  border-radius: 10px;
  background-color: #308ac9;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
}
.personnel-container-quest p {
  display: inline-block;
  margin-top: 15px;
  font-weight: 700;
  font-family: Poppins;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
}
.input-style {
  height: 40px;
  padding: 0px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.input-style {
  @media (max-width: 768px) {
    width: 270px;
  }
}
.personnel-data-doc-style {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: min(100%, 300px);
    gap: 20px;
  }
  display: flex;
  flex-direction: column;
  margin: 37px 0;
  width: min(96%, 300px);
}

.personnel-data-doc-age {
  display: flex;
  flex-direction: column;
  margin: 37px 0;
  width: min(96%, 300px);
}
.personnel-data-doc {
  display: flex;
  gap: 31px;
}
.personnel-data-doc {
  @media (max-width: 768px) {
    display: grid;
    gap: 0;
  }
  border-bottom: 1px solid #e3e3e3;
}
.personnel-data-doc-main label {
  font-weight: 700;
}
.personnel-data-doc-style-emergency {
  display: flex;
  flex-direction: column;
  margin: 37px 0;
}
.contact-emergency {
  display: flex;
  margin-bottom: 10px;
}
.contact-emergency-select {
  width: min(96%, 81px);
  height: 40px;
  padding: 0px 8px;
  border: 1px solid #e3e3e3;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: Poppins;
  font-size: 14px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.contact-emergency-input {
  width: min(100%, 234px);
  height: 40px;
  padding: 0px 8px;
  border: 1px solid #e3e3e3;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 14px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.contact-emergency button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #308ac9;
  background-size: cover;
}
.button-add-cont {
  font-size: 40px;
  margin-bottom: 0;
  margin-left: 2px;
}
.personnel-add-button {
  display: flex;
  flex-direction: column;
}
.botton-add {
  position: absolute;
  margin-top: 120px;
}
.color-red button {
  background-color: #ff2631;
  padding: 0px 8px;
  width: 40px;
}
.button-direction {
  max-width: 99%;
  display: flex;
  justify-content: right;
  margin: 10px 0;
}
.button-direction button {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #ff2631;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-direction-text {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 50%;
  color: #ff2631;
  display: grid;
  place-items: center;
  margin: 0;
  justify-content: center;
}
.personnel-container-direction {
  max-width: 95%;
  margin: 0 auto;
  margin-bottom: 20px;
  height: auto;
}
.container-direction {
  display: flex;
  justify-content: space-between;
  background-color: #ff2631;
  padding: 10px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.container-direction p {
  margin: auto 0;
  color: white;
  font-weight: 700;
}
.container-direction-text {
  max-width: 1500px;
  height: 40px;
  background-color: #f8f8fc;
  display: flex;
  justify-content: center;
}
.container-direction-text p {
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  font-weight: 700;
  margin-top: 10px;
}
.personnel-container-quest-checkbox {
  display: flex;
  font-family: Poppins;
  font-size: 12px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
}
.personnel-container-quest-checkbox label {
  margin: 0;
}
.picture-ID {
  @media (max-width: 992px) {
    margin: 0 auto;
  }
  width: 150px;
  height: 180px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.picture-ID p {
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  font-weight: 700;
  span {
    text-transform: uppercase;
  }
}
.picture-ID-img {
  width: 150px !important;
  height: 150px;
  background-color: #fcf7f7;
  border-radius: 50%;
  position: relative;
  border: 1px solid rgb(236, 236, 236);
}
.picture-ID-img button {
  width: 30px;
  height: 30px;
  background-color: #50c4f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  position: absolute;
  left: 80px;
}
.picture-ID-img p {
  width: 18px;
  height: 18px;
  background-color: #f8f8fc;
  border-radius: 50%;
  color: #50c4f2;
  margin: 0;
}
.select-img {
  position: absolute;
  width: 80px;
  height: 36px;
  font-family: Poppins;
  font-size: 12px;
  color: #f8f8fc;
  text-align: center;
  left: 40px;
  top: 60px;
}
.personnel-picture {
  @media (max-width: 992px) {
    flex-direction: column;
  }
  display: flex;
  gap: 50px;
  margin-top: 20px;
}
.personnel-picture-container {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.personnel-picture-selection-file {
  margin-top: 10px;
  p {
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    font-weight: 700;
  }
  input {
    width: 200px;
    height: 30px;
    border: 1px solid #ff2631;
    border-radius: 5px;
    background-size: cover;
    font-family: Poppins;
    font-size: 12px;
    color: #ff2631;
    text-decoration: none solid rgb(255, 38, 49);
    text-align: center;
    font-weight: 700;
  }
}
.picture-input {
  display: flex;
  gap: 10px;

  p {
    font-weight: 400;
    margin: auto 0;
  }
}
.text-add {
  display: flex;
  gap: 10px;

  p {
    color: #a80038;
  }

  .text-add-input {
    width: 60px;
    height: 30px;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    text-align: center;
    background-color: white;
    font-weight: 400;
  }
}
.upload-data {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  display: flex;
  align-items: center;
  align-content: center;
  gap: 20px;
  .file-top {
    @media (max-width: 768px) {
      font-size: 12px;
      margin-top: -6px;
    }
    margin-top: 12px;
  }
  .imageLogo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
  }
  .input-file {
    position: relative;
    display: flex;
    margin-top: -10px;
    font-size: 12px;
    font-family: Poppins;
    font-weight: bold;
    border: 1px solid #ff2631;
    border-radius: 5px;
    width: 250px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }

  .input-file input[type="file"] {
    @media (max-width: 768px) {
      width: 0px;
    }
    opacity: 0;
    width: 2px;
    height: 30px;
    display: inline-block;
  }
  .file-name {
    margin-bottom: 20px;
  }
  .input-file::before {
    background-color: #fff;
    color: #ff2631;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
#uploadImageS1 {
  display: none;
}
.file-top-submit {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.file-top-submit input {
  background-color: #fff;
  border: none;
}
#eliminar {
  color: #ff2631;
}
.element-select {
  position: relative;
  right: 200px;
  top: 25px;
}
.container {
  display: flex;
  width: 350px;
}
.container select {
  width: 81px;
  height: 40px;
  padding: 0px 8px;
  border: 1px solid #e3e3e3;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: Poppins;
  font-size: 14px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.container input {
  width: 234px;
  height: 40px;
  padding: 0px 8px;
  border: 1px solid #e3e3e3;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 14px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.add,
.red {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #308ac9;
  background-size: cover;
}

.btn-add {
  display: grid;
  place-items: center;
  font-size: 40px;
}
.contactos {
  @media (max-width: 768px) {
    width: 270px;
  }
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 400px;
  overflow: auto;
}
.each-input p {
  @media (max-width: 768px) {
    margin: 10px;
    text-align: center;
  }
  font-weight: 700;
  margin: 0;
}
