.table-dashboard th:first-child {
    width: 120px !important;
}
.table-dashboard {
    font-family: "Rubik", sans-serif !important;
}
.table-dashboard th:last-child {
    border-top-right-radius: 10px !important;
    width: 110px !important;
}

/*.table-dashboard th:nth-child(2) {
    width: 150px !important;
}

.table-dashboard th:nth-child(3) {
    width: 50px !important;
}

.table-dashboard th:nth-child(4) {
    width: 120px !important;
}

.table-dashboard th:nth-child(9) {
    width: 120px !important;
}
*/
.table-dashboard td {
    text-align: center !important;
    padding: 15px 0px !important;
    font-size: 13px !important;
    border-width: initial !important;
}
.table-dashboard .p-sortable-column:focus {
    box-shadow: inherit !important;
}

.table-dashboard .pi-angle-double-left {
    display: none !important;
}
.p-paginator-element {
    border: none !important;
    padding: 5px 10px;
}
.table-dashboard .p-paginator-prev {
    background-color: none !important;
    background-size: cover !important;
    color: #000000 !important;
    text-align: center !important;
    outline: none;
    border: none !important;
    padding: none !important;
}
.p-paginator-first .p-paginator-element .p-link .p-disabled {
    display: none !important;
}
.table-dashboard .pi-angle-left {
    font-size: 14px !important;
}
.table-dashboard .pi-angle-left:before {
    content: "Anterior";
}

.table-dashboard .p-paginator-last {
    display: none !important;
}
.table-dashboard .p-paginator-next {
    background-color: none !important;
    background-size: cover !important;
    color: #000000 !important;
    text-align: center !important;
    padding: 10px 20px !important;
    outline: none !important;
    border: none !important;
}
.table-dashboard .pi-angle-right {
    font-size: 14px !important;
}
.table-dashboard .pi-angle-right:before {
    content: "Siguiente";
}

.table-dashboard .p-paginator .p-paginator-pages .p-paginator-page {
    margin: 0px !important;
    background-color: #ffffff !important;
    color: #000000 !important;
    text-align: center !important;
    font-size: 14px !important;
}
.table-dashboard .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    font-size: 12px !important;
    color: #ffffff !important;
    margin: 0 !important;
    margin-top: 1em;
    border: none;
    border-radius: 5px !important;
    padding: 5px 14px !important;
}

.table-dashboard .p-paginator {
    display: block !important;
    align-items: initial !important;
    justify-content: initial !important;
    flex-wrap: initial !important;
    text-align: right !important;
    padding-right: 0px !important;
}
.table-dashboard .p-paginator .p-paginator-pages {
    font-size: 12px !important;
    color: #ffffff !important;
    /* text-align: center !important; */
    transition: 0.5s all ease;
    margin: none !important;
    border: none;
    border-radius: 5px !important;
    padding: none !important;
}
.p-paginator {
    margin-top: 1em !important;
}
.p-paginator-first {
    display: none !important;
}
.table-dashboard .p-paginator-next {
    padding: 0 !important;
}

.p-paginator-page .p-paginator-element .p-link .p-paginator-page-start {
    padding: 0 !important;
}
.p-paginator-page .p-paginator-element .p-link .p-paginator-page-end {
    padding: 0 !important;
}
.table-dashboard .pi-angle-right {
    font-size: 14px !important;
    background-color: white;
    padding: 10px;
}
.table-dashboard .p-paginator-prev {
    background-color: white !important;
    background-size: cover !important;
    color: #000000 !important;
    text-align: center !important;
    outline: none;
    border: none !important;
    padding: none !important;
    margin-right: 10px !important;
}
.table-dashboard .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    font-size: 14px !important;
    color: #ffffff !important;
    text-align: center !important;
    border: none;
    border-radius: 5px !important;
    padding: 5px 12px !important;
    background-image: linear-gradient(135deg, #93dbfa 0%, #4165f2 100%);
}

.table-dashboard td {
    text-align: center !important;
    padding: 15px 0px !important;
    font-size: 13px !important;
    border-width: initial !important;
    color: #121212;
}
.primeReactNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
}
.inputSearch {
    width: 300px;
    height: 40px;
    padding: 0px 0px 0px 20px;
    border-radius: 28px;
    background-color: #f8f8fc;
    background-size: cover;
    font-family: Nunito;
    font-size: 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}
.p-paginator-current {
    float: left !important;
    margin-top: 7px;
    font-size: 14px !important;
}
.inputSearch input {
    border: none;
    outline: none;
    background-color: #f8f8fc;
}
.inputSearch input::placeholder {
    font-weight: 600;
    color: #b8b8b8;
    font-size: 12px;
    z-index: 99;
}
.subtitle {
    font-weight: bold;
    font-size: 15px;
    font-family: "Rubik", sans-serif;
}
.p-paginator-current {
    float: left !important;
    margin-top: 7px;
    color: #000000;
}
.table-dashboard .p-paginator {
    margin-top: 3em !important;
}
.table-dashboard .p-paginator-next {
    padding: 0 !important;
    margin-left: 10px;
}
.containerImages img {
    margin-left: 1.3em;
    cursor: pointer;
}
.p-datatable-tbody tr:nth-child(2n + 1) {
    background: rgba(35, 62, 94, 0.055) !important;
}
.p-datatable-tbody tr:nth-child(even) {
    background: #ffffff !important;
}
.p-column-title {
    font-family: "Rubik", sans-serif !important;
}
.table-dashboard .pi-sort-alt {
    display: none !important;
}
.table-dashboard .pi-sort-amount-up-alt {
    display: none !important;
}
.table-dashboard th {
    background-color: #fa0401 !important;
}
.table-dashboard td {
    border: none !important;
}
