.bgc-blue
{
    background: rgb(44, 61, 194)!important;
}
.UsersBlockedStock-table
{
    margin-top: 20px;
}
    
    
    
