
.containerCancellation{
    width:100%;
    height: 749px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 2px 1px 15px rgba(0,0,0,0.1);
    padding-top:60px;
    padding-left: 53px;
    padding-right: 53px;
    margin-top:31px;
    margin-bottom: 30px;
}

.contentCancellation{
    width:80%;
}

.labelText{
    width:60%;
    display:flex;
    justify-content:space-between;
    font-size:18px;
    font-weight: 500;
}

.lightText{
    color:#969696;
    font-size: 14px;
    margin-left: 10px;
}

.progressBarCancellation{
    width:100%;
    display:flex;
    justify-content:center;
    //margin-top:15px;
    margin-top: 75px;

}

.cancellationCard{
    width: 264px;
    height: 110px;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
    border:1px solid #0600a8;
    display: flex;
    padding: 10px;
    position: absolute;

    img{
        width:30px;
        height: 30px;
        margin-top:25px;
        margin-left:20px;
        margin-right: 25px;
    }

    .boldCancellation{
        font-size:30px;
        font-weight: bold;
    }

    .titleCancellation{
        color:#0600a8;
        font-size: 20px;
    }

}

.progressBarClass{
    //background-color:#ff2631;
    height:40px;
    //border-top-left-radius: 20px;
    //border-bottom-left-radius: 20px;
    //text-align: end;
    padding-right: 10px;
    padding-top:5px;

    p{
        font-size:18px;
        font-weight:bold;
        color:#ffffff;
    }
}

.progressBarLeft{
    height:40px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    text-align: end;
    padding-right: 10px;
    padding-top:5px;
    float:right;


    p{
        font-size:18px;
        font-weight:bold;
        color:#ffffff;
    }
}

.progressBarRight{
    height:40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: start;
    padding-left: 10px;
    padding-top:5px;
    width:40%;

    p{
        font-size:18px;
        font-weight:bold;
        color:#ffffff;
    }
}

.progressBar1{
    display:flex;
    margin-top:20px;
    justify-content:center;
}

.middleCard{
    width:100%;
    display:flex;
    justify-content:center;
}


.textCenter{

   width:100%;
}

.percentageWidth{
    width:40%;
}

.middleTextSection{
    display:flex;
    width:100%;
    align-items:center;
    justify-content: center;
}

.textGroup{
    height:120px;
    text-align:center;
    font-size:18px;
    padding-top: 28px;
    width:20%;
    -webkit-box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
    box-shadow:2px 2px 15px rgba(0,0,0,0.1);



    //box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
    //width: 208px;

}
