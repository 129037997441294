@import '../../../../../sass/variables.scss';

.div-downloads-dashboard{
    margin-bottom: 15px;
    text-align: right;
}
.text-download-dashboard{
    font-size: 12px;
    font-family: Poppins;
    margin-right: 15px;
}
.icon-word-dashboard{
    color: $blueLight;
    cursor: pointer;
    margin-left: 10px;
    border: 1px solid #eee;
    font-size: 28px;
    padding: 3px;
    border-radius: 2px;
}
.icon-excel-dashboard{
    color: $green;
    cursor: pointer;
    margin-left: 10px;
    border: 1px solid #eee;
    font-size: 28px;
    padding: 3px;
    border-radius: 2px;
}

.table-activations th {
    padding: 1px 0px !important;
    //border: 1px solid #e9ecef !important;
    border: 0px !important;
    border-width: 0 0 1px 0 !important;
    font-weight: 600 !important;
    color: #fff !important;
    transition: box-shadow 0.2s;
    font-size: 11px !important;
    text-align: center !important;
    font-family: Poppins;
    height: 40px !important;
}

.table-dashboard  th:first-child {
    width: 120px !important;
    border-top-left-radius: 10px !important
}

.table-dashboard  th:last-child {
    border-top-right-radius: 10px !important;
    //font-size: 10px !important;
    width: 110px !important;
}

.prep-father{
    background-color: $redTransparent !important;
}

.prep-child{
    background-color: $red !important;
}

.pos-father{
    background-color: $greenTBTransparent !important;
}

.pos-child{
    background-color: $greenTB !important;
}

.dth-father{
    background-color: $blueLightTransparent !important;
}

.dth-child{
    background-color: $blueLight !important;
}

.gpon-father{
    background-color: $yellowTransparent !important;
}

.gpon-child{
    background-color: $yellow !important;
}

.date-main{
    background-color: $blueLight !important;
}

.date-child-activations{
    background-color: $blueLight !important;
}

.date-child-activations:hover{
    background-color: $blue !important;
}
.input-table-edit-modal
{
    border: none;
    background: transparent;
}
