
.graphContainer{
    width: 676px;
    //width:450px;
    height: 401px;
    border-radius: 10px;
    fill: #ffffff;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
    padding-left:50px;
    padding-right:50px;
    padding-top:20px;
    margin-top:31px;
    margin-left:20px;
}
