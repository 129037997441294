.side-menu
{
  
}
.sub-menu
{
  font-size: 11px;
  color:#969696 ;
  height: 28px;
  line-height: 12px;
}
.sidebar-dark-purple .sidebar-left {
  background: #fa0401 !important;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a:hover {
  background: #fa0401 !important;
}
.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a {
  padding: 5px 24px !important;
}
.layout-sidebar-compact
  .sidebar-left-secondary
  .childNav
  li.nav-item
  a:hover
  span {
  color: white !important;
}
.layout-sidebar-compact
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold {
  padding: 10px 0 !important;
}

.layout-sidebar-compact .sidebar-left .navigation-left {
  width: 51px !important;
}

.layout-sidebar-compact.sidenav-open .sidebar-left-secondary {
  left: 51px !important;
}

.layout-sidebar-compact
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold
  .nav-icon,
.layout-sidebar-compact
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold
  .feather {
  font-size: 20px !important;
  height: 20px !important;
  width: 20px !important;
}

.nav-icon-p{
  height: 30px !important;
  width:  30px !important;
  margin: 10px;

}
.pl-10{
  padding-left:8px ;
}

.sidebar__image-group {
  z-index: 999;
  position: absolute;
  bottom: 0px;
  left: 44px;
  display: flex;
  flex-direction: column;
}

.sidebar__image1 {
  position: absolute;
  width: 77px;
  height: 68px;
  top: -77px;
  left:7px;
}
.sidebar__image2{
  margin-top:-50px;
  left: 150px;
  width: 120px;
  height: 120px;
}
.icons-group {
  position: absolute;
  bottom: 0px;
  left: -8px;
}

.layout-sidebar-compact .sidebar-left-secondary header h6 {
  font-size: 20px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  font-weight: 700;
}

.layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a {
  color: #969696;
}
.layout-sidebar-compact.sidenav-open .sidebar-left-secondary {
  box-shadow: 2px 2px 15px rgb(0 0 0 / 20%);
}
li ul {
  display: none;
}
.show:hover ul {
  display: block;
}

.layout-sidebar-compact
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-ul.active {
  // color: #fff;
  background: #fa0401 !important;
}

.layout-sidebar-compact
  .sidebar-left-secondary
  .childNav
  li.nav-item
  ul.nav-item-ul
  a {
  padding: 5px 24px 5px 14px !important;
}
.layout-sidebar-compact.sidenav-open .main-content-wrap {
  width: calc(100% - 76px - 198px);
}
// @media(max-width:500px) {
//   .layout-sidebar-compact.sidenav-open .main-content-wrap {
//   width:auto
//   }
// }
.layout-sidebar-compact .sidebar-left-secondary,
.layout-sidebar-compact .sidebar-left {
  top: 0 !important;
}
.main-header {
  height: 40px;
}
.layout-sidebar-compact .main-content {
  margin-top: 50px;
  min-height: 100vh;
}
.container-dashboard {
  padding-left: 0px !important;
}
.selected {
  // .layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a{
  // .layout-sidebar-compact .sidebar-left-secondary .childNav li.nav-item a  {
  span {
    color: white !important;
  }
  background: #fa0401 !important;
  // }
}
.layout-sidebar-compact .sidebar-left-secondary .submenu-area:hover {
  display: block !important;
}
.active {
  background-color: #fa0401;
  span {
      color: #fff;
  }
}
