

.container-createplan{
    padding-left: 36px;
}

.form-createplan{
    margin-top: 20px;
}

.div-buttons-createplan{
    text-align: right;
    margin-top: 20px;
    margin-bottom: 20px;
}

.div-buttons-createplan button{
    margin-left: 10px;
}