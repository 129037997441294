
$blue: #0271c1;
$blueLight: #308ac9;
$blueLightTransparent: rgb(48 138 201 / 60%);
$green: #036402;
$greenLight: #16de9a;
$redTransparent: rgb(255 38 49 / 60%);
$red: #ff2631;
$greenTB: #11ab77;
$greenTBTransparent: rgb(17 171 119 / 60%);
$yellow: #f5a72a;
$yellowTransparent: rgb(245 167 42 / 60%);
$gray: #4a4f54;
$grayLight:#f8f8fc;
$red2: #fa0401;
