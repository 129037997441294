.reports-contain-selects {
    display: flex;
    gap: 40px;
    margin-top: 30px;
    .contain-selects-1 {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .contain-buttons {
        display: flex;
        justify-content: right;
        .button-blue {
            background-color: #0271c1;
            border: none;
            border-radius: 10px;
            color: #fff;
            font-size: 14px;
            padding: 7px 17px;
            font-weight: bold;
        }
        .button-green {
            background-color: #036402;
            border: none;
            border-radius: 10px;
            color: #fff;
            font-size: 14px;
            padding: 7px 17px;
            font-weight: bold;
        }
    }
    .contain-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        gap: 10px;
        width: 350px;
        height: 50px;
        box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
        border-radius: 10px;
        .select {
            width: 200px;
            height: 30px;
            border-radius: 10px;
            font-size: 12px;
            border: 1px solid #e3e3e3;
            color: #a6a6a6;
            padding: 0 10px;
        }
        .select2 {
            display: flex;
            align-items: center;
            width: 200px;
            height: 30px;
            border-radius: 10px;
            border: 1px solid #e3e3e3;
            .p-placeholder {
                color: #a6a6a6;
                font-size: 12px;
            }
        }
        .title {
            font-size: 14px;
            font-weight: bold;
        }
        .title2 {
            width: 100px;
            font-size: 10px;
            font-weight: bold;
        }
    }
}
.reports-datatable {
    width: 100%;
    height: 750px;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
    padding: 40px 30px;
    margin: 30px 0;
    th {
        background-color: #0271c1 !important;
        color: #fff !important;
        height: 50px;
        font-size: 14px;
    }
    th:first-child {
        border-top-left-radius: 10px;
    }
    th:last-child {
        border-top-right-radius: 10px;
    }
    td {
        height: 40px;
        font-size: 13px;
        text-align: center !important;
        border: 1px solid #a6a6a6 !important;
        background-color: #fff !important;
    }
    .contain-datatable-header {
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        font-size: 13px;
        .icon {
            width: 40px;
            height: 40px;
            border: 1px solid #e3e3e3;
            padding: 8px;
        }
    }
}