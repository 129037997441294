

.cardProgressBar{
    width: 562px;
    height: 401px;
    padding-left:40px;
    padding-right:40px;
    padding-top:35px;
    padding-bottom:35px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
    margin-top:31px;
    //width:460px;

    .boldText2{
        font-weight:bold;
        font-size: 15px;
    }
}

.textActivate{
    font-size:18px;
    color:#000000;
}

.colorRed{
  color:#ff2631;
}

.flexText2{
    display:flex;
    justify-content: space-between;
    margin-top:25px;
}

.titleProgress{
    color:#969696;

}
