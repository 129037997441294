.printL__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #a80038;
}
.printL__title {
    font-size: 20px;
    margin-right: 10px;
    font-weight: bold;
    
}
.printL__date {
    font-size: 12px;
    color: #4a4f54;
}