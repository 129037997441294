#root {
  &.fijar-body {
    overflow: hidden;
  }
}
.personnel {
  max-width: 4000px;
  margin-top: 20px;
  width: 100%;
}
.personnel-container {
  width: 100%;
  height: 100%;
  max-width: 4000px;
  border-radius: 10px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  padding: 50px 20px;
  margin-bottom: 20px;
}
.personnel-container-text {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #a80038 0%, #ff2631 100%);
  border-image-slice: 1;
  padding-bottom: 20px;
  margin-bottom: 30px;
  font-weight: 700;
}
.personnel-container-data {
  overflow: auto;
}
.start-date {
  @media (max-width: 768px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    font-weight: 700;
  }
  input {
    @media (max-width: 768px) {
      width: 100%;
    }
    width: 300px;
    height: 40px;
    padding: 0px 8px 0px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    background-size: cover;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
  }
  select {
    @media (max-width: 768px) {
      width: 100%;
    }
    width: 300px;
    height: 40px;
    padding: 0px 8px 0px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    background-size: cover;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
  }
}
.data-one {
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
  display: flex;
  gap: 120px;

  select {
    @media (max-width: 768px) {
      width: 100%;
    }
    width: 300px;
    height: 40px;
    padding: 0px 8px 0px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    background-size: cover;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
  }
  p {
    height: 40px;
    padding: 0px 8px 0px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #f8f8fc;
    background-size: cover;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.phone-number {
  @media (max-width: 768px) {
    width: 100%;
  }
  display: flex;
  gap: 30px;
  width: 431px;

  .lada {
    width: 100px;
    height: 40px;
    padding: 0px 8px 0px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    background-size: cover;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
  }
}
.modal {
  @media (max-width: 768px) {
  }
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 2800px;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: 0;
}
.modal-container {
  @media (max-width: 375px) {
  }
  display: block;
  position: relative;
  height: 430px;
  width: min(90%, 800px);
  border-radius: 15px;
  background: #fff;
  z-index: 1;

  p {
    margin: 30px;
  }
}
.select-modal {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-bottom: 30px;
}
.select-modal select {
  @media (max-width: 768px) {
    width: 95%;
  }
  width: 300px;
}
.select-modal-description {
  display: flex;
  gap: 30px;
  align-items: center;
}
.size {
  width: 100px !important;
}
.btn-cerrarModal:hover {
  cursor: pointer;
}
.btonverde {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 52px;
  height: 26px;
  border-radius: 28px;
  background-color: green;
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.1);
}
.btnblanco {
  margin: 0 2px;
  width: 20px;
  height: 20px;
  background-color: #f8fafb;
  border-radius: 60%;
}
.btongris {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 52px;
  height: 26px;
  border-radius: 28px;
  background-color: #ebedee;
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.1);
}
.btnmalva {
  margin: 0 2px;
  width: 20px;
  height: 20px;
  background-color: #bbbfdc;
  border-radius: 60%;
}
.total {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  border-top: 1px solid gray;

  p {
    margin-top: 10px;
  }
}
.total span {
  font-weight: 700;
}
.inputs-modal {
  @media (max-width: 480px) {
    flex-direction: column;
  }
  display: flex;
  margin-top: 40px;
  margin-left: 30px;
  gap: 30px;
}
.inputs-modal input {
  @media (max-width: 480px) {
    width: 250px;
  }
  width: 300px;
}
.display-phone{
  @media (max-width: 480px) {
    flex-direction: column;
  }
}
.input-number input {
  width: 200px;
}
.inputs-column {
  display: flex;
  flex-direction: column;
  max-width: 732px;
}
.textarea2 {
  width: 100%;
}
.textarea2 textarea {
  max-width: 732px;
  height: 100px;
  padding: 0px 8px 50px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  margin-bottom: 30px;
}
.modal-asign {
  margin-top: 20px;
}
.select-modal--request {
  width: 250px;
  height: 40px;
  padding: 0px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #f8f8fc;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.input-style-request {
  width: 250px;
  height: 40px;
  padding: 0px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
