.ConfirmReception
{
     position: relative;
     .column
     {
        text-align: center!important;
     }
    .select-container
    {
        
        width: 350px;
        margin-top: 35px;
    }
    
    .div-container-stand
    {
        margin-top: 31px;
    }
    .input-search
    {
        display: flex;
        align-items: center;
        padding-top: 20px;
        .btn-search
        {
            width:40px ;
            height:40px ;
            background:#308ac9;
            border-radius: 10px;
            border:none;
            
        }
        .button-container
        {
            display: flex;
            margin-left: 35px;
        }
    }
    .btn2{
        height: 40px;
        margin-right: 10px;
        border-radius: 10px;
        background-color: #036402 !important ;
        font-size: 12px;
        color: #f8f8fc;
        text-decoration: none solid rgb(248, 248, 252);
        text-align: center;
        border: none;
        font-weight: bolder;
        &:hover {
            background-color: #079105 !important;
        }
    }
    .btn1{
        height: 40px;
        margin-right: 10px;
        border-radius: 10px;
        background-color: #308ac9 !important ;
        font-size: 12px;
        color: #f8f8fc;
        text-decoration: none solid rgb(248, 248, 252);
        text-align: center;
        border: none;
        font-weight: bolder;
        &:hover {
            background-color: #4d98ce !important;
        }
    }
    @media (max-width:700px)
    {
        .select-container
        {
            
            width: 100%;
        }
        .input-search
        {
            flex-direction: column;
            // width: 100%;
            align-items: initial;
            .button-container
            {
                margin-left: 0px;
                width: 100%;
                justify-content: flex-end;
            }
        }
    }
}