.printL__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #a80038;
}
.printL__title {
    font-size: 20px;
    margin-right: 10px;
    
}
.printL__date {
    font-size: 12px;
    color: #4a4f54;
}
.printL__container {
    
    .printL__container-inputs {
        margin-top: 65px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
        .printL__input-container {
            margin: 5px;
            min-width:270px ;
            width: 100%;
            max-width: 500px;
            display: flex;
            flex-wrap: nowrap;
            

            
        }
        .printL__input-container1 {
            margin: 5px;
            min-width:150px ;
            display: flex;
            flex-wrap: nowrap;
            

            
        }
        .printL__btn-search{
            width: 40px;
            height: 40px;
            border-radius: 10px;
            background-color: #308ac9;
            background-size: cover;
            border: 1px solid #308ac9;
            transition: background .3s ease;
            &:hover{
                background-color: #459bd8;
            }

        }
        .printL__btn-reset{
            max-width: 157px;
            height: 40px;
            border-radius: 10px;
            background-color: #308ac9;
            background-size: cover;
            font-family: Poppins;
            font-size: 12px;
            color: #f8f8fc;
            text-decoration: none solid rgb(248, 248, 252);
            text-align: center;
            border: 1px solid #308ac9;
            transition: background .3s ease;
            &:hover{
                background-color: #459bd8;
            }
        }
        .printL__btn {
            transition: all .3s ;
            max-width: 78px;
            height: 40px;
            border: 1px solid #969696;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background-color: #969696;
            font-size: 14px;
            color: #ffffff;
            text-decoration: none solid rgb(255, 255, 255);
            text-align: center;    
            transition: background .3s ease;
            &:hover{
                background-color: #b1afaf;
            } 
        }
        .active {
            border: 1px solid #308ac9;
            background-color: #308ac9;
            transition: background .3s ease;
            &:hover{
                background-color: #459bd8;
            }
        }
        .printL__input {
            max-width: 510px;
            width: 100%;
            height: 40px;
            padding: 0px 8px;
            border: 1px solid #e3e3e3;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: #ffffff;
            background-size: cover;
            font-family: Poppins;
            font-size: 14px;
            text-decoration: none solid rgb(0, 0, 0);
        }
    }
    .printL__containerCard{
        margin-top: 43px;
        display: flex;
        flex-wrap: wrap;
        
    }
    .printL__button-print{
        width: 245px;
        height: 40px;
        border-radius: 10px;
        background-color: #036402;
        border:none;
        background-size: cover;
        font-family: Poppins;
        font-size: 13px;
        color: #f8f8fc;
        text-decoration: none solid rgb(248, 248, 252);
        text-align: center;
        font-weight: bold;
        transition: background .3s ease;
            &:hover{
                background-color: #077e05;
            }
    }

}
