.table-modal-metanotassign
{
    min-width: 1400px;
}
.search-container-cj
{
    
    display: block;
    
.db
{

    display: flex;
    justify-content: flex-end;

.search
{
    margin-bottom: 20px;
    position: relative;
    width: 250px;
    display: block;
    .input-search
    {
        border-radius: 10px;
        border: 1px solid #e3e3e3;
        height: 40px;
        padding-left: 1rem;
        width: 100%;
        
    }
    .input-search::placeholder
    {
        color: #e3e3e3;
    }
    .input-icon {
        color: #191919;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        left: unset;
        right: 12px;
      }
}
@media (max-width:350px)
{
    .search
    {
        width: 200px;

    }
    
}
}
}
.container-btn-modal-meta
{
 display: flex;
 justify-content: flex-end;
 .btn-cancel
 {
    height: 40px;
    width: 150px;
    background:#e3e3e3 ;
    border:none;
    font-weight: bold;
    font-size: 12px;
    margin-right: 30px;
    border-radius: 10px;

 }
 .btn-save
 {
    height: 40px;
    width: 150px;
    background:#036402 ;
    border:none;
    color: white;
    font-weight: bold;
    font-size: 12px;
    border-radius: 10px;
 }
}