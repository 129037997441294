.card-select{
    border-radius: 10px;
    fill: #ffffff;
    box-shadow: 2px 2px 15px rgb(0 0 0 / 10%);
    padding: 10px 18px 6px 18px;
    height: 54px;
    margin-top: 22px;
}
.inputName{
    font-family: Poppins;
    font-weight: 600;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
}
.inputName-small-word{
    font-size: 16px;
    line-height: 0 !important;
    //margin: 'revert'
}
.inputName-medium-word{
    font-size: 12px;
    margin: 0px !important;
}
.inputName-large-word{
    font-size: 10px;
    margin: 0px !important;
}

.inputSelected-relativeSelect{
    height: 30px;
    padding: 0px 8px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    background-size: cover;
    
}
.inputSelected-relativeSelect p{
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    line-height: 6px;
}

.div-iconCheronDown-rs{
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}

.card-list-select{
    //padding: 20px;
    //height: 248px;
    transition: all 0.3s;
    border-radius: 10px;
    fill: #ffffff;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
}

.search-container{
    height: 40px;
    padding: 0px 8px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    background-color: #ffffff;
    background-size: cover;
    font-family: Poppins;
    font-size: 14px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
}

.sub-search-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-search-container input{
    display: inline-block;
    border: 0px;
    height: 36px;
    width: 90%;
}

textarea:focus, input:focus{
    outline: none;
}

.sub-search-container div{
    display: inline-block;
}

.list-container-rs{
    //width: 308px;
    height: 100px;
    overflow-y: overlay;
    padding: 0px 8px;
    border: 1px solid #e3e3e3;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    margin-top: 18px;
    
}

.item-list-rs{
    width: 100%;
    cursor: pointer;
}

.item-list-rs:hover, .item-list-rs:focus, .item-list-rs:active{
    background: #dadada;
}

.list-container-rs::-webkit-scrollbar {
    width: 5px;
    //height: 8px;
    //display: none;  
}

.list-container-rs::-webkit-scrollbar-thumb {
    background: #8e8e8e;
    border-radius: 4px;
}

@media screen and (max-width: 1246px) {
    .inputName-large-word{
        font-size: 8px;
    }
}

@media screen and (max-width: 991px) {
    .inputName-large-word{
        font-size: 10px;
    }
}

@media screen and (max-width: 767px) {
    .inputName-large-word, .inputName-medium-word{
        font-size: 12px;
    }
}