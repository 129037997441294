@import '../../../../../resource/sass/variables.scss';

.sectionActions{
    display:flex;
    gap: 10px;
    align-items: center;
}


.centeredActions{
    display:flex;
    align-items: center;
}

.greenBtn{
    width: 100px;
    height: 30px;
    border-radius: 28px;
    background-color: #036402;
    font-family: Poppins;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    border:none!important;
    
}
@media (max-width:765px)
{
    .p-datatable
    {
        .p-paginator-bottom
        {
            display: flex;
            
        }
        
    }  
    .paginator-before
    {
        margin-right:0px!important;
    }
    .paginator-after
    {
        margin-left:0px!important;
    }
    

}

.paginator-before
{
    margin-right:20px;
    
}
.paginator-after
{
    margin-left:20px;
}


.p-paginator{
    border:none!important;
    justify-content: flex-end!important;
    width:500px;
    float:right;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
    background-color: #036402!important;
    color:#ffffff!important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
        width:30px!important;
        //height:30px!important;
        border-radius:50%;
}

.btnModalCancel{
    width: 196px;
    height: 40px;
    border-radius: 10px;
    background-color: #ffffff;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-align: center;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
    border:none;
}


.btnModalDo {
    width: 196px;
    height: 40px;
    border-radius: 10px;
    background-color: #308ac9;
    font-family: Poppins;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    border: none;
    margin-left: 10px;
}

//.ant-modal-footer{
//    display:flex;
//    justify-content: center;
//}

// .pi{
//     display:none!important;
// }


.contentModal{
    text-align: center;
    img{
        width:112px;
        height:112px;
        transform:rotate(180deg)
    }
}

.ant-modal-content{
    width:500px!important;
}



.titleModal{
    font-size:18px;
    text-align:center;
    font-weight: bold;
    margin-top:31px;

}

.table-pos th {
    background-color: $red !important;
    padding: 1px 30px !important;
    border: 1px solid #e9ecef !important;
    border-width: 0 0 1px 0 !important;
    // font-weight: 600 !important;
    color: #fff !important;
    transition: box-shadow 0.2s;
    font-size: 12px !important;
    font-family: Poppins;
    height: 55px !important;
    width: 200px;
}

.table-pos  th:first-child {
    border-top-left-radius: 10px !important;
    width: 100px;
}

.table-pos  th:last-child {
    border-top-right-radius: 10px !important;
}

.table-pos td {
    border: 0px !important;
    padding: 10px 30px !important;
    font-size: 12px !important;
    font-family: Poppins !important;
    border-width: initial !important;
}

.btnedit-table{
    cursor: pointer;
}


