.Modal__background{
    opacity: 0.7;
    margin-left: 0px !important;
    z-index: 3;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
}
.Modal_container{
    z-index: 5;
    position: absolute;
    max-width: 500px;
    width: 100%;
    height: 364px;
    border-radius: 10px;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 20px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .modal-title {
        top: 40px;
        left: 35px;
        position: absolute;
        font-family: Poppins;
        font-size: 20px;
        color: #000000;
        text-decoration: none solid rgb(0, 0, 0);
        font-weight: bold;
    }
    .w-80{
        width: 80%;
    }
    .icon-alert{
        width: 112px;
        height: 112px;
        fill: #f5a72a;
        transform: scaleY(-1);
    }
    .btn:not(:disabled):not(.disabled){
        box-shadow: 2px 2px 15px  rgba(0,0,0,0.1);
    }

}