.GeneralModal
{
    min-height: 634px;
    overflow-y: auto;

.Modal__background{
    opacity: 0.7;
    margin-left: 0px !important;
    z-index: 3;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
}
.General-Modal_container{
    z-index: 5;
    position: fixed;
    max-width: fit-content;
    width: 100%;
    height: max-content;
    border-radius: 10px;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 20px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-title {
        top: 40px;
        left: 35px;
        position: absolute;
        font-family: Poppins;
        font-size: 20px;
        color: #000000;
        text-decoration: none solid rgb(0, 0, 0);
        font-weight: bold;
    }
    .w-80{
        width: 80%;
    }
    .header-color
    {
        background: #0600a8!important;
    }
    
    }
   
    
    .form
    {
        width: 100%;
    }
   
}
.dt-modal2
{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 18px;
    margin-bottom:20px ;
}

@media (max-width:1024px) {
    
    .General-Modal_container
    {height: 500px!important;}
}
