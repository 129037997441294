.cardInfo {
    width: 304px;
    height: 150px;
    border-radius: 15px;
    fill: #ffffff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    font-family: Poppins;
}

.sectionText {
    width: 100%;

    p {
        font-size: 20px;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
}

.colorText {
    font-size: 20px;
}

.imgSpace {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 30px;
        height: 30px;
    }
}

.boldText {
    font-weight: bold;
    font-size: 30px;
}

.contentCard {
    height: calc(150px - 40px);
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 23px;
    display: flex;
}

.footerCard {
    height: 40px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
