@import '../../../../sass/main.scss';

.container-createplan{
    padding-left: 36px;
}

.form-createplan{
    margin-top: 20px;
}

.div-btns-plans{
    text-align: right;
    margin-bottom: 20px;
}

.div-buttons-plans button{
    margin-left: 10px;
}
@media (max-width:765px)
{
    .p-datatable
    {
        .p-paginator-bottom
        {
            display: flex;
            
        }
        
    }  
    .paginator-before
    {
        margin-right:0px!important;
    }
    .paginator-after
    {
        margin-left:0px!important;
    }
    

}