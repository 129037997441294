.cj-activations-reports
{
    .fz-13
    {
        .inputName-small-word
        {
            font-size: 13px;
            line-height: 20px!important;
        }
    }
    .fz-14
    {
        .inputName-small-word
        {
            font-size: 14px;
            line-height: 20px!important;
        }
    }
    .buttons-container
    {
        display: flex;
        justify-content: flex-end;
    }
    @media (max-width:1271px) {
        .p-lg-3{
            width: 50%;
        }
        
    }
    @media (max-width:576px) {
        .p-lg-3{
            width: 100%;
        }
        
    }

}
    