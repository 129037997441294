.select-commercial
{
    width:180px;
    height: 40px;
    border-radius: 10px;
    font-size: 12px;
    border:1px solid #e3e3e3;
    padding-left: 10px;
}
.logo-commercial
{
    width: 225px;
    height: 53px;
    object-fit: cover;
    margin-bottom: 19px;
}
.treetable
{
    background: #ff2631!important;
    color: white!important;
    font-size: 12px;
}
.treetable-container
{
    width: 1555px;
    font-family: "Poppins";
    
}
.treetable-scroll
{
    overflow-x: scroll;
}
.treetable-column
{
    font-size: 11px;
    height: 50px;
   
    
}
.tableInput
{
    width: 88px;
    height: 35px;
    border:1px solid #e3e3e3;
    border-radius: 10px;
}
.tableInput::placeholder
{
    color:#e3e3e3 ;
}
.treetable-column
{

}
.table-tree-style
{
    // color:red!important;
    border-bottom:0px solid  !important;
}
.p-treetable 
{
    .p-treetable-tbody > tr
    {
      
    border-bottom:2px solid #a80038;
    }
}
.pi{
    display: block!important;
    font-size: 10px;
}
.p-treetable
{
    .p-treetable-tbody > tr > td
    {
        .p-treetable-toggler
        {
            width: 1rem;
            height: 1rem;
            color: #ff5917;
        }
        .p-treetable-toggler::before
        {
            color: #ff5917!important;
        }
        .p-treetable-toggler::after
        {
            color: #ff5917!important;
        }
    }
}  
