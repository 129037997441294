.Modal__background{
    opacity: 0.7;
    margin-left: 0px !important;
    z-index: 3;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
}
.Modal_container{
    z-index: 5;
    position: fixed;
    max-width: 877px;
    width: 100%;
    height: 364px;
    border-radius: 10px;
    background-color: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 20px;
    overflow-x: auto;
    padding-top: 70px;

    .modal-title {
        top: 40px;
        left: 35px;
        position: absolute;
        font-family: Poppins;
        font-size: 20px;
        color: #000000;
        text-decoration: none solid rgb(0, 0, 0);
        font-weight: bold;
    }
    .w-80{
        width: 80%;
    }
    .header-color
    {
        background: #0600a8!important;
    }
    .title-container
    {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding-left: 18px;
        margin-bottom:20px ;
        .title
        {
            font-size: 15px;
            font-weight: bold;
        }
    }
    
}
.dt-modal1
{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 18px;
    margin-bottom:20px ;
}
