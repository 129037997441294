@import '../../../../../sass/variables.scss';


.table-plans th {
    background-color: $red !important;
    padding: 1px 30px !important;
    border: 1px solid #e9ecef !important;
    border-width: 0 0 1px 0 !important;
    color: #fff !important;
    transition: box-shadow 0.2s;
    font-size: 12px !important;
    font-family: Poppins;
    height: 55px !important;
}

.table-plans  th:first-child {
    border-top-left-radius: 10px !important
}

.table-plans  th:last-child {
    border-top-right-radius: 10px !important;
}

.table-plans td {
    border: 0px !important;
    padding: 10px 30px !important;
    font-size: 12px !important;
    font-family: Poppins !important;
    border-width: initial !important;
}

.table-plans tr:nth-child(2n+2) {background: #f3f3f3 !important;}

.btnedit-table{
    color: $blue;
    cursor: pointer;
}

.greenBtn{
    width: 100px;
    height: 30px;
    border-radius: 28px;
    background-color: #036402;
    font-family: Poppins;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    border:none!important;
}

.btnModalCancel{
    width: 196px;
    height: 40px;
    border-radius: 10px;
    background-color: #ffffff;
    font-family: Poppins;
    font-size: 12px;
    color: #000000;
    text-align: center;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
    border:none;
}


.btnModalDo {
    width: 196px;
    height: 40px;
    border-radius: 10px;
    background-color: #308ac9;
    font-family: Poppins;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    border: none;
    margin-left: 10px;
}

.pi{
    display:none!important;
}


.contentModal{
    text-align: center;
    img{
        width:112px;
        height:112px;
        transform:rotate(180deg)
    }
}

.ant-modal-content{
    width:500px!important;
}



.titleModal{
    font-size:18px;
    text-align:center;
    font-weight: bold;
    margin-top:31px;

}

@media (max-width: '600px'){

}