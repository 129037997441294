.sectionCards{
    display:flex;
    justify-content:space-between;
}

.sectionSecondColumn{
    display:flex;
    justify-content: space-between;

}
