.title-header-section{
    font-family: Poppins;
    font-size: 20px;
    color: #000000;
    font-weight: bold;
    text-decoration: none solid rgb(0, 0, 0);
    //text-decoration: none solid rgb(255, 38, 49);
}
.div-headerSection{
    padding-bottom: 10px;
    border-bottom: 2px solid #a80038;
    padding-top:20px;
    padding: 10px;
    //border-bottom: 1px solid linear-gradient(90deg, #a80038 0%, #ff2631 100% );
}
.date-headerSection{
    font-family: Poppins;
    font-size: 12px;
    color: #4a4f54;
    text-decoration: none solid rgb(74, 79, 84);
    text-align: right;
    width: 40%;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: '1000px'){
    .title-header-section {
        font-size: 16px;
    }
    .div-headerSection {
        padding: 0;
    }
    .date-headerSection {
        font-size: 10px;
        margin-left: 10px;
        justify-content: center;
        width: 100%;
    }
}
