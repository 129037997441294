.files-receive-contain {
  margin-top: 30px;
  width: 100%;
  height: 800px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  .contain-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    .input-search {
      display: flex;
      align-items: center;
      padding-top: 20px;
      .btn-search {
        width: 40px;
        height: 40px;
        background: #308ac9;
        border-radius: 10px;
        border: none;
      }
      .button-container {
        display: flex;
        margin-left: 35px;
      }
    }
    .contain-input {
      display: flex;
      align-items: center;
      width: 120px;
      height: 40px;
      background-color: #308ac9;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .title {
        color: #fff;
        font-weight: bold;
        padding: 0 10px;
      }
      .inputReceive {
        width: 250px;
        height: 40px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid #e3e3e3;
        padding: 0 10px;
      }
    }
    .contain-select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      gap: 10px;
      width: 350px;
      height: 50px;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      .select,
      .input {
        width: 200px;
        height: 30px;
        border-radius: 10px;
        font-size: 12px;
        border: 1px solid #e3e3e3;
        color: #a6a6a6;
        padding: 0 10px;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .contain-datatable {
    margin-top: 30px;
    .table-files-receive th {
      background-color: #308ac9 !important;
      color: #fff !important;
      font-weight: bold;
      height: 40px;
      width: 100px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
    .table-files-receive th:first-child {
      border-top-left-radius: 10px;
    }
    .table-files-receive th:last-child {
      border-top-right-radius: 10px;
    }
    .table-files-receive td {
        display: flex;
        justify-content: center;
        border: 1px solid #e3e3e3;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    .table-files-receive .p-datatable-tbody tr:nth-child(2n+1) {
        background: none !important
    }
    .table-files-receive th:nth-child(10) span {
        width:80px !important;
        display: flex;
    }
    .CI-check {
        width: 20px;
        height: 20px;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.15), inset 1px 1px 5px rgba(0,0,0,0.15);
        margin: auto;
    }
  }
  .contain-buttons {
    display: flex;
    justify-content: start;
    gap: 20px;
    .button-blue {
        width: 120px;
        height: 40px;
        background-color: #308ac9;
        color: #fff;
        border-radius: 10px;
        border: none;
    }
    .button-green {
        width: 120px;
        height: 40px;
        background-color: #036402;
        color: #fff;
        border-radius: 10px;
        border: none;
        padding: 0 20px;
    }
  }
}

.contain-modal {
    opacity: 0.7;
    margin-left: 0px !important;
    z-index: 3;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
}
.modal{
    z-index: 5;
    position: absolute;
    max-width: 800px;
    width: 100%;
    height: 450px;
    border-radius: 10px;
    background-color: #FFFFFF;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 20px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        background-color: #308ac9;
        width: 100%;
        height: 50px;
        color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
        margin-top: 10px;
    }
    .close {
        margin-left: 730px;
    }
    .contain {
        display: flex;
        justify-content: space-between;
        gap: 100px;
        margin-top: 40px;
        .contain-info {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}
.modal2{
    z-index: 5;
    position: absolute;
    max-width: 500px;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    background-color: #FFFFFF;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto ;
    padding: 20px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title2 {
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        padding: 10px 20px;
        margin-top: 10px;
        text-align: center;
    }
    .contain2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 100px;
        margin-top: 40px;
        .select {
            width: 300px;
            height: 40px;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            padding: 0 20px;
        }
        .contain-button {
            display: flex;
            gap: 20px;
            .button-white {
                box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
                border: none;
                background: none;
                width: 200px;
                height: 40px;
                border-radius: 10px;
                font-weight: bold;
            }
            .button-blue {
                background-color: #0271c1;
                border: none;
                width: 200px;
                height: 40px;
                border-radius: 10px;
                color: #fff;
                font-weight: bold;
            }
        }
    }
}
