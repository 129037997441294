.cj-recive-letarge
{


.center-absolute-select
{
    .inputName-medium-word
    {
        font-size: 11px;
        text-align: center;
    }
    .card-select-absolute
    {
        height: auto;
    }
    .grid-container-as
        {
            padding-bottom: 10px;
        }
    
}
.grid-container
{
    .p-grid
    {
        align-items: center;
        .btn-duplex
        {
            display: flex;
            align-items: center;
            .btn-duplex-push
            {
                width: 50%;
                height: 40px;
                background: #308ac9;
                border: 1px solid #0271c1;
                border-radius: 10px 0px 0px 10px;
                color: white;
                
            }
            input
            {
                height:40px ;
                border:1px solid #e3e3e3;
                font-size: 14px;
                border-radius: 0px 10px 10px 0px;
                width: 100%;
        
            }
            input::placeholder{
                color: #e3e3e3;
            }
            
            
            
            
        }
        .btn-search
        {
            width: 40px;
            height: 40px;
            background: #308ac9;
            border-radius: 10px;
            outline: none;
            border: none;
        }
       
    }
    
    @media (max-width:767px)
    {
        .btn-search-container
        {
           display: flex;
           justify-content: flex-end; 
        }
    }
    @media (max-width:1024px)
    {
        .p-lg-3{
            width: 50%;
        }
    }
    @media (max-width:768px)
    {
        .p-lg-3{
            width: 100%;
        }
    }
    
   

}
.actions-container
{
    display: flex;
    position: relative;
    right: 10px;
}
.icons-action
{
    padding: 5px;
    align-items: center;
    justify-content: center;
}

.buttons-container
{
    display: flex;
    justify-content: flex-start;
    .btn-clean
    {
        
        background:#0271c1;
        margin-right: 17px;
        width: 100px;
    }
    .btn-reseption
    {
        
        background:#036402 ;
        width:113px;
    }
    button
    {
        color:white;
        font-weight: bold;
        border: none;
        border-radius: 10px;
        height: 40px;
        font-size: 12px;


    }
}
@media (max-width:768px)
{
    .buttons-container
    {
        justify-content: center;
    }
}
}