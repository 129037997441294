@import '../../../../sass/variables.scss';
@import url('../../../../sass/main.scss');

.div-downloads-dashboard{
    margin-bottom: 15px;
    text-align: right;
}
.buttons-container
{
    display: flex;
    justify-content: flex-end;
}
.btn-jf
{
    width: 100px;
    border-radius: 10px;
    border:none;
    outline: none;
    height:40px ;
    color: white;
    font-size: 12px;
    font-weight: bold;
}
@media (max-width:750px)
{
    .div-downloads-dashboard{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .buttons-container
    {
        
        justify-content: center;
    }
}
.text-download-dashboard{
    font-size: 12px;
    font-family: Poppins;
    margin-right: 15px;
}
.icon-word-dashboard{
    color: $blueLight;
    cursor: pointer;
    margin-left: 10px;
    border: 1px solid #eee;
    font-size: 28px;
    padding: 3px;
    border-radius: 2px;
}
.icon-excel-dashboard{
    color: $green;
    cursor: pointer;
    margin-left: 10px;
    border: 1px solid #eee;
    font-size: 28px;
    padding: 3px;
    border-radius: 2px;
}
.table-activations{
    margin-bottom: 40px;
}
.table-activations th {
    padding: 1px 0px !important;
    //border: 1px solid #e9ecef !important;
    border: 0px !important;
    border-width: 0 0 1px 0 !important;
    font-weight: 600 !important;
    color: #fff !important;
    transition: box-shadow 0.2s;
    font-size: 11px !important;
    text-align: center !important;
    font-family: Poppins;
    height: 40px !important;
}

.table-dashboard  th:first-child {
    width: 120px !important;
    border-top-left-radius: 10px !important
}

.table-dashboard  th:last-child {
    border-top-right-radius: 10px !important;
    //font-size: 10px !important;
    width: 110px !important;
}

.nomargin{
    margin: 0px !important;
}

.border-radius-left{
    border-radius: 10px 0 0 0;
}

.border-radius-right{
    border-radius: 0 10px 0 0;
}

.rdtable{
    //width: 100%;
    //padding: 6px 2px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    display: inline-block;
}
.ptn{
    padding-top: 7px;
}
.ptup{
    position: relative;
    top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.prep-child .rdtable{
    padding: 6px;
}

.rdtable label{
    font-weight: 600;
    color: #fff;
    transition: box-shadow 0.2s;
    font-size: 12px;
    font-family: Poppins;
}

.fatherstable{
    width: 100%;
    text-align: center;
    height: 40px;
    padding-top: 5px;
}

.fatherstable label{
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    transition: box-shadow 0.2s;
    font-family: Poppins;
}

.fatherstable-date{
    width: 100%;
    text-align: center;
    height: 80px !important;
    padding-top: 28px;
    border-top-left-radius: 10px !important
}

.fatherstable-date label{
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    transition: box-shadow 0.2s;
    font-family: Poppins;
}

.lastfathertbl{
    border-top-right-radius: 10px !important;
}

.divSUBTH{
    text-align: center;
    height: 40px;
}

.gray-father{
    background-color: $gray !important;
}

.gray-child{
    background-color: #252729;
}

.prep-father{
    background-color: $redTransparent !important;
}

.prep-child{
    background-color: $red !important;
}

.pos-father{
    background-color: $greenTBTransparent !important;
}

.opacityColor{
    opacity:60%;
}

.pos-child{
    background-color: $greenTB !important;
}

.dth-father{
    background-color: $blueLightTransparent !important;
}

.dth-child{
    background-color: $blueLight !important;
    text-align: center;
}
/*
.dth-child label{
    font-size: 10px;
}*/

.gpon-father{
    background-color: $yellowTransparent !important;
}

.gpon-child{
    background-color: $yellow !important;
}

.date-main{
    background-color: $blueLight !important;
    height: 100%;
}

.date-child-activations{
    background-color: $blueLight !important;
}

.date-child-activations:hover{
    background-color: $blue !important;
}
.graylb{
    position: relative;
    top: 8px;
}
.graylb label{
    font-size: 10px !important;
}

.widthtable{
    position: relative;
    display: grid !important;
    float: left;
}

.doubleLetter{
    font-size: 11px;
    display: block;
    line-height: 11px;
}

.row-1{
    width: 8%;
    //width: 98.24px;
}
.row-2{
    width: 14%;
    //width: 171.92px;
}
.row-3{
    width: 20%;
    //width: 245.59px;
}
.row-4{
    width: 24%;
    //width: 294.72px;
}
.row-5{
    width: 28%;
    //width: 343.83px;
}
.row-6{
    width: 6%;
    //width: 73.68px;
}
.rowInfo{
    padding: 10px 0px;
    text-align: center;
    height: 40px;
}
.rowInfo label{
    font-family: Poppins;
    font-size: 11px;
    color: white;
    font-weight: 600;
}

.div-label-row {
    display: inline-block;
    //width: 41px;
}


.div-label-row label{
    color: #000000 !important;
}

//2
.bgrow2{
    background-color: rgb(255 38 49 / 15%);
}
.row-2-item1{
    width: 10%;
}
.row-2-item2{
    width: 32%;
}
.row-2-item3{
    width: 25%;
}
.row-2-item4{
    width: 30%;
}
//3
.bgrow3{
    background-color: rgb(17 171 119 / 15%);
}
.row-3-item1{
    width: 26%;
}
.row-3-item2{
    width: 19%;
}
.row-3-item3{
    width: 14%;
}
.row-3-item4{
    width: 20%;
}
.row-3-item5{
    width: 20%;
}
//4
.bgrow4{
    background-color: rgb(48 138 201 / 15%);
}
.row-4-item1{
    width: 21%;
}
.row-4-item2{
    width: 13%;
}
.row-4-item3{
    width: 25%;
}
.row-4-item4{
    width: 35%;
}
//5
.bgrow5{
    background-color: rgb(245 167 42 / 15%);
}
.row-5-item1{
    width: 13%;
}
.row-5-item2{
    width: 19%;
}
.row-5-item3{
    width: 10%;
}
.row-5-item4{
    width: 15%;
}
.row-5-item5{
    width: 7%;
}
.row-5-item6{
    width: 8%;
}
.row-5-item7{
    width: 28%;
}

.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th{
    white-space: nowrap;
    padding: 3px 10px !important;
}
//6
.bgrow6{
    background-color: rgb(37 39 41 / 15%);
}

.month-ago-table{
    color: black !important;
}

@media screen and (max-width: 1254px) {
    .rdtable label{
        font-size: 8px;
    }
}   
.p-datatable .p-column-header-content {
    place-content: center;
}
.main-bot{
    background-color: #246796 !important;
    // nuevo codigo
    width: 100%;
    //***********
}
.prep-bot{
    background-color: #ff263126 !important;
    div{
        color: black !important;
    }
}
.pos-bot{
    background-color: #11ab7726 !important;
    div{
        color: black !important;
    }
}
.dth-bot{
    background-color: #308ac926 !important;
    div{
        color: black !important;
    }
}
.gpon-bot{
    background-color: #f5a72a26 !important; 
    div{
        color: black !important;
    }
}
.btnGreen
{
    outline: none;
    background: #036402 !important;
    color: white;
    border-radius:28px;
    font-size: 14px;
    cursor: pointer;
    border:none;
    height: 30px;
    width:100px ;
}
@media(max-width:500px)
{
    .p-paginator
    {
        padding: 0;
    }
}
// thead
// {
//     display: flex;
// }
.table-conatiner
{
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;
    
}

@media (min-width:1757px) {
    .table-conatiner
    {
        width: 100%;
        overflow-x: scroll;
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        
    }         
}

.table
{
    width:1530px;
    height: 399px;
    font-size: 12px;
    border: hidden;
    .header
    {
        position: sticky;
        display: flex;
        .header1-minimized
        {
            .title-container
            {
                color: white;
                background: #308ac9;
                justify-content: space-around;
                display: flex;
                align-items: center;
                height: 80px;
                width: 192px!important;
                border-radius: 10px 0 0 0;
            }
            
            .subtitle
            {
                width: 100%;
                height: 40px;
                display: flex;
                background:#246796;
                justify-content: center;
                font-size: 12px;
                align-items: center;
                color: white;
            }
            .center-y
            {
                vertical-align: middle;
            }
        }
        .header1
        {
           
            
           
            
            .title-container
            {
                color: white;
                background: #308ac9;
                justify-content: space-around;
                display: flex;
                align-items: center;
                height: 80px;
                width: 455px!important;
                border-radius: 10px 0 0 0;
            }
            .subtitle
            {
                width: 100%;
                height: 40px;
                display: flex;
                background:#246796;
                justify-content: center;
                font-size: 12px;
                align-items: center;
                color: white;
            }
            .center-y
            {
                vertical-align: middle;
            }
        }
        .header2
        {
            color: white;
            .title-container
            {
                width: 269px;
                display: flex;
                justify-content: center;
                font-size:20px ;
                font-weight: bolder;
                
            
            }
            .title-container-border
            {
                width: 269px;
                display: flex;
                justify-content: center;
                font-size:20px ;
                font-weight: bolder;
                border-radius: 0 10px 0 0;
                
            }
            .subtitle-container
            {
                display: flex;
                justify-content: space-around;
                .subtitle
                {
                    font-size: 12px;
                }
            }
            .header-value
            {
                display: flex;
                justify-content: space-around;
                height: 38px;
                align-items: center;
               
                .value
                {
                    color: #000000;
                    font-size: 12px;
                    opacity:100%;
                }
            }
        }
        
        
    }
    td{
        width: 151px!important;
        text-align: center;
        border: 1px solid #e3e3e3;
        font-weight: bold;
        vertical-align: middle;
    }
    .td-minimized
    {
        width: 192px!important;
    }
    tr
    {
        border: none;
        border-collapse: hidden;
    }
    .td
    {
        font-weight: 400;
        width: 89.7px!important;;
    }
    	
}
.table-min
{
    width:1268px;
    height: 399px;
    font-size: 12px;
    border: hidden;
    .header
    {
        position: sticky;
        display: flex;
        .header1-minimized
        {
            .title-container
            {
                color: white;
                background: #308ac9;
                justify-content: space-around;
                display: flex;
                align-items: center;
                height: 80px;
                width: 192px!important;
                border-radius: 10px 0 0 0;
            }
            
            .subtitle
            {
                width: 100%;
                height: 40px;
                display: flex;
                background:#246796;
                justify-content: center;
                font-size: 12px;
                align-items: center;
                color: white;
            }
            .center-y
            {
                vertical-align: middle;
            }
        }
        .header1
        {
           
            
           
            
            .title-container
            {
                color: white;
                background: #308ac9;
                justify-content: space-around;
                display: flex;
                align-items: center;
                height: 80px;
                width: 455px!important;
                border-radius: 10px 0 0 0;
            }
            .subtitle
            {
                width: 100%;
                height: 40px;
                display: flex;
                background:#246796;
                justify-content: center;
                font-size: 12px;
                align-items: center;
                color: white;
            }
            .center-y
            {
                vertical-align: middle;
            }
        }
        .header2
        {
            color: white;
            .title-container
            {
                width: 269px;
                display: flex;
                justify-content: center;
                font-size:20px ;
                font-weight: bolder;
                height: 47.42px;
                align-items: center;
            
            }
            .title-container-border
            {
                width: 269px;
                display: flex;
                justify-content: center;
                font-size:20px ;
                font-weight: bolder;
                border-radius: 0 10px 0 0;
                height: 47.41px;
                align-items: center;
            }
            .subtitle-container
            {
                display: flex;
                justify-content: space-around;
                height: 34.84px;
                align-items: center;
                .subtitle
                {
                    font-size: 12px;
                }
            }
            .header-value
            {
                display: flex;
                justify-content: space-around;
                height: 38px;
                align-items: center;
               
                .value
                {
                    color: #000000;
                    font-size: 12px;
                    opacity:100%;
                }
            }
        }
        
        
    }
    td{
        width: 151px!important;
        text-align: center;
        border: 1px solid #e3e3e3;
        font-weight: bold;
        vertical-align: middle;
    }
    .td-minimized
    {
        width: 192px!important;
        height: 43.84px!important;
    }
    tr
    {
        border: none;
        border-collapse: hidden;
    }
    .td
    {
        font-weight: 400;
        width: 89.7px!important;;
    }
    	
}
.body-table
{
    // padding-top: 120px;
   
    
    
}
.pointer
{
    cursor: pointer;
}
.table-container
{
    display: flex;
}
.table>:not(:first-child)

{
    border:none;
}
