@import '../../../../../sass/main.scss';

.form-createplan{
    margin-top: 20px;
}

.contain-buttons{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
}
