.table-four .p-datatable-thead > tr > th {
  background: #f8f8fc !important;
  padding: 10px !important;
  color: black !important;
  font-size: 12px !important;
}
.table-four .p-datatable-thead > tr > th {
  justify-content: left !important;
  text-align: left;
}
.table-four .p-column-header-content {
  place-content: left !important;
  margin-left: 20px;
}
.template .centeredActions {
  display: flex !important;
  justify-content: left !important;
}
.table-four .p-datatable-tbody > tr > td {
  text-align: left !important;
  padding-left: 50px !important;
}
.table-four .p-column-header-content {
  place-content: flex-start !important;
  padding-left: 20px !important;
}
.table-four th:first-child {
  border-top-left-radius: 0px !important;
}
.table-four th:last-child {
  border-top-right-radius: 10px !important;
}
.table-four input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  vertical-align: -25%;
  height: 3ex;
  width: 3ex;
  background-color: white;
  border-radius: 0px;
  box-shadow: none;
  margin-right: 0.5em;
  border: 0.5px solid rgb(179, 179, 179);
}
