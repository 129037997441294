.CardDowload
{
    padding-left: 2%;
    padding-right: 2%;
    
    .body
    {
        height: 120px;
        overflow-y: scroll;
    }
    .bg-gray
    {
        background: #f8f8fc;
    }
    .title-container
    {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 18px;
        font-size: 16px;
        font-weight: bold;
    }
    .header
    {
        font-weight: bold;
        font-size: 12px;
        display: flex;
        height: 40px;
        padding-left: 2%;
        padding-right: 2%;
        color: white;
        align-items: center;
        border-radius: 10px 10px 0 0;
        
        .column1
        {
            min-width: 170px;
            display: flex;
            justify-content: center;
        }
        .column2
        {
            width: 100%;
        }
        .column3{
            width: 120px;
            display: flex;
            justify-content: center;
        }
    }
    .content
    {
        display: flex;
        padding-left: 2%;
        padding-right: 2%;
        height: 60px;
        
        .document
        {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 170px;
            
            .btn-document
            {
                background:white;
                border:1px solid #e3e3e3;
                height: 40px;
                width: 40px;
                margin-top: 10px;
                margin-bottom: 10px;
                border-radius: 5px;
                
                .img-container
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    .img
                    {
                        width: 17px;
                        height:19px ;
                    }
                }
            }
        }
        .description
        {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;
        }
        .action
        {
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            .btn
            {
                width: 88px;
                height: 40px;
                border-radius: 10px;
                border: none;
                color: white;
                font-weight:bold ;
                font-size: 12px;
                
            }
        }
    }
    @media (max-width:850px)
{
    .body
    {
        overflow-x: scroll;
        .content 
        {
            .description
            {
                min-width: 500px;
            }
        }
    
        .header
        {
            width: fit-content;
            .column2
            {
                min-width: 500px;
            }
            .column3
            {
                justify-content: flex-end;
                padding-right: 50px;
            } 
        }
    }
    
    
    
}
}
