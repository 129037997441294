.container-height {
  height: calc(100vh - 150px);
}
p {
  font-size: 10px;
}
.textarea {
  max-width: 1223px;
  width: 100%;
  height: 200px;
  padding: 0px 8px 60px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.staff-detail-header {
  display: flex;
  gap: 31px;
  justify-content: space-between;
}
.staff-detail-header {
  @media (max-width: 1080px) {
    display: flex;
    flex-direction: column;
  }
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 20px;
}
.staff-detail-header-nav {
  @media (max-width: 480px) {
    display: contents;
  }
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  margin-bottom: 20px;

  a {
    @media (max-width: 480px) {
      border-radius: 10px;
    }
    padding: 7px 10px;
    border: 2px solid #e3e3e3;
    text-align: center;
  }
  .a-l {
    @media (max-width: 480px) {
      border-radius: 10px;
    }
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .a-r {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  a:hover {
    color: black;
  }
}
.select-green {
  background-color: #036402;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  font-weight: 700;
}
.select-green:hover {
  color: white !important;
}
.not-select {
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  font-weight: 700;
}
.staff-detail-header-button {
  @media (max-width: 480px) {
    margin: 10px auto;
  }
  @media (max-width: 1080px) {
    margin: 10px auto;
  }
  display: flex;
  max-width: 300px;
  width: 100%;
  gap: 20px;

  button {
    margin: 0;
  }
}
.button-white {
  max-width: 300px;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: center;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  p {
    margin: 0;
  }
}
.button-red {
  max-width: 400px;
  width: 100%;
  border-radius: 10px;
  background-color: #ff2631;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #ffffff;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: center;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  p {
    margin: 0;
  }
}
.profile-container {
  @media (max-width: 480px) {
    flex-direction: column;
  }
  max-width: 1920px;
  margin: 20px 0;
  display: flex;
  overflow: auto;
}
.profile-container-img {
  @media (max-width: 480px) {
    display: flex;
  }
}
.noPicture .profile-data-information {
  @media (max-width: 480px) {
    width: 0;
    height: 0;
  }
}
.profile-data-information {
  width: 190px;
  height: 95px;
  text-align: center;
  .profile-data-information-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto;
  }
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.data-information {
  @media (max-width: 480px) {
    width: 100%;
  }
  width: 1200px;
  overflow: auto;
  border-bottom: 2px solid #e3e3e3;
  margin-bottom: 20px;
}

.data-information-container {
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0;
  }
  display: flex;
  gap: 70px;
  span {
    font-weight: 700;
  }
}
.text-social {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.socialSecurity {
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0;
    margin: 0;
  }
}
.dataGeneral {
  margin-bottom: 20px;
}

.dataGeneral .p-datatable .p-datatable-thead > tr > th {
  background-color: #0271c1 !important;
}
.update-button {
  display: grid;
  place-items: center;
  width: 150px;
  height: 30px;
  border-radius: 10px;
  background-color: #0271c1;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
}
.centeredStatus,
.centeredStatusP {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
}
.centeredStatus {
  @media (max-width: 768px) {
    margin-right: 0px;
  }
  background-color: #036402;

}
.centeredStatusP {
  background-color: #969696;
}
.penalizaciones .p-datatable .p-datatable-tbody > tr > td {
  font-size: 11px !important;
}
.dataGeneralBtn {
  display: flex;
  justify-content: right;
  margin: 20px 0;
}
.dataGeneralBtn input {
  width: 250px;
  height: 40px;
  padding: 0px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.dataGeneralBtn button {
  background-color: #036402;
}
.tableDownload{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tableDownload .p-datatable {
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



