.cambio-table .p-datatable .p-datatable-tbody > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.files-search-contain {
  padding-left: 20px;
  .input-search {
    display: flex;
    align-items: center;
    padding-top: 20px;
    .btn-search {
      width: 40px;
      height: 40px;
      background: #308ac9;
      border-radius: 10px;
      border: none;
    }
    .button-container {
      display: flex;
      margin-left: 35px;
    }
  }
}

.files-search-contain1 {
  width: 100%;
  margin: 20px 0;
  padding: 30px 20px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  .contain-title {
    width: 100%;
    height: 50px;
    background-color: #308ac9;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }
  .contain-alert {
    float: right;
    margin-top: 20px;
    .alert-yellow {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f8a100;
      width: 140px;
      height: 30px;
      border-radius: 20px;
      color: #fff;
      gap: 7px;
      font-weight: bold;
      font-size: 10px;
    }
    .alert-red {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ff2631;
      width: 140px;
      height: 30px;
      border-radius: 20px;
      color: #fff;
      gap: 7px;
      font-weight: bold;
      font-size: 10px;
      margin-top: 20px;
      padding: 5px 10px;
    }
  }
  .contain-data {
    display: flex;
    .contain-info {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 30px 20px;
      .bold {
        font-weight: bold;
        font-size: 14px;
      }
      .bold-blue {
        font-weight: bold;
        color: #308ac9;
        font-size: 18px;
      }
      .bold-green {
        font-weight: bold;
        color: #036402;
        font-size: 14px;
      }
      .blueColor {
        font-size: 18px;
        color: #308ac9;
      }
      .contain {
        display: flex;
        gap: 50px;
      }
      .xbold {
        font-weight: normal;
      }
      .top {
        margin-top: 20px;
      }
      .topplus {
        margin-top: 100px;
      }
      .check {
        width: 20px;
        height: 20px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15),
          inset 1px 1px 5px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
      }
    }
  }
  .linea-info {
    margin: 50px 0;
    width: 100%;
    height: 1px;
    background-color: rgb(0, 0, 0);
  }
}

.files-search-contain2 {
  padding: 0 20px;
  .title {
    font-weight: bold;
    font-size: 16px;
  }
  .contain-input {
    margin-top: 15px;
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .files-input {
      border: 1px solid rgb(227, 227, 227);
      border-radius: 8px;
      width: 300px;
      height: 40px;
      padding: 0 15px;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      font-size: 20px;
    }
    .date-result {
      margin-top: 30px;
      color: #036402;
      font-size: 14px;
      width: 120px;
      .bold-green {
        font-weight: bold;
      }
    }
  }
  .contain-input1 {
    display: flex;
    gap: 50px;
    overflow: auto;
  }
  .contain-button {
    display: flex;
    justify-content: right;
    .button-green {
      margin-top: 20px;
      border: none;
      width: 200px;
      height: 40px;
      background-color: #036402;
      color: #fff;
      font-size: 11px;
      border-radius: 10px;
      font-weight: bold;
    }
  }
}

.observations-contain {
  display: flex;
  gap: 40px;
  width: 100%;
  .contain-cbox {
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 40px 30px;
    .observations-contain-buttons {
      display: flex;
      justify-content: right;
      gap: 20px;
      margin-top: 20px;
      .button-blue {
        border: none;
        border-radius: 10px;
        width: 150px;
        height: 40px;
        background-color: #0271c1;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }
      .button-green {
        border: none;
        border-radius: 10px;
        width: 150px;
        height: 40px;
        background-color: #036402;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }
    }
    .contain-textarea {
      padding: 0 20px;
      margin-top: 40px;
      .textarea {
        width: 100%;
        height: 150px;
        max-height: 150px;
        min-height: 150px;
        border: 1px solid rgb(227, 227, 227);
        margin-top: 10px;
      }
      .title-observation {
        font-size: 14px;
      }
    }
    .contain-cboxs {
      display: flex;
      .contain-cbox1 {
        padding: 0 20px;
        margin-top: 20px;
      }
    }
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .contain {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      .cbox {
        min-width: 20px;
        width: 20px;
        height: 20px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15),
          inset 1px 1px 5px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.observations-contain-table {
  width: 100%;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 30px 40px;
  .observations-contain-table-title {
    display: flex;
    align-items: center;
    gap: 20px;
    .title-blue {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #308ac9;
      width: 450px;
      height: 50px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .title-black {
      margin: 0 10px;
      font-size: 16px;
      font-weight: bold;
    }
    .title-black:hover {
      cursor: pointer;
    }
  }
  .search-files-datatable th {
    background-color: #308ac9 !important;
    color: #fff !important;
    display: flex;
    justify-content: center;
    font-size: 12px;
    height: 40px;
  }
  .search-files-datatable tr th:first-child(4) {
    width: 1150px;
  }
  .search-files-datatable th:nth-child(5) {
    width: 200px;
    border-top-right-radius: 10px;
  }
  .search-files-datatable td {
    display: flex;
    justify-content: center;
    border: 2px solid #e3e3e3 !important;
    background-color: #fff !important;
    font-size: 12px;
  }
  .search-files-datatable2 th {
    background-color: #308ac9 !important;
    color: #fff !important;
    font-size: 12px;
    width: 200px;
    height: 40px;
  }
  .search-files-datatable2 th:first-child {
    border-top-left-radius: 10px;
  }
  .search-files-datatable2 th:last-child {
    border-top-right-radius: 10px;
  }
  .search-files-datatable2 td {
    text-align: center !important;
    border: 2px solid #e3e3e3 !important;
    background-color: #fff !important;
    font-size: 12px;
  }
  .datatable2-title {
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0;
  }
}

.contain-modal {
  opacity: 0.7;
  margin-left: 0px !important;
  z-index: 3;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: rgb(0, 0, 0);
  width: 100vw;
  height: 100vh;
}
.modal {
  z-index: 5;
  position: absolute;
  max-width: 550px;
  width: 100%;
  height: 350px;
  border-radius: 10px;
  background-color: #ffffff;
  top: 35%;
  left: 0;
  right: 0;
  bottom: 0;
  // margin: auto ;
  padding: 20px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    background-color: #308ac9;
    width: 100%;
    height: 50px;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 20px;
    margin-top: 10px;
  }
  .close {
    margin-left: 480px;
  }
  .contain {
    display: flex;
    width: 100%;
    padding: 0 30px;
    margin-top: 40px;
    .contain-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .bold {
        font-weight: bold;
      }
    }
  }
}
.p-datatable-wrapper {
  overflow: auto;
}

@media (max-width: 900px) {
  .contain-data {
    flex-direction: column;
    .contain-info {
      width: 100%;
      font-size: 12px;
    }
  }
  .files-search-contain1 .contain-alert {
    float: none;
    margin-top: 20px;
    padding: 0 20px;
    .alert-yellow,
    .alert-red {
      width: 100%;
    }
  }
  .files-search-contain1 {
    height: 2020px;
  }
  .files-search-contain1 .contain-data .contain-info {
    gap: 0;
  }
  .files-search-contain1 .contain-data .contain-info .contain {
    flex-direction: column;
    gap: 0;
  }
  .files-search-contain2 .contain-input1 {
    flex-direction: column;
    gap: 0;
  }
  .files-search-contain2 {
    padding: 0;
  }
  .files-search-contain2 .contain-input .files-input {
    width: 100%;
  }
  .files-search-contain2 .contain-input .date-result {
    margin-top: 5px;
    text-align: right;
  }
  .files-search-contain2 .contain-button .button-green {
    width: 100%;
  }
  .observations-contain .contain-cbox .contain-cboxs {
    flex-direction: column;
  }
  .observations-contain .contain-cbox {
    width: 100%;
    height: 1000px;
    font-size: 12px;
    padding: 10px 0;
  }
  .observations-contain .contain-cbox .title {
    padding: 10px;
  }
  .observations-contain {
    flex-direction: column;
    height: 2000px;
  }
  .observations-contain .contain-cbox .contain-textarea .textarea {
    width: 100%;
  }
  .observations-contain-table .observations-contain-table-title {
    flex-direction: column;
    width: 100%;
  }
  .observations-contain-table .observations-contain-table-title .title-blue {
    width: 100%;
    font-size: 12px;
    text-align: center;
  }
  .observations-contain-table .observations-contain-table-title .title-black {
    font-size: 12px;
    text-align: center;
  }
}
