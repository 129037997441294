.files-form-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid #000;
  .files-form-title {
    font-size: 20px;
    color: #000;
    font-weight: bold;
  }
  .files-form-date {
    font-size: 12px;
    color: #4a4f54;
    text-align: right;
  }
}
.files-form-contain {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 700px;
  font-family: "Poppins";
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  margin-top: 30px;
  .filesForm-contain-card {
    .prepaid {
      width: 380px;
      height: 40px;
      background-color: #ff2631;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      padding: 5px 0;
    }
    .pospaid {
      width: 380px;
      height: 40px;
      background-color: #11ab77;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      padding: 5px 0;
    }
    .dth {
      width: 380px;
      height: 40px;
      background-color: #308ac9;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      padding: 5px 0;
    }
    .gpon {
      width: 380px;
      height: 40px;
      background-color: #f5a72a;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      padding: 5px 0;
    }
    .contain {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-left: 30px;
      gap: 20px;
      .contain-icon {
        display: flex;
        align-items: center;
        gap: 10px;
        .color-red {
          color: #ff2631;
        }
        .color-green {
          color: #11ab77;
        }
        .color-blue {
          color: #308ac9;
        }
        .color-yellow {
          color: #f5a72a;
        }
        .icon-red {
          border: 2px solid rgba(255, 38, 49, 0.5);
          border-radius: 5px;
          padding: 8px;
          height: 40px;
          width: 40px;
        }
        .icon-green {
          border: 2px solid rgba(17, 171, 119, 0.5);
          border-radius: 5px;
          padding: 8px;
          height: 40px;
          width: 40px;
        }
        .icon-blue {
          border: 2px solid rgba(48, 138, 201, 0.5);
          border-radius: 5px;
          padding: 8px;
          height: 40px;
          width: 40px;
        }
        .icon-yellow {
          border: 2px solid rgba(245, 167, 42, 0.5);
          border-radius: 5px;
          padding: 8px;
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}
