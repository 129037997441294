textarea {
  height: 60px;
  padding: 10px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}

.personnel-container-means {
  margin: 30px;
}
.personnel-container-search {
  @media (max-width: 768px) {
    flex-direction: column;
  }
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
}
.personnel-container-search-lab-inp {
  display: flex;
  gap: 20px;
  align-items: center;

  @media (min-width: 768px) {
    width: 650px;
  }
}
.personnel-container-search-lab-inp label {
  margin: auto 0;
  width: min(100%, 350px);
  font-family: Poppins;
  font-size: 14px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.personnel-container-search-button {
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
.datatable .p-datatable .p-datatable-thead > tr > th {
  background: #0600a8 !important;
  padding: 10px !important;
  color: white !important;
  font-size: 12px !important;
}
.modal {
  @media (max-width: 768px) {
  }
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 4000px;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: 0;
}
.modal-container {
  overflow: auto;
}
.modal-container .input-modal {
  width: 300px;
  overflow: auto;
}
.modal-container-dir {
  @media (max-width: 768px) {
    max-width: 300px;
    width: 90%;
    height: 90%;
  }
  overflow: auto;
  height: 400px;
  display: block;
  position: relative;
  width: min(90%, 800px);
  border-radius: 15px;
  background: #fff;
  z-index: 1;
  justify-content: center;

  p {
    margin: 20px 30px;
  }
}
.modal-container-text {
  @media (max-width: 768px) {
    padding-left: 0;
    font-size: 15px;
    justify-content: center;
  }
  font-family: Poppins;
  font-size: 15px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: center;
  font-weight: 700;
  display: flex;
  justify-content: left;
  padding-left: 40px;
}
.contenido-modal {
  @media (max-width: 768px) {
    margin: 10px;
  }
  display: flex;
  flex-direction: column;
  margin: 20px 76px;
}
.input-modal {
  height: 40px;
  padding: 0px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.input-gray {
  border: 1px solid #e3e3e3;
  background-color: #f8f8fc;
}
.iconEliminar {
  margin: 0;
  border: 1px dashed #ff2631;
}
.modalDeliver {
  height: 95%;
}
.modalDeliver input {
  height: 30px;
}
.modalDeliver label {
  margin: 0;
}
