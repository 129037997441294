.UploadDocuments
{
    .top-container
    {
        
        
        .input-search
        {
            display: flex;
            align-items: center;
            padding-top: 20px;
            
            
            .btn-search
            {
                width:40px ;
                height:40px ;
                background:#308ac9;
                border-radius: 10px;
                border:none;
                
            }
            .btn-clean-container
            {
                display: flex;
                
                justify-content: flex-end;
                .btn-clean
                {
                    
                    height:40px ;
                    background:#308ac9;
                    border-radius: 10px;
                    border:none;
                    color: white;
                    font-weight: bold;
                    font-size:12px ;
                    padding-left: 15px;
                    padding-right: 15px;
                    
                }
            }
            
            .button-container
            {
                display: flex;
                margin-left: 35px;
                justify-content: space-between;
                width: 66%;
            }
        }
        @media (max-width:700px)
        {
            .select-container
            {
                
                width: 100%;
            }
            .input-search
            {
                flex-direction: column;
                // width: 100%;
                align-items: initial;
                .button-container
                {
                    margin-left: 5px;
                    
                    width: auto;
                    justify-content: space-between;
                }
            }
        }
    }
    .data-container
    {
        margin-top: 37px;
        margin-bottom: 86px;
        display: flex;
        .element
        {
            width: 25%;
            font-size:14px;
            .info
            {
                font-weight: bold;
            }
        }
    }
    .header-body
    {
        height: 50px;
        border-radius: 10px 10px 0 0;
        background: #308ac9;
        display: flex;
        align-items: center;
        color: white;
        font-size: 18px;
        font-weight: bold;
        padding-left: 21px;
    }
    // img charge
    .border-container-image
    {
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        width:310px ;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 47px;
        margin-left: 36px;
        margin-bottom: 50px;
        .title
        {
            color:#969696;
            padding-bottom: 7px;
            padding-top: 18px;
            font-size: 14px;
            

        }
        .container-btn-upload
        {
            display: flex;
            justify-content: center;
            .btn-upload
            {
                
                height: 30px;
                background:#308ac9;
                color: white;
                font-weight: bold;
                font-size: 12px;
                margin-bottom: 8px;
                border-radius:10px ;
                border: none;
                outline: none;
            }
            
            
        }
        
    }
   .img-upload-container
   {
    display: flex;
   }
    .charge-img
    {
        width: 310px;
        height: 189px;
        margin-top: 87px;
        margin-left: 36px;
        margin-bottom: 50px;
        .img
        {
            width: 200px;
            height: 200px;
        }
    }
    .btns-container
    {
        display: flex;
        flex-direction: column;
        margin-top: 47px;
        margin-left: 28px;
        .btn
        {
            width: 113px;
            height:40px ;
            font-size: 12px;
            color: white;
            border-radius: 10px;
            border: none;
            margin-bottom:13px ;
            font-weight: bold;
        }
    }
    @media (max-width:1200px)
    {
        .img-upload-container
        {
            display: flex;
            flex-direction: column;
            overflow-x: scroll;
        }
        .border-container-image
        {
            width: 100%;
            margin-top: 30px;
            margin-left: 0px;
            margin-bottom: 20px;
            min-width:450px;
        }
        .charge-img {
            width: 100%;
            height: 189px;
            margin-top: 30px;
            margin-left: 0px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            min-width:450px;
        }
        .btns-container
        {
            justify-content: center;
            .btn
            {
                min-width:400px;
                width: 100%;
            }
        }
        .header-body
        {
            text-align: center;
        }
        
    }
    @media (max-width:400px)
    {
        .header-body
        {
            font-size: 14px;
        }
    }
    @media (max-width:768px) {
        .data-container
        {
            width: 100%;
            flex-direction: column;
            .element
            {
                width: 100%;
                text-align: center;
                margin-top: 10px;
                .info
                    {
                        text-align: center;
                    }
            }
        }
        
        
    }   
  
    
}
.modal-container-upload-doccuments
    {
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.8);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        
       
        .modal-upload-doccuments
        {
            width:500px ;
            height: 346px;
            border-radius: 10px;
            background: white;
            z-index: 3;
            padding: 51px 31px 51px 31px;
            .img-alert-container
            {
                display: flex;
                justify-content: center;
                
            }
        }
        .text-modal-container
        {
            display: flex;
            justify-content: center;
            font-weight: bold;
            font-size:18px ;
            margin-top: 30px;
            margin-bottom: 35px;
            
        }
        .btn-modal-container
        {
            display:flex;
            justify-content: space-between;
            .btn-modal
            {
                background:white ;
                border-radius: 10px;
                border: none;
                outline: none;
                font-size: 12px;
                font-weight: bold;
                width:203px ;
                height:40px ;
                box-shadow: 0px 0px 22px -12px rgba(0,0,0,0.6);
            }
            .btn-modal-blue
            {
                background:#0271c1 ;
                border-radius: 10px;
                border: none;
                outline: none;
                color: white;
                font-size: 12px;
                font-weight: bold;
                width:203px ;
                height:40px ;
            }
        }
        
    }
    .title-swal-upload-documents
        {
            font-size: 18px;
            font-weight: bold;
        }

            .btn-left-upload-documents
            {
                background:white ;
                border-radius: 10px;
                border: none;
                outline: none;
                font-size: 12px;
                font-weight: bold;
                width:203px ;
                height:40px ;
                box-shadow: 0px 0px 61px -12px rgba(0,0,0,0.6);
                margin-right: 32px;
            }
            .btn-right-upload-documents
            {
                background:#0271c1 ;
                border-radius: 10px;
                border: none;
                outline: none;
                color: white;
                font-size: 12px;
                font-weight: bold;
                width:203px ;
                height:40px ;
            }
            @media(max-width:468px)
            {
                .btn-left-upload-documents
                {
                    margin-right: 0px;
                    margin-bottom: 15px;
                }
            }
        
        