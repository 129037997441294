.Modal-container-ips
{
    min-width: 900px;
    .headerips
    {
        display: flex;
        justify-content: space-between;
        .title
        {
            font-size: 20px;
            font-weight: bold;

        }
    }
    .input-container
    {
        display: flex;
        padding-bottom: 20px;
        
        .sub-title
        {
            font-size:12px ;
            font-weight: bold;
            margin-bottom: 10px;

        }
        .input-ips
        {
            width: 250px;
            height: 40px;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            padding-left: 20px;
            margin-right: 30px;
        }
    }
    .profile-image
    {
        display: flex;
        align-items: center;

        height: 40px;
        margin-bottom: 30px;
        margin-top: 17px;
        img
        {
            border-radius:150px;
            width: 40px;
            height: 40px;
            object-fit: cover;
            
        }
        .text-image
        {
            margin-left: 20px;
        }
    }
    .btn-container
    {
        display: flex;
        justify-content: flex-end;
        .btn
        {
            width: 150px;
            height: 40px;
            background: #e3e3e3;
            font-weight: bold;
            font-size: 12px;
            border-radius: 10px;
            border: none;
            color:#000000;
            margin-right: 30px;
    
        }
        .btn-entregar
        {
            width: 150px;
            height: 40px;
            color: white;
            background: #036402;
            font-weight: bold;
            font-size: 12px;
            border-radius: 10px;
            border: none;
    
        }
    }
}
