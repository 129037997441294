.Table__container{
    margin-bottom: 30px;
    width: 100%;
    padding: 20px;
    min-height: 245px;
    border-radius: 10px;
    box-shadow: 1px 1px 15px rgba(0,0,0,0.1);

    

    
    .Table__table{
        width: 100%;
        

        .Table__file{
            width: 40px;
            height: 40px;
            border-radius: 5px;
            fill: #ffffff;
            stroke-width: 1;
            border: 1px solid #d6d2d2;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 17px;
                height: 19px;
            }
        }
        button {
            width: 100px;
            height: 40px;
            border-radius: 10px;
            background-color: #036402 ;
            background-size: cover;
            font-family: Poppins;
            font-size: 12px;
            color: #f8f8fc;
            text-decoration: none solid rgb(248, 248, 252);
            text-align: center;
            border: none;
            transition: background-color .3s ease;
            &:hover{
            background-color: #2f9c2d ;
                
    
            }

        }
        
        .p-datatable-thead tr{
            z-index: 100;
            height: 50px;
            border-radius: 10px 10px 0px 0px;      

            th{
                color: white;
                border-radius: 10px 10px 0px 0px;

                font-family: Poppins;
                font-size: 12px;
                color: #ffffff;
                text-decoration: none solid rgb(255, 255, 255);
                text-align: center;
                
            }
            .p-align-center{
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }
    .p-datatable-tbody tr:first-child button{
        background: #fa0401 !important;
        transition: background-color .3s ease;

        &:hover{
            background: rgb(218, 81, 76) !important;

        }

    }
    .p-datatable-tbody tr:nth-child(2n+1) {
        background: #ffffff !important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        border: none !important;
    }

    // color de la tabla y el titulo en red
    .Table__title-red{
        color: #fa0401;
        font-size: 18px;
        font-weight: bolder;
    }
    .Table__body-red{
        .p-datatable-thead tr{
            background-color: #fa0401 !important;
            th {
                background-color: #fa0401 !important;
            }
        }
    }

    // color de la tabla y el titulo en green
    .Table__title-green{
        color: #036402;
        font-size: 18px;
        font-weight: bolder;
    }
    .Table__body-green{
        .p-datatable-thead tr{
            background-color: #036402 !important;
            th {
                background-color: #036402 !important;
            }
        }
    }

    // color de la tabla y el titulo en blue
    .Table__title-blue{
        color: #0271c1;
        font-size: 18px;
        font-weight: bolder;
    }
    .Table__body-blue{
        .p-datatable-thead tr{
            background-color: #0271c1 !important;
            th {
                background-color: #0271c1 !important;
            }
        }
    }

    // color de la tabla y el titulo en yellow
    .Table__title-yellow{
        color: #f8a100;
        font-size: 18px;
        font-weight: bolder;
    }
    .Table__body-yellow{
        .p-datatable-thead tr{
            background-color: #f8a100 !important;
            th {
                background-color: #f8a100 !important;
            }
        }
    }
}