.reports {
  .reports-select {
    @media (max-width: 992px) {
      gap: 20px;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 50px;

    .selects {
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
      }

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
    }
    .btnPhone {
      @media (max-width: 768px) {
        justify-content: end;
      }
      @media (max-width: 480px) {
        justify-content: center;
      }
    }
    .btn-reports {
      margin: 0;
      width: 100px;
      height: 40px;
      border-radius: 10px;
      background-size: cover;
      font-family: Poppins;
      font-size: 12px;
      color: #f8f8fc;
      text-decoration: none solid rgb(248, 248, 252);
      text-align: center;
    }
  }
  .reportsContainer {
    margin: 30px 0;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
  }
}
