

.statusSection{
    display:flex;
    width:175px;

}

.statusSelected{
    background-color: #ff2631!important;
    color:#ffffff!important;
}

.green-text
{
    font-weight: bold;
    color:#036402 ;
    font-size: 12px;
    margin-top: 31px;
    margin-bottom: 31px;
}
.btnLeftStatus{
    width: 88px;
    height: 35px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #e3e3e3;
    background-size: cover;
    font-family: Poppins;
    font-size: 12px;
    color: #969696;
    font-weight: bold;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    border:none;
    outline:none!important;
}

.fakeCheck{
    width: 19px;
    height: 20px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.15), inset 1px 1px 5px rgba(0,0,0,0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    img{
        position: absolute;
        width: 19px;
    }
    .input-label {
        padding: 15px 0 0 150px;
    }

}

.div-buttons-createplan2{
    text-align: right;
    margin-top: 80px;
    margin-bottom: 20px;
    display:flex;
    justify-content: flex-start;
}

.btnRightStatus{
    width: 87px;
    height: 35px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #e3e3e3;
    background-size: cover;
    font-size: 12px;
    color: #969696;
    text-decoration: none solid rgb(150, 150, 150);
    text-align: center;
    border:none;
    font-weight: bold;
    outline:none!important;


}
