.login__login-box{
	max-width: 600px;
	height: 584px;
	border-radius: 15px;
	fill: #ffffff;
	box-shadow: 1px 1px 15px rgba(0,0,0,0.1);
	padding: 31px 28px;
	padding-top: 18px;
	text-align: center;
	margin: auto;
    margin-top: calc((100vh - 684px)/2);
}
.login__imgInter{
	width: 200px;
	height: 156px;
	object-fit: cover;
}

.msg-error{
	
    font-size:12px!important;
    color:red!important;
}


.login__title-log{
	width: 100%;
	height: 38px;
	font-family: Poppins;
    font-weight: 700;
	font-size: 20px;
	color: #000000;
	text-decoration: none solid rgb(0, 0, 0);
    margin: auto;
    font-weight: 700;
    margin-bottom: 24px;
}
.login__label{
	width: 100%;
	height: 18px;
	font-family: Poppins;
	color: #000000;
	text-decoration: none solid rgb(0, 0, 0);
	text-align: initial;
	margin-bottom: 10px;
	font-weight: 500;
}
.login__input-login{
	width: 100%;
	height: 40px;
	padding: 0px 8px 0px 20px;
	border: 1px solid #e3e3e3;
	border-radius: 10px;
	background-color: #ffffff;
	background-size: cover;
	font-family: Poppins;
	font-size: 12px;
	color: #000000;
	text-decoration: none solid rgb(0, 0, 0);
	margin-bottom: 10px;
}
.login__forget{
	text-align: right;
	width: 100%;
	height: 18px;
	font-family: Poppins;
	font-size: 10px;
	color: #ff2631;
	text-decoration: none solid rgb(255, 38, 49);
    font-weight: bold;

}
.login__btn-log{
	width: 100%;
	height: 40px;
	border-radius: 10px;
	background-color: #036402;
	background-size: cover;
	font-family: Poppins;
	font-size: 14px;
	color: #ffffff;
	text-decoration: none solid rgb(255, 255, 255);
	text-align: center;
	border: 0;
	transition: background-color .3s ease;
	&:hover {
        background-color: #088b06;
    }
}

.input-error {
	border: 1px solid #ff2631;
	color: #ff2631 ;
	text-decoration: none solid rgb(0, 0, 0);
}
.login__imgbottom{
	width: 225px;
	height: 53px;
	object-fit: cover;
	margin-top: 30px;
}
.login__remember {
	max-width: 98px;
	height: 20px;
	font-family: Poppins;
	font-size: 10px;
	color: #000000;
	text-decoration: none solid rgb(0, 0, 0);
	text-align: right;
}
.m-a{
    margin: auto;
}
.d-flex{
	display: flex;
}
.px-18px{
	padding: 0 18px;
	align-items: center;
}
 .text-right {
	text-align: right;
 }

 @media(min-width: 500px) {
	
	.login__remember {
		font-size: 12px;
	}
	.login__forget{
		font-size: 12px;
	}
	.login__login-box{
		padding: 31px 31px;
	}

 }

.RP__btn{
	width: 100%;
	height: 40px;
	border-radius: 10px;
	font-size: 14px;
	color: #ffffff;
	text-decoration: none solid rgb(255, 255, 255);
	text-align: center;
    transition: background-color .2s ease;
	border: 0;
}

.RP__btn-cancel {
    margin-right: 11px;
    background-color: #308ac9;
    

    &:hover {
        background-color: #549fd4;
    }
}

.RP__btn-submit {
    margin-left: 11px;
	background-color: #036402;

    &:hover {
        background-color: #088b06;
    }

}

.mb-5 {
    margin-bottom: 15px;
}

.text {
    font-weight: 600;
}
.bold {
    font-weight: bolder;
}