@import '../../../../sass/variables.scss';

.div-downloads-dashboard{
    margin-bottom: 15px;
    text-align: right;
}
.text-download-dashboard{
    font-size: 12px;
    font-family: Poppins;
    margin-right: 15px;
}
.icon-word-dashboard{
    color: $blueLight;
    cursor: pointer;
    margin-left: 10px;
    border: 1px solid #eee;
    font-size: 28px;
    padding: 3px;
    border-radius: 2px;
}
.icon-excel-dashboard{
    color: $green;
    cursor: pointer;
    margin-left: 10px;
    border: 1px solid #eee;
    font-size: 28px;
    padding: 3px;
    border-radius: 2px;
}

.table-dashboard th {
    background-color: $blueLight !important;
    padding: 1px 0px !important;
    border: 1px solid #e9ecef !important;
    border-width: 0 0 1px 0 !important;
    font-weight: 600 !important;
    color: #fff !important;
    transition: box-shadow 0.2s;
    font-size: 12px !important;
    text-align: center !important;
    font-family: Poppins;
    height: 55px !important;
}

.table-dashboard  th:first-child {
    width: 120px !important;
    border-top-left-radius: 10px !important
}

.table-dashboard  th:last-child {
    border-top-right-radius: 10px !important;
    //font-size: 10px !important;
    width: 110px !important;
}

.table-dashboard th:nth-child(2) {
    width: 150px !important;
}

.table-dashboard th:nth-child(3) {
    width: 50px !important;
}

.table-dashboard th:nth-child(4) {
    width: 120px !important;
}

.table-dashboard th:nth-child(9) {
    width: 120px !important;
}

.table-dashboard td {
    text-align: center !important;
    border: 1px solid #e9ecef;
    padding: 10px 0px !important;
    outline-color: #a6d5fa;
    font-size: 12px !important;
    font-family: Poppins !important;
    border-width: initial !important;
}
.table-dashboard .p-sortable-column:focus{
    box-shadow: inherit !important;
}

.table-dashboard .pi-sort-alt:before{
    font-family: "FontAwesome";
    content: "\f0dd" !important;
    font-size: 16px !important;
    background: white !important;
    padding: 0px 5px 2px 5px !important;
    color: $blueLight !important;
    border-radius: 4px;
}
.table-dashboard .pi-sort-amount-up-alt:before{
    font-family: "FontAwesome";
    content: "\f0de" !important;
    font-size: 16px !important;
    background: white !important;
    padding: 2px 5px 0px 5px !important;
    color: $blueLight !important;
    border-radius: 4px;
}

.table-dashboard .pi-sort-amount-down:before {
    font-family: "FontAwesome";
    content: "\f0dd" !important;
    font-size: 16px !important;
    background: white !important;
    padding: 0px 5px 2px 5px !important;
    color: $blueLight;
    border-radius: 4px;
}

.table-dashboard .pi-angle-double-left{
    display: none !important;
}
.table-dashboard .p-paginator-prev{
    border-radius: 28px !important;
    background-color: $green !important;
    background-size: cover !important;
    color: #ffffff !important;
    text-decoration: none solid rgb(255, 255, 255) !important;
    text-align: center !important;
    padding: 10px 20px !important;
}
.table-dashboard .pi-angle-left{
    font-family: Poppins !important;
    font-size: 14px !important;
}
.table-dashboard .pi-angle-left:before{
    content: "Anterior";
}

.table-dashboard .p-paginator-last{
    display: none !important;
}
.table-dashboard .p-paginator-next{
    border-radius: 28px !important;
    background-color: $green !important;
    background-size: cover !important;
    color: #ffffff !important;
    text-decoration: none solid rgb(255, 255, 255) !important;
    text-align: center !important;
    padding: 10px 20px !important;
}
.table-dashboard .pi-angle-right{
    font-family: Poppins !important;
    font-size: 14px !important;
}
.table-dashboard .pi-angle-right:before{
    content: "Siguiente";
}

.table-dashboard .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-radius: 40px !important;
    background-color: $green !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    color: #ffffff !important;
    text-align: center !important;
    margin: 15px !important;
}

.table-dashboard .p-paginator .p-paginator-pages .p-paginator-page{
    margin: 0px !important;
    background-color: #ffffff !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    color: #000000 !important;
    text-decoration: none solid rgb(0, 0, 0) !important;
    text-align: center !important;
}

.table-dashboard .p-paginator{
    display: block !important;
    align-items: initial !important;
    justify-content: initial !important;
    flex-wrap: initial !important;
    text-align: right !important;
    padding-right: 0px !important;
    padding-top: 18px !important;
}

.checkbox-table-dashboard + label:before {
    content: url("http://localhost:8085/gesticlaro/public/images/dashboard/checkbox.svg");
    //content: url('./img/checkbox.svg');
    position: absolute;
    z-index: 100;
  }
  .checkbox-table-dashboard:checked+label:before {
    //content: url('./img/checkbox.svg');
    content: url("http://localhost:8085/gesticlaro/public/images/dashboard/checkbox.svg");
  }
  .checkbox-table-dashboard{
    display: none;
  }