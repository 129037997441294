.card {
    width: 304px;
    height: 250px;
    border-radius: 15px;
    fill: #ffffff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    font-family: Poppins;
}
.frist__card__one {
    border-bottom: 30px solid #f5a72a;
}
.card-title__one {
    color: #f5a72a;
    position: absolute;
    margin-left: 20px;
    top: 45%;
    font-family: "Poppins";
    font-size: 18px;
}
.card-image {
    position: absolute;
    right: 0;
    top: 50%;
    margin-right: 30px;
}
.second__card {
    border-bottom: 30px solid #ff2631;
}

.three__card {
    border-bottom: 30px solid #036402;
}
.card-number {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 30px;
    position: absolute;
    top: 45%;
    margin-left: 20px;
}
.card-title__two {
    text-align: center;
    margin-top: 15px;
    color: #ff2631;
    font-family: "Poppins";
    font-size: 18px;
}
.card-title__three {
    color: #036402;
    font-family: "Poppins";
    font-size: 18px;
    margin-top: 15px;
    margin-left: 15px;
}
.card__ultimate {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-top: 20px;
}
.card__ultimate p {
    font-family: "Poppins";
    font-size: 12px;
    margin-right: 30px;
}
.card__ultimate span {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: bold;
}
