

.containerActivationTable{
    width:100%;
    margin-top:31px;
    //height: 348px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
    padding-top: 28px;
    padding-left:21px;
    padding-bottom: 28px;
    padding-right: 21px;

}
