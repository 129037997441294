@import "../../../../../../sass/main.scss";

.parameters {
  @media (max-width: 768px) {
    height: 100%;
  }
  min-height: calc(100vh - 40px);
  height: 100%;
}
.parameters-title {
  font-family: Poppins;
  font-size: 20px;
  color: #000000;
  padding-bottom: 20px;
  text-decoration: none solid rgb(0, 0, 0);
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #a80038 0%, #ff2631 100%);
  border-image-slice: 1;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;

  .parameters-title-span {
    font-family: Poppins;
    font-size: 15px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
  }
}
.parameters-container {
  width: 100%;
  max-width: 4000px;
  border-radius: 10px;
  fill: #ffffff;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}
.parameters-container-header {
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .parameters-container-header-search label {
    font-family: Poppins;
    font-size: 14px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    font-weight: 600;
  }
}
.parameters-container-header-search {
  display: flex;
  align-items: center;
  gap: 10px;
}
.parameters-container-header-search label {
  margin: auto 0;
}
.parameters-container-header-search input {
  width: 190px;
  height: 40px;
  padding: 0px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.parameters-container-header-button {
  display: flex;
  justify-content: center;
}
.parameters-container-header-button button {
  @media (max-width: 768px) {
    width: -webkit-fill-available;
  }

  margin: 0;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  background-color: #036402;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #f8f8fc;
  text-decoration: none solid rgb(248, 248, 252);
  text-align: center;
}
.parameters-footer {
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.parameters-footer-download {
  display: flex;
}
.parameters-footer-download label {
  margin: auto 0;
  font-family: Poppins;
  font-size: 12px;
  color: #4a4f54;
  text-decoration: none solid rgb(74, 79, 84);
}
.parameters-footer-download-button {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  fill: #ffffff;
  stroke-width: 1;
}
.parameters-footer-pag {
  display: flex;
  margin-bottom: 10px;
}
.parameters-footer-pag-text {
  margin: auto 0;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 14px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: center;
}
.parameters-footer-pag-button {
  width: 100px;
  height: 30px;
  border-radius: 28px;
  background-color: #036402;
  background-size: cover;
  font-family: Poppins;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
}
.parameters-table {
  max-width: 5000px;
  font-size: 12px;
}

.left-elements .p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
  padding: 10px !important;
  font-size: 12px !important;
}
.p-datatable .p-datatable-thead > tr > th {
  background: #308ac9 !important;
  padding: 10px !important;
  color: white !important;
  font-size: 12px !important;
}
.modalButton {
  position: fixed;
  top: 30%;
  left: 35%;
  width: 400px;
  height: 200px;
  background-color: #9e0e0e;
  text-align: center;
  padding-top: 30px;
}
.modalNewReason .ant-modal-content {
  @media (max-width: 768px) {
    margin: 0 auto;
    max-width: 400px !important;
    width: 95% !important;
  }
}
.parameters-table th:first-child {
  border-top-left-radius: 10px !important;
}
.parameters-table th:last-child {
  border-top-right-radius: 10px !important;
}
.ant-modal-close {
  width: 50px !important;
  margin: 0;
  background-color: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
.ant-modal-close span {
  display: block;
  margin-top: 12px;
  margin-right: 5px;
}
.eliminarParameters {
  position: fixed;
  bottom: 15px;
  left: 0px;
  background-color: rgb(250, 101, 109);
  padding: 1rem 2rem;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.btnModalAgregar {
  background-color: #036402;
}
.modalAdd {
  background-color: #036402;
}
.selectModal {
  width: 25rem;
  height: 3rem;
  border: 3px solid;
  padding-left: 1rem;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to left, #3ad58d, #003619);
}
.ModalCancel {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  background-color: #e3e3e3;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: center;
}
.textModal {
  width: 233px;
  height: 38px;
  font-family: Poppins;
  font-size: 20px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  font-weight: 700;
}
.modalNewReason .modal-container input {
  @media (max-width: 768px) {
    width: 230px;
  }
  width: 300px;
  height: 40px;
  padding: 0px 8px 0px 20px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  background-color: #ffffff;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.swal2-container .swal2-styled:not(.swal2-deny) {
  order: 2;
  width: 196px;
  height: 40px;
  border-radius: 10px;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: center;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  background-color: #308ac9 !important;
}
.swal2-container .swal2-styled:not(.swal2-confirm) {
  order: 1;
  width: 196px;
  height: 40px;
  border-radius: 10px;
  background-color: #ffffff !important;
  background-size: cover;
  font-family: Poppins;
  font-size: 12px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  text-align: center;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
  font-weight: 700;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark{
  font-size: none !important;
}
.swal2-container .swal2-styled:not(.swal2-cancel):focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 51, 153, 0);
}

