//Authentication

.guest {
    > div:first-child {
        display: none  !important;
    }

    .main-content-wrap {
        width: 100% !important;
    }

    .main-header {
        display: none  !important;
    }

    .main-content {
      position: initial;
        min-height: calc(100vh)  !important;
    }

}
.position-fixed
{
  position: fixed;
}
.p-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
  }
  @media (max-width:575px)
  {
    .p-grid {
      flex-direction: column;
      justify-content: center;
      
    }

     
  }
  
  .p-grid > .p-col,
  .p-grid > [class*=p-col] {
    box-sizing: border-box;
  }
  
  .p-nogutter {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
  }
  
  .p-nogutter > .p-col,
  .p-nogutter > [class*=p-col-] {
    padding: 0;
  }
  
  .p-col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  
  .p-col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  
  .p-col-1,
  .p-col-2,
  .p-col-3,
  .p-col-4,
  .p-col-5,
  .p-col-6,
  .p-col-7,
  .p-col-8,
  .p-col-9,
  .p-col-10,
  .p-col-11,
  .p-col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  
  .p-col-1 {
    width: 8.3333%;
  }
  
  .p-col-2 {
    width: 16.6667%;
  }
  
  .p-col-3 {
    width: 25%;
  }
  
  .p-col-4 {
    width: 33.3333%;
  }
  
  .p-col-5 {
    width: 41.6667%;
  }
  
  .p-col-6 {
    width: 50%;
  }
  
  .p-col-7 {
    width: 58.3333%;
  }
  
  .p-col-8 {
    width: 66.6667%;
  }
  
  .p-col-9 {
    width: 75%;
  }
  
  .p-col-10 {
    width: 83.3333%;
  }
  
  .p-col-11 {
    width: 91.6667%;
  }
  
  .p-col-12 {
    width: 100%;
  }
  
  .p-offset-12 {
    margin-left: 100%;
  }
  
  .p-offset-11 {
    margin-left: 91.66666667%;
  }
  
  .p-offset-10 {
    margin-left: 83.33333333%;
  }
  
  .p-offset-9 {
    margin-left: 75%;
  }
  
  .p-offset-8 {
    margin-left: 66.66666667%;
  }
  
  .p-offset-7 {
    margin-left: 58.33333333%;
  }
  
  .p-offset-6 {
    margin-left: 50%;
  }
  
  .p-offset-5 {
    margin-left: 41.66666667%;
  }
  
  .p-offset-4 {
    margin-left: 33.33333333%;
  }
  
  .p-offset-3 {
    margin-left: 25%;
  }
  
  .p-offset-2 {
    margin-left: 16.66666667%;
  }
  
  .p-offset-1 {
    margin-left: 8.33333333%;
  }
  
  .p-offset-0 {
    margin-left: 0%;
  }
  
  .p-sm-1,
  .p-sm-2,
  .p-sm-3,
  .p-sm-4,
  .p-sm-5,
  .p-sm-6,
  .p-sm-7,
  .p-sm-8,
  .p-sm-9,
  .p-sm-10,
  .p-sm-11,
  .p-sm-12,
  .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-12,
  .p-lg-1,
  .p-lg-2,
  .p-lg-3,
  .p-lg-3-5-col,
  .p-lg-4,
  .p-lg-5,
  .p-lg-6,
  .p-lg-7,
  .p-lg-8,
  .p-lg-9,
  .p-lg-10,
  .p-lg-11,
  .p-lg-12,
  .p-xl-1,
  .p-xl-2,
  .p-xl-3,
  .p-xl-4,
  .p-xl-5,
  .p-xl-6,
  .p-xl-7,
  .p-xl-8,
  .p-xl-9,
  .p-xl-10,
  .p-xl-11,
  .p-xl-12 {
    padding: 0.5rem;
  }
  
  .p-col-nogutter {
    padding: 0;
  }
  
  @media screen and (min-width: 300px) {
    .p-sm-1,
  .p-sm-2,
  .p-sm-3,
  .p-sm-4,
  .p-sm-5,
  .p-sm-6,
  .p-sm-7,
  .p-sm-8,
  .p-sm-9,
  .p-sm-10,
  .p-sm-11,
  .p-sm-12 {
      flex: 0 0 auto;
    }
  
    .p-sm-1 {
      width: 8.3333%;
    }
  
    .p-sm-2 {
      width: 16.6667%;
    }
  
    .p-sm-3 {
      width: 25%;
    }
  
    .p-sm-4 {
      width: 33.3333%;
    }
  
    .p-sm-5 {
      width: 41.6667%;
    }
  
    .p-sm-6 {
      width: 50%;
    }
  
    .p-sm-7 {
      width: 58.3333%;
    }
  
    .p-sm-8 {
      width: 66.6667%;
    }
  
    .p-sm-9 {
      width: 75%;
    }
  
    .p-sm-10 {
      width: 83.3333%;
    }
  
    .p-sm-11 {
      width: 91.6667%;
    }
  
    .p-sm-12 {
      width: 100%;
    }
  
    .p-sm-offset-12 {
      margin-left: 100%;
    }
  
    .p-sm-offset-11 {
      margin-left: 91.66666667%;
    }
  
    .p-sm-offset-10 {
      margin-left: 83.33333333%;
    }
  
    .p-sm-offset-9 {
      margin-left: 75%;
    }
  
    .p-sm-offset-8 {
      margin-left: 66.66666667%;
    }
  
    .p-sm-offset-7 {
      margin-left: 58.33333333%;
    }
  
    .p-sm-offset-6 {
      margin-left: 50%;
    }
  
    .p-sm-offset-5 {
      margin-left: 41.66666667%;
    }
  
    .p-sm-offset-4 {
      margin-left: 33.33333333%;
    }
  
    .p-sm-offset-3 {
      margin-left: 25%;
    }
  
    .p-sm-offset-2 {
      margin-left: 16.66666667%;
    }
  
    .p-sm-offset-1 {
      margin-left: 8.33333333%;
    }
  
    .p-sm-offset-0 {
      margin-left: 0%;
    }
  }
  @media screen and (min-width: 768px) {
    .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-12 {
      flex: 0 0 auto;
    }
  
    .p-md-1 {
      width: 8.3333%;
    }
  
    .p-md-2 {
      width: 16.6667%;
    }
  
    .p-md-3 {
      width: 25%;
    }
  
    .p-md-4 {
      width: 33.3333%;
    }
  
    .p-md-5 {
      width: 41.6667%;
    }
  
    .p-md-6 {
      width: 50%;
    }
  
    .p-md-7 {
      width: 58.3333%;
    }
  
    .p-md-8 {
      width: 66.6667%;
    }
  
    .p-md-9 {
      width: 75%;
    }
  
    .p-md-10 {
      width: 83.3333%;
    }
  
    .p-md-11 {
      width: 91.6667%;
    }
  
    .p-md-12 {
      width: 100%;
    }
  
    .p-md-offset-12 {
      margin-left: 100%;
    }
  
    .p-md-offset-11 {
      margin-left: 91.66666667%;
    }
  
    .p-md-offset-10 {
      margin-left: 83.33333333%;
    }
  
    .p-md-offset-9 {
      margin-left: 75%;
    }
  
    .p-md-offset-8 {
      margin-left: 66.66666667%;
    }
  
    .p-md-offset-7 {
      margin-left: 58.33333333%;
    }
  
    .p-md-offset-6 {
      margin-left: 50%;
    }
  
    .p-md-offset-5 {
      margin-left: 41.66666667%;
    }
  
    .p-md-offset-4 {
      margin-left: 33.33333333%;
    }
  
    .p-md-offset-3 {
      margin-left: 25%;
    }
  
    .p-md-offset-2 {
      margin-left: 16.66666667%;
    }
  
    .p-md-offset-1 {
      margin-left: 8.33333333%;
    }
  
    .p-md-offset-0 {
      margin-left: 0%;
    }
  }
  @media screen and (min-width: 992px) {
    .p-lg-1,
  .p-lg-2,
  .p-lg-3,
  .p-lg-4,
  .p-lg-5,
  .p-lg-6,
  .p-lg-7,
  .p-lg-8,
  .p-lg-9,
  .p-lg-10,
  .p-lg-11,
  .p-lg-12 {
      flex: 0 0 auto;
    }
    
  
    .p-lg-1 {
      width: 8.3333%;
    }
  
    .p-lg-2 {
      width: 16.6667%;
    }
  
    .p-lg-3 {
      width: 25%;
    }
    .p-lg-3-5-col
    {
      width: 20%;
    }
    @media screen and (max-width: 1548px) 
      {
        .p-lg-3-5-col
        {
          width: 25%;
        }
      }
      @media screen and (max-width: 1320px) 
      {
        .p-lg-3-5-col
        {
          width: 33%;
        }
      }
      @media screen and (max-width: 1080px) 
      {
        .p-lg-3-5-col
        {
          width: 50%;
        }
      }
      
  
    .p-lg-4 {
      width: 33.3333%;
    }
  
    .p-lg-5 {
      width: 41.6667%;
    }
  
    .p-lg-6 {
      width: 50%;
    }
  
    .p-lg-7 {
      width: 58.3333%;
    }
  
    .p-lg-8 {
      width: 66.6667%;
    }
  
    .p-lg-9 {
      width: 75%;
    }
  
    .p-lg-10 {
      width: 83.3333%;
    }
  
    .p-lg-11 {
      width: 91.6667%;
    }
  
    .p-lg-12 {
      width: 100%;
    }
  
    .p-lg-offset-12 {
      margin-left: 100%;
    }
  
    .p-lg-offset-11 {
      margin-left: 91.66666667%;
    }
  
    .p-lg-offset-10 {
      margin-left: 83.33333333%;
    }
  
    .p-lg-offset-9 {
      margin-left: 75%;
    }
  
    .p-lg-offset-8 {
      margin-left: 66.66666667%;
    }
  
    .p-lg-offset-7 {
      margin-left: 58.33333333%;
    }
  
    .p-lg-offset-6 {
      margin-left: 50%;
    }
  
    .p-lg-offset-5 {
      margin-left: 41.66666667%;
    }
  
    .p-lg-offset-4 {
      margin-left: 33.33333333%;
    }
  
    .p-lg-offset-3 {
      margin-left: 25%;
    }
  
    .p-lg-offset-2 {
      margin-left: 16.66666667%;
    }
  
    .p-lg-offset-1 {
      margin-left: 8.33333333%;
    }
  
    .p-lg-offset-0 {
      margin-left: 0%;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-xl-1,
  .p-xl-2,
  .p-xl-3,
  .p-xl-4,
  .p-xl-5,
  .p-xl-6,
  .p-xl-7,
  .p-xl-8,
  .p-xl-9,
  .p-xl-10,
  .p-xl-11,
  .p-xl-12 {
      flex: 0 0 auto;
    }
  
    .p-xl-1 {
      width: 8.3333%;
    }
  
    .p-xl-2 {
      width: 16.6667%;
    }
  
    .p-xl-3 {
      width: 25%;
    }
  
    .p-xl-4 {
      width: 33.3333%;
    }
  
    .p-xl-5 {
      width: 41.6667%;
    }
  
    .p-xl-6 {
      width: 50%;
    }
  
    .p-xl-7 {
      width: 58.3333%;
    }
  
    .p-xl-8 {
      width: 66.6667%;
    }
  
    .p-xl-9 {
      width: 75%;
    }
  
    .p-xl-10 {
      width: 83.3333%;
    }
  
    .p-xl-11 {
      width: 91.6667%;
    }
  
    .p-xl-12 {
      width: 100%;
    }
  
    .p-xl-offset-12 {
      margin-left: 100%;
    }
  
    .p-xl-offset-11 {
      margin-left: 91.66666667%;
    }
  
    .p-xl-offset-10 {
      margin-left: 83.33333333%;
    }
  
    .p-xl-offset-9 {
      margin-left: 75%;
    }
  
    .p-xl-offset-8 {
      margin-left: 66.66666667%;
    }
  
    .p-xl-offset-7 {
      margin-left: 58.33333333%;
    }
  
    .p-xl-offset-6 {
      margin-left: 50%;
    }
  
    .p-xl-offset-5 {
      margin-left: 41.66666667%;
    }
  
    .p-xl-offset-4 {
      margin-left: 33.33333333%;
    }
  
    .p-xl-offset-3 {
      margin-left: 25%;
    }
  
    .p-xl-offset-2 {
      margin-left: 16.66666667%;
    }
  
    .p-xl-offset-1 {
      margin-left: 8.33333333%;
    }
  
    .p-xl-offset-0 {
      margin-left: 0%;
    }
  }
  .p-justify-start {
    justify-content: flex-start;
  }
  
  .p-justify-end {
    justify-content: flex-end;
  }
  
  .p-justify-center {
    justify-content: center;
  }
  
  .p-justify-between {
    justify-content: space-between;
  }
  
  .p-justify-around {
    justify-content: space-around;
  }
  
  .p-justify-even {
    justify-content: space-evenly;
  }
  
  .p-align-start {
    align-items: flex-start;
  }
  
  .p-align-end {
    align-items: flex-end;
  }
  
  .p-align-center {
    align-items: center;
  }
  
  .p-align-baseline {
    align-items: baseline;
  }
  
  .p-align-stretch {
    align-items: stretch;
  }
  
  .p-col-align-start {
    align-self: flex-start;
  }
  
  .p-col-align-end {
    align-self: flex-end;
  }
  
  .p-col-align-center {
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .p-col-align-baseline {
    align-self: baseline;
  }
  
  .p-col-align-stretch {
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  
  .p-dir-row {
    flex-direction: row;
  }
  
  .p-dir-rev {
    flex-direction: row-reverse;
  }
  
  .p-dir-col {
    flex-direction: column;
  }
  
  .p-dir-col-rev {
    flex-direction: column-reverse;
  }
  
  .p-dir-col > .p-col,
  .p-dir-col-rev > .p-col {
    flex-basis: auto;
  }
  
  .p-col-order-first {
    order: -1;
  }
  
  .p-col-order-last {
    order: 13;
  }
  
  .p-col-order-0 {
    order: 0;
  }
  
  .p-col-order-1 {
    order: 1;
  }
  
  .p-col-order-2 {
    order: 2;
  }
  
  .p-col-order-3 {
    order: 3;
  }
  
  .p-col-order-4 {
    order: 4;
  }
  
  .p-col-order-5 {
    order: 5;
  }
  
  .p-col-order-6 {
    order: 6;
  }
  
  .p-col-order-7 {
    order: 7;
  }
  
  .p-col-order-8 {
    order: 8;
  }
  
  .p-col-order-9 {
    order: 9;
  }
  
  .p-col-order-10 {
    order: 10;
  }
  
  .p-col-order-11 {
    order: 11;
  }
  
  .p-col-order-12 {
    order: 12;
  }
  
  @media screen and (min-width: 576px) {
    .p-sm-order-first {
      order: -1;
    }
  
    .p-sm-order-last {
      order: 13;
    }
  
    .p-sm-order-0 {
      order: 0;
    }
  
    .p-sm-order-1 {
      order: 1;
    }
  
    .p-sm-order-2 {
      order: 2;
    }
  
    .p-sm-order-3 {
      order: 3;
    }
  
    .p-sm-order-4 {
      order: 4;
    }
  
    .p-sm-order-5 {
      order: 5;
    }
  
    .p-sm-order-6 {
      order: 6;
    }
  
    .p-sm-order-7 {
      order: 7;
    }
  
    .p-sm-order-8 {
      order: 8;
    }
  
    .p-sm-order-9 {
      order: 9;
    }
  
    .p-sm-order-10 {
      order: 10;
    }
  
    .p-sm-order-11 {
      order: 11;
    }
  
    .p-sm-order-12 {
      order: 12;
    }
  }
  @media screen and (min-width: 768px) {
    .p-md-order-first {
      order: -1;
    }
  
    .p-md-order-last {
      order: 13;
    }
  
    .p-md-order-0 {
      order: 0;
    }
  
    .p-md-order-1 {
      order: 1;
    }
  
    .p-md-order-2 {
      order: 2;
    }
  
    .p-md-order-3 {
      order: 3;
    }
  
    .p-md-order-4 {
      order: 4;
    }
  
    .p-md-order-5 {
      order: 5;
    }
  
    .p-md-order-6 {
      order: 6;
    }
  
    .p-md-order-7 {
      order: 7;
    }
  
    .p-md-order-8 {
      order: 8;
    }
  
    .p-md-order-9 {
      order: 9;
    }
  
    .p-md-order-10 {
      order: 10;
    }
  
    .p-md-order-11 {
      order: 11;
    }
  
    .p-md-order-12 {
      order: 12;
    }
  }
  @media screen and (min-width: 992px) {
    .p-lg-order-first {
      order: -1;
    }
  
    .p-lg-order-last {
      order: 13;
    }
  
    .p-lg-order-0 {
      order: 0;
    }
  
    .p-lg-order-1 {
      order: 1;
    }
  
    .p-lg-order-2 {
      order: 2;
    }
  
    .p-lg-order-3 {
      order: 3;
    }
  
    .p-lg-order-4 {
      order: 4;
    }
  
    .p-lg-order-5 {
      order: 5;
    }
  
    .p-lg-order-6 {
      order: 6;
    }
  
    .p-lg-order-7 {
      order: 7;
    }
  
    .p-lg-order-8 {
      order: 8;
    }
  
    .p-lg-order-9 {
      order: 9;
    }
  
    .p-lg-order-10 {
      order: 10;
    }
  
    .p-lg-order-11 {
      order: 11;
    }
  
    .p-lg-order-12 {
      order: 12;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-xl-order-first {
      order: -1;
    }
  
    .p-xl-order-last {
      order: 13;
    }
  
    .p-xl-order-0 {
      order: 0;
    }
  
    .p-xl-order-1 {
      order: 1;
    }
  
    .p-xl-order-2 {
      order: 2;
    }
  
    .p-xl-order-3 {
      order: 3;
    }
  
    .p-xl-order-4 {
      order: 4;
    }
  
    .p-xl-order-5 {
      order: 5;
    }
  
    .p-xl-order-6 {
      order: 6;
    }
  
    .p-xl-order-7 {
      order: 7;
    }
  
    .p-xl-order-8 {
      order: 8;
    }
  
    .p-xl-order-9 {
      order: 9;
    }
  
    .p-xl-order-10 {
      order: 10;
    }
  
    .p-xl-order-11 {
      order: 11;
    }
  
    .p-xl-order-12 {
      order: 12;
    }
  }
  .p-field {
    margin-bottom: 1rem;
  }
  
  .p-field > label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  
  .p-field.p-grid > label {
    display: flex;
    align-items: center;
  }
  
  .p-field > small {
    margin-top: 0.25rem;
  }
  
  .p-field.p-grid,
  .p-formgrid.p-grid {
    margin-top: 0;
  }
  
  .p-field.p-grid .p-col-fixed,
  .p-formgrid.p-grid .p-col-fixed,
  .p-field.p-grid .p-col,
  .p-formgrid.p-grid .p-col,
  .p-field.p-grid .p-col-1,
  .p-formgrid.p-grid .p-col-1,
  .p-field.p-grid .p-col-2,
  .p-formgrid.p-grid .p-col-2,
  .p-field.p-grid .p-col-3,
  .p-formgrid.p-grid .p-col-3,
  .p-field.p-grid .p-col-4,
  .p-formgrid.p-grid .p-col-4,
  .p-field.p-grid .p-col-5,
  .p-formgrid.p-grid .p-col-5,
  .p-field.p-grid .p-col-6,
  .p-formgrid.p-grid .p-col-6,
  .p-field.p-grid .p-col-7,
  .p-formgrid.p-grid .p-col-7,
  .p-field.p-grid .p-col-8,
  .p-formgrid.p-grid .p-col-8,
  .p-field.p-grid .p-col-9,
  .p-formgrid.p-grid .p-col-9,
  .p-field.p-grid .p-col-10,
  .p-formgrid.p-grid .p-col-10,
  .p-field.p-grid .p-col-11,
  .p-formgrid.p-grid .p-col-11,
  .p-field.p-grid .p-col-12,
  .p-formgrid.p-grid .p-col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .p-formgroup-inline {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  
  .p-formgroup-inline .p-field,
  .p-formgroup-inline .p-field-checkbox,
  .p-formgroup-inline .p-field-radiobutton {
    margin-right: 1rem;
  }
  .responsive-sidebar-cover
  {
    width: 100%;
    background: rgba(0, 0, 0, 0.45);
    
  }
  .p-formgroup-inline .p-field > label,
  .p-formgroup-inline .p-field-checkbox > label,
  .p-formgroup-inline .p-field-radiobutton > label {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  
  .p-field-checkbox,
  .p-field-radiobutton {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
  
  .p-field-checkbox > label,
  .p-field-radiobutton > label {
    margin-left: 0.5rem;
    line-height: 1;
  }
  
  .p-d-none {
    display: none !important;
  }
  
  .p-d-inline {
    display: inline !important;
  }
  
  .p-d-inline-block {
    display: inline-block !important;
  }
  
  .p-d-block {
    display: block !important;
  }
  
  .p-d-flex {
    display: flex !important;
  }
  
  .p-d-inline-flex {
    display: inline-flex !important;
  }
  
  @media screen and (min-width: 576px) {
    .p-d-sm-none {
      display: none !important;
    }
  
    .p-d-sm-inline {
      display: inline !important;
    }
  
    .p-d-sm-inline-block {
      display: inline-block !important;
    }
  
    .p-d-sm-block {
      display: block !important;
    }
  
    .p-d-sm-flex {
      display: flex !important;
    }
  
    .p-d-sm-inline-flex {
      display: inline-flex !important;
    }
  }
  @media screen and (min-width: 768px) {
    .p-d-md-none {
      display: none !important;
    }
  
    .p-d-md-inline {
      display: inline !important;
    }
  
    .p-d-md-inline-block {
      display: inline-block !important;
    }
  
    .p-d-md-block {
      display: block !important;
    }
  
    .p-d-md-flex {
      display: flex !important;
    }
  
    .p-d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  @media screen and (min-width: 992px) {
    .p-d-lg-none {
      display: none !important;
    }
  
    .p-d-lg-inline {
      display: inline !important;
    }
  
    .p-d-lg-inline-block {
      display: inline-block !important;
    }
  
    .p-d-lg-block {
      display: block !important;
    }
  
    .p-d-lg-flex {
      display: flex !important;
    }
  
    .p-d-lg-inline-flex {
      display: inline-flex !important;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-d-xl-none {
      display: none !important;
    }
  
    .p-d-xl-inline {
      display: inline !important;
    }
  
    .p-d-xl-inline-block {
      display: inline-block !important;
    }
  
    .p-d-xl-block {
      display: block !important;
    }
  
    .p-d-xl-flex {
      display: flex !important;
    }
  
    .p-d-xl-inline-flex {
      display: inline-flex !important;
    }
  }
  @media print {
    .p-d-print-none {
      display: none !important;
    }
  
    .p-d-print-inline {
      display: inline !important;
    }
  
    .p-d-print-inline-block {
      display: inline-block !important;
    }
  
    .p-d-print-block {
      display: block !important;
    }
  
    .p-d-print-flex {
      display: flex !important;
    }
  
    .p-d-print-inline-flex {
      display: inline-flex !important;
    }
  }
  .p-text-justify {
    text-align: justify !important;
  }
  
  .p-text-left {
    text-align: left !important;
  }
  
  .p-text-right {
    text-align: right !important;
  }
  
  .p-text-center {
    text-align: center !important;
  }
  
  .p-text-nowrap {
    white-space: nowrap !important;
  }
  
  .p-text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .p-text-lowercase {
    text-transform: lowercase !important;
  }
  
  .p-text-uppercase {
    text-transform: uppercase !important;
  }
  
  .p-text-capitalize {
    text-transform: capitalize !important;
  }
  
  .p-text-bold {
    font-weight: 700 !important;
  }
  
  .p-text-normal {
    font-weight: 400 !important;
  }
  
  .p-text-light {
    font-weight: 300 !important;
  }
  
  .p-text-italic {
    font-style: italic !important;
  }
  
  @media screen and (min-width: 576px) {
    .p-text-sm-justify {
      text-align: justify !important;
    }
  
    .p-text-sm-left {
      text-align: left !important;
    }
  
    .p-text-sm-right {
      text-align: right !important;
    }
  
    .p-text-sm-center {
      text-align: center !important;
    }
  }
  @media screen and (min-width: 768px) {
    .p-text-md-justify {
      text-align: justify !important;
    }
  
    .p-text-md-left {
      text-align: left !important;
    }
  
    .p-text-md-right {
      text-align: right !important;
    }
  
    .p-text-md-center {
      text-align: center !important;
    }
  }
  @media screen and (min-width: 992px) {
    .p-text-lg-justify {
      text-align: justify !important;
    }
  
    .p-text-lg-left {
      text-align: left !important;
    }
  
    .p-text-lg-right {
      text-align: right !important;
    }
  
    .p-text-lg-center {
      text-align: center !important;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-text-xl-justify {
      text-align: justify !important;
    }
  
    .p-text-xl-left {
      text-align: left !important;
    }
  
    .p-text-xl-right {
      text-align: right !important;
    }
  
    .p-text-xl-center {
      text-align: center !important;
    }
  }
  .p-flex-row {
    flex-direction: row !important;
  }
  
  .p-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  
  .p-flex-column {
    flex-direction: column !important;
  }
  
  .p-flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  
  @media screen and (min-width: 576px) {
    .p-flex-sm-row {
      flex-direction: row !important;
    }
  
    .p-flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
  
    .p-flex-sm-column {
      flex-direction: column !important;
    }
  
    .p-flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
  }
  @media screen and (min-width: 768px) {
    .p-flex-md-row {
      flex-direction: row !important;
    }
  
    .p-flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
  
    .p-flex-md-column {
      flex-direction: column !important;
    }
  
    .p-flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
  }
  @media screen and (min-width: 992px) {
    .p-flex-lg-row {
      flex-direction: row !important;
    }
  
    .p-flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
  
    .p-flex-lg-column {
      flex-direction: column !important;
    }
  
    .p-flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-flex-xl-row {
      flex-direction: row !important;
    }
  
    .p-flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
  
    .p-flex-xl-column {
      flex-direction: column !important;
    }
  
    .p-flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
  }
  .p-jc-start {
    justify-content: flex-start;
  }
  
  .p-jc-end {
    justify-content: flex-end;
  }
  
  .p-jc-center {
    justify-content: center;
  }
  
  .p-jc-between {
    justify-content: space-between;
  }
  
  .p-jc-around {
    justify-content: space-around;
  }
  
  .p-jc-evenly {
    justify-content: space-evenly;
  }
  
  @media screen and (min-width: 576px) {
    .p-jc-sm-start {
      justify-content: flex-start;
    }
  
    .p-jc-sm-end {
      justify-content: flex-end;
    }
  
    .p-jc-sm-center {
      justify-content: center;
    }
  
    .p-jc-sm-between {
      justify-content: space-between;
    }
  
    .p-jc-sm-around {
      justify-content: space-around;
    }
  
    .p-jc-sm-evenly {
      justify-content: space-evenly;
    }
  }
  @media screen and (min-width: 768px) {
    .p-jc-md-start {
      justify-content: flex-start;
    }
  
    .p-jc-md-end {
      justify-content: flex-end;
    }
  
    .p-jc-md-center {
      justify-content: center;
    }
  
    .p-jc-md-between {
      justify-content: space-between;
    }
  
    .p-jc-md-around {
      justify-content: space-around;
    }
  
    .p-jc-md-evenly {
      justify-content: space-evenly;
    }
  }
  @media screen and (min-width: 992px) {
    .p-jc-lg-start {
      justify-content: flex-start;
    }
  
    .p-jc-lg-end {
      justify-content: flex-end;
    }
  
    .p-jc-lg-center {
      justify-content: center;
    }
  
    .p-jc-lg-between {
      justify-content: space-between;
    }
  
    .p-jc-lg-around {
      justify-content: space-around;
    }
  
    .p-jc-lg-evenly {
      justify-content: space-evenly;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-jc-xl-start {
      justify-content: flex-start;
    }
  
    .p-jc-xl-end {
      justify-content: flex-end;
    }
  
    .p-jc-xl-center {
      justify-content: center;
    }
  
    .p-jc-xl-between {
      justify-content: space-between;
    }
  
    .p-jc-xl-around {
      justify-content: space-around;
    }
  
    .p-jc-xl-evenly {
      justify-content: space-evenly;
    }
  }
  .p-ai-start {
    align-items: flex-start;
  }
  
  .p-ai-end {
    align-items: flex-end;
  }
  
  .p-ai-center {
    align-items: center;
  }
  
  .p-ai-baseline {
    align-items: baseline;
  }
  
  .p-ai-stretch {
    align-items: stretch;
  }
  
  @media screen and (min-width: 576px) {
    .p-ai-sm-start {
      align-items: flex-start;
    }
  
    .p-ai-sm-end {
      align-items: flex-end;
    }
  
    .p-ai-sm-center {
      align-items: center;
    }
  
    .p-ai-sm-baseline {
      align-items: baseline;
    }
  
    .p-ai-sm-stretch {
      align-items: stretch;
    }
  }
  @media screen and (min-width: 768px) {
    .p-ai-md-start {
      align-items: flex-start;
    }
  
    .p-ai-md-end {
      align-items: flex-end;
    }
  
    .p-ai-md-center {
      align-items: center;
    }
  
    .p-ai-md-baseline {
      align-items: baseline;
    }
  
    .p-ai-md-stretch {
      align-items: stretch;
    }
  }
  @media screen and (min-width: 992px) {
    .p-ai-lg-start {
      align-items: flex-start;
    }
  
    .p-ai-lg-end {
      align-items: flex-end;
    }
  
    .p-ai-lg-center {
      align-items: center;
    }
  
    .p-ai-lg-baseline {
      align-items: baseline;
    }
  
    .p-ai-lg-stretch {
      align-items: stretch;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-ai-xl-start {
      align-items: flex-start;
    }
  
    .p-ai-xl-end {
      align-items: flex-end;
    }
  
    .p-ai-xl-center {
      align-items: center;
    }
  
    .p-ai-xl-baseline {
      align-items: baseline;
    }
  
    .p-ai-xl-stretch {
      align-items: stretch;
    }
  }
  .p-as-start {
    align-self: start;
  }
  
  .p-as-end {
    align-self: flex-end;
  }
  
  .p-as-center {
    align-self: center;
  }
  
  .p-as-baseline {
    align-self: baseline;
  }
  
  .p-as-stretch {
    align-self: stretch;
  }
  
  @media screen and (min-width: 576px) {
    .p-as-sm-start {
      align-self: start;
    }
  
    .p-as-sm-end {
      align-self: flex-end;
    }
  
    .p-as-sm-center {
      align-self: center;
    }
  
    .p-as-sm-baseline {
      align-self: baseline;
    }
  
    .p-as-sm-stretch {
      align-self: stretch;
    }
  }
  @media screen and (min-width: 768px) {
    .p-as-md-start {
      align-self: start;
    }
  
    .p-as-md-end {
      align-self: flex-end;
    }
  
    .p-as-md-center {
      align-self: center;
    }
  
    .p-as-md-baseline {
      align-self: baseline;
    }
  
    .p-as-md-stretch {
      align-self: stretch;
    }
  }
  @media screen and (min-width: 992px) {
    .p-as-lg-start {
      align-self: start;
    }
  
    .p-as-lg-end {
      align-self: flex-end;
    }
  
    .p-as-lg-center {
      align-self: center;
    }
  
    .p-as-lg-baseline {
      align-self: baseline;
    }
  
    .p-as-lg-stretch {
      align-self: stretch;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-as-xl-start {
      align-self: start;
    }
  
    .p-as-xl-end {
      align-self: flex-end;
    }
  
    .p-as-xl-center {
      align-self: center;
    }
  
    .p-as-xl-baseline {
      align-self: baseline;
    }
  
    .p-as-xl-stretch {
      align-self: stretch;
    }
  }
  .p-ac-start {
    align-content: flex-start;
  }
  
  .p-ac-end {
    align-content: flex-end;
  }
  
  .p-ac-center {
    align-content: center;
  }
  
  .p-ac-around {
    align-content: space-around;
  }
  
  .p-ac-stretch {
    align-content: stretch;
  }
  
  .p-ac-between {
    align-content: space-between;
  }
  
  @media screen and (min-width: 576px) {
    .p-ac-sm-start {
      align-content: flex-start;
    }
  
    .p-ac-sm-end {
      align-content: flex-end;
    }
  
    .p-ac-sm-center {
      align-content: center;
    }
  
    .p-ac-sm-around {
      align-content: space-around;
    }
  
    .p-ac-sm-stretch {
      align-content: stretch;
    }
  
    .p-ac-sm-between {
      align-content: space-between;
    }
  }
  @media screen and (min-width: 768px) {
    .p-ac-md-start {
      align-content: flex-start;
    }
  
    .p-ac-md-end {
      align-content: flex-end;
    }
  
    .p-ac-md-center {
      align-content: center;
    }
  
    .p-ac-md-around {
      align-content: space-around;
    }
  
    .p-ac-md-stretch {
      align-content: stretch;
    }
  
    .p-ac-md-between {
      align-content: space-between;
    }
  }
  @media screen and (min-width: 992px) {
    .p-ac-lg-start {
      align-content: flex-start;
    }
  
    .p-ac-lg-end {
      align-content: flex-end;
    }
  
    .p-ac-lg-center {
      align-content: center;
    }
  
    .p-ac-lg-around {
      align-content: space-around;
    }
  
    .p-ac-lg-stretch {
      align-content: stretch;
    }
  
    .p-ac-lg-between {
      align-content: space-between;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-ac-xl-start {
      align-content: flex-start;
    }
  
    .p-ac-xl-end {
      align-content: flex-end;
    }
  
    .p-ac-xl-center {
      align-content: center;
    }
  
    .p-ac-xl-around {
      align-content: space-around;
    }
  
    .p-ac-xl-stretch {
      align-content: stretch;
    }
  
    .p-ac-xl-between {
      align-content: space-between;
    }
  }
  .p-order-0 {
    order: 0;
  }
  
  .p-order-1 {
    order: 1;
  }
  
  .p-order-2 {
    order: 2;
  }
  
  .p-order-3 {
    order: 3;
  }
  
  .p-order-4 {
    order: 4;
  }
  
  .p-order-5 {
    order: 5;
  }
  
  .p-order-6 {
    order: 6;
  }
  
  @media screen and (min-width: 576px) {
    .p-order-sm-0 {
      order: 0;
    }
  
    .p-order-sm-1 {
      order: 1;
    }
  
    .p-order-sm-2 {
      order: 2;
    }
  
    .p-order-sm-3 {
      order: 3;
    }
  
    .p-order-sm-4 {
      order: 4;
    }
  
    .p-order-sm-5 {
      order: 5;
    }
  
    .p-order-sm-6 {
      order: 6;
    }
  }
  @media screen and (min-width: 768px) {
    .p-order-md-0 {
      order: 0;
    }
  
    .p-order-md-1 {
      order: 1;
    }
  
    .p-order-md-2 {
      order: 2;
    }
  
    .p-order-md-3 {
      order: 3;
    }
  
    .p-order-md-4 {
      order: 4;
    }
  
    .p-order-md-5 {
      order: 5;
    }
  
    .p-order-md-6 {
      order: 6;
    }
  }
  @media screen and (min-width: 992px) {
    .p-order-lg-0 {
      order: 0;
    }
  
    .p-order-lg-1 {
      order: 1;
    }
  
    .p-order-lg-2 {
      order: 2;
    }
  
    .p-order-lg-3 {
      order: 3;
    }
  
    .p-order-lg-4 {
      order: 4;
    }
  
    .p-order-lg-5 {
      order: 5;
    }
  
    .p-order-lg-6 {
      order: 6;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-order-xl-0 {
      order: 0;
    }
  
    .p-order-xl-1 {
      order: 1;
    }
  
    .p-order-xl-2 {
      order: 2;
    }
  
    .p-order-xl-3 {
      order: 3;
    }
  
    .p-order-xl-4 {
      order: 4;
    }
  
    .p-order-xl-5 {
      order: 5;
    }
  
    .p-order-xl-6 {
      order: 6;
    }
  }
  .p-flex-nowrap {
    flex-wrap: nowrap;
  }
  
  .p-flex-wrap {
    flex-wrap: wrap;
  }
  
  .p-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  
  @media screen and (min-width: 576px) {
    .p-flex-sm-nowrap {
      flex-wrap: nowrap;
    }
  
    .p-flex-sm-wrap {
      flex-wrap: wrap;
    }
  
    .p-flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }
  @media screen and (min-width: 768px) {
    .p-flex-md-nowrap {
      flex-wrap: nowrap;
    }
  
    .p-flex-md-wrap {
      flex-wrap: wrap;
    }
  
    .p-flex-md-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }
  @media screen and (min-width: 992px) {
    .p-flex-lg-nowrap {
      flex-wrap: nowrap;
    }
  
    .p-flex-lg-wrap {
      flex-wrap: wrap;
    }
  
    .p-flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-flex-xl-nowrap {
      flex-wrap: nowrap;
    }
  
    .p-flex-xl-wrap {
      flex-wrap: wrap;
    }
  
    .p-flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }
  .p-pt-0 {
    padding-top: 0 !important;
  }
  
  .p-pt-1 {
    padding-top: 0.25rem !important;
  }
  
  .p-pt-2 {
    padding-top: 0.5rem !important;
  }
  
  .p-pt-3 {
    padding-top: 1rem !important;
  }
  
  .p-pt-4 {
    padding-top: 1.5rem !important;
  }
  
  .p-pt-5 {
    padding-top: 2rem !important;
  }
  
  .p-pt-6 {
    padding-top: 3rem !important;
  }
  
  .p-pr-0 {
    padding-right: 0 !important;
  }
  
  .p-pr-1 {
    padding-right: 0.25rem !important;
  }
  
  .p-pr-2 {
    padding-right: 0.5rem !important;
  }
  
  .p-pr-3 {
    padding-right: 1rem !important;
  }
  
  .p-pr-4 {
    padding-right: 1.5rem !important;
  }
  
  .p-pr-5 {
    padding-right: 2rem !important;
  }
  
  .p-pr-6 {
    padding-right: 3rem !important;
  }
  
  .p-pl-0 {
    padding-left: 0 !important;
  }
  
  .p-pl-1 {
    padding-left: 0.25rem !important;
  }
  
  .p-pl-2 {
    padding-left: 0.5rem !important;
  }
  
  .p-pl-3 {
    padding-left: 1rem !important;
  }
  
  .p-pl-4 {
    padding-left: 1.5rem !important;
  }
  
  .p-pl-5 {
    padding-left: 2rem !important;
  }
  
  .p-pl-6 {
    padding-left: 3rem !important;
  }
  
  .p-pb-0 {
    padding-bottom: 0 !important;
  }
  
  .p-pb-1 {
    padding-bottom: 0.25rem !important;
  }
  
  .p-pb-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .p-pb-3 {
    padding-bottom: 1rem !important;
  }
  
  .p-pb-4 {
    padding-bottom: 1.5rem !important;
  }
  
  .p-pb-5 {
    padding-bottom: 2rem !important;
  }
  
  .p-pb-6 {
    padding-bottom: 3rem !important;
  }
  
  .p-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  .p-px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  
  .p-px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  
  .p-px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  
  .p-px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  
  .p-px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  
  .p-px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  
  .p-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .p-py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  
  .p-py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  
  .p-py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  
  .p-py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  
  .p-py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  
  .p-py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  
  .p-p-0 {
    padding: 0 !important;
  }
  
  .p-p-1 {
    padding: 0.25rem !important;
  }
  
  .p-p-2 {
    padding: 0.5rem !important;
  }
  
  .p-p-3 {
    padding: 1rem !important;
  }
  
  .p-p-4 {
    padding: 1.5rem !important;
  }
  
  .p-p-5 {
    padding: 2rem !important;
  }
  
  .p-p-6 {
    padding: 3rem !important;
  }
  
  @media screen and (min-width: 576px) {
    .p-pt-sm-0 {
      padding-top: 0 !important;
    }
  
    .p-pt-sm-1 {
      padding-top: 0.25rem !important;
    }
  
    .p-pt-sm-2 {
      padding-top: 0.5rem !important;
    }
  
    .p-pt-sm-3 {
      padding-top: 1rem !important;
    }
  
    .p-pt-sm-4 {
      padding-top: 1.5rem !important;
    }
  
    .p-pt-sm-5 {
      padding-top: 2rem !important;
    }
  
    .p-pt-sm-6 {
      padding-top: 3rem !important;
    }
  
    .p-pr-sm-0 {
      padding-right: 0 !important;
    }
  
    .p-pr-sm-1 {
      padding-right: 0.25rem !important;
    }
  
    .p-pr-sm-2 {
      padding-right: 0.5rem !important;
    }
  
    .p-pr-sm-3 {
      padding-right: 1rem !important;
    }
  
    .p-pr-sm-4 {
      padding-right: 1.5rem !important;
    }
  
    .p-pr-sm-5 {
      padding-right: 2rem !important;
    }
  
    .p-pr-sm-6 {
      padding-right: 3rem !important;
    }
  
    .p-pl-sm-0 {
      padding-left: 0 !important;
    }
  
    .p-pl-sm-1 {
      padding-left: 0.25rem !important;
    }
  
    .p-pl-sm-2 {
      padding-left: 0.5rem !important;
    }
  
    .p-pl-sm-3 {
      padding-left: 1rem !important;
    }
  
    .p-pl-sm-4 {
      padding-left: 1.5rem !important;
    }
  
    .p-pl-sm-5 {
      padding-left: 2rem !important;
    }
  
    .p-pl-sm-6 {
      padding-left: 3rem !important;
    }
  
    .p-pb-sm-0 {
      padding-bottom: 0 !important;
    }
  
    .p-pb-sm-1 {
      padding-bottom: 0.25rem !important;
    }
  
    .p-pb-sm-2 {
      padding-bottom: 0.5rem !important;
    }
  
    .p-pb-sm-3 {
      padding-bottom: 1rem !important;
    }
  
    .p-pb-sm-4 {
      padding-bottom: 1.5rem !important;
    }
  
    .p-pb-sm-5 {
      padding-bottom: 2rem !important;
    }
  
    .p-pb-sm-6 {
      padding-bottom: 3rem !important;
    }
  
    .p-px-sm-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .p-px-sm-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
  
    .p-px-sm-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  
    .p-px-sm-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  
    .p-px-sm-4 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  
    .p-px-sm-5 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  
    .p-px-sm-6 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  
    .p-py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  
    .p-py-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
  
    .p-py-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  
    .p-py-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  
    .p-py-sm-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
  
    .p-py-sm-5 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  
    .p-py-sm-6 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  
    .p-p-sm-0 {
      padding: 0 !important;
    }
  
    .p-p-sm-1 {
      padding: 0.25rem !important;
    }
  
    .p-p-sm-2 {
      padding: 0.5rem !important;
    }
  
    .p-p-sm-3 {
      padding: 1rem !important;
    }
  
    .p-p-sm-4 {
      padding: 1.5rem !important;
    }
  
    .p-p-sm-5 {
      padding: 2rem !important;
    }
  
    .p-p-sm-6 {
      padding: 3rem !important;
    }
  }
  @media screen and (min-width: 768px) {
    .p-pt-md-0 {
      padding-top: 0 !important;
    }
  
    .p-pt-md-1 {
      padding-top: 0.25rem !important;
    }
  
    .p-pt-md-2 {
      padding-top: 0.5rem !important;
    }
  
    .p-pt-md-3 {
      padding-top: 1rem !important;
    }
  
    .p-pt-md-4 {
      padding-top: 1.5rem !important;
    }
  
    .p-pt-md-5 {
      padding-top: 2rem !important;
    }
  
    .p-pt-md-6 {
      padding-top: 3rem !important;
    }
  
    .p-pr-md-0 {
      padding-right: 0 !important;
    }
  
    .p-pr-md-1 {
      padding-right: 0.25rem !important;
    }
  
    .p-pr-md-2 {
      padding-right: 0.5rem !important;
    }
  
    .p-pr-md-3 {
      padding-right: 1rem !important;
    }
  
    .p-pr-md-4 {
      padding-right: 1.5rem !important;
    }
  
    .p-pr-md-5 {
      padding-right: 2rem !important;
    }
  
    .p-pr-md-6 {
      padding-right: 3rem !important;
    }
  
    .p-pl-md-0 {
      padding-left: 0 !important;
    }
  
    .p-pl-md-1 {
      padding-left: 0.25rem !important;
    }
  
    .p-pl-md-2 {
      padding-left: 0.5rem !important;
    }
  
    .p-pl-md-3 {
      padding-left: 1rem !important;
    }
  
    .p-pl-md-4 {
      padding-left: 1.5rem !important;
    }
  
    .p-pl-md-5 {
      padding-left: 2rem !important;
    }
  
    .p-pl-md-6 {
      padding-left: 3rem !important;
    }
  
    .p-pb-md-0 {
      padding-bottom: 0 !important;
    }
  
    .p-pb-md-1 {
      padding-bottom: 0.25rem !important;
    }
  
    .p-pb-md-2 {
      padding-bottom: 0.5rem !important;
    }
  
    .p-pb-md-3 {
      padding-bottom: 1rem !important;
    }
  
    .p-pb-md-4 {
      padding-bottom: 1.5rem !important;
    }
  
    .p-pb-md-5 {
      padding-bottom: 2rem !important;
    }
  
    .p-pb-md-6 {
      padding-bottom: 3rem !important;
    }
  
    .p-px-md-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .p-px-md-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
  
    .p-px-md-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  
    .p-px-md-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  
    .p-px-md-4 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  
    .p-px-md-5 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  
    .p-px-md-6 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  
    .p-py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  
    .p-py-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
  
    .p-py-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  
    .p-py-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  
    .p-py-md-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
  
    .p-py-md-5 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  
    .p-py-md-6 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  
    .p-p-md-0 {
      padding: 0 !important;
    }
  
    .p-p-md-1 {
      padding: 0.25rem !important;
    }
  
    .p-p-md-2 {
      padding: 0.5rem !important;
    }
  
    .p-p-md-3 {
      padding: 1rem !important;
    }
  
    .p-p-md-4 {
      padding: 1.5rem !important;
    }
  
    .p-p-md-5 {
      padding: 2rem !important;
    }
  
    .p-p-md-6 {
      padding: 3rem !important;
    }
  }
  @media screen and (min-width: 992px) {
    .p-pt-lg-0 {
      padding-top: 0 !important;
    }
  
    .p-pt-lg-1 {
      padding-top: 0.25rem !important;
    }
  
    .p-pt-lg-2 {
      padding-top: 0.5rem !important;
    }
  
    .p-pt-lg-3 {
      padding-top: 1rem !important;
    }
  
    .p-pt-lg-4 {
      padding-top: 1.5rem !important;
    }
  
    .p-pt-lg-5 {
      padding-top: 2rem !important;
    }
  
    .p-pt-lg-6 {
      padding-top: 3rem !important;
    }
  
    .p-pt-lg-auto {
      padding-top: 3rem !important;
    }
  
    .p-pr-lg-0 {
      padding-right: 0 !important;
    }
  
    .p-pr-lg-1 {
      padding-right: 0.25rem !important;
    }
  
    .p-pr-lg-2 {
      padding-right: 0.5rem !important;
    }
  
    .p-pr-lg-3 {
      padding-right: 1rem !important;
    }
  
    .p-pr-lg-4 {
      padding-right: 1.5rem !important;
    }
  
    .p-pr-lg-5 {
      padding-right: 2rem !important;
    }
  
    .p-pr-lg-6 {
      padding-right: 3rem !important;
    }
  
    .p-pl-lg-0 {
      padding-left: 0 !important;
    }
  
    .p-pl-lg-1 {
      padding-left: 0.25rem !important;
    }
  
    .p-pl-lg-2 {
      padding-left: 0.5rem !important;
    }
  
    .p-pl-lg-3 {
      padding-left: 1rem !important;
    }
  
    .p-pl-lg-4 {
      padding-left: 1.5rem !important;
    }
  
    .p-pl-lg-5 {
      padding-left: 2rem !important;
    }
  
    .p-pl-lg-6 {
      padding-left: 3rem !important;
    }
  
    .p-pb-lg-0 {
      padding-bottom: 0 !important;
    }
  
    .p-pb-lg-1 {
      padding-bottom: 0.25rem !important;
    }
  
    .p-pb-lg-2 {
      padding-bottom: 0.5rem !important;
    }
  
    .p-pb-lg-3 {
      padding-bottom: 1rem !important;
    }
  
    .p-pb-lg-4 {
      padding-bottom: 1.5rem !important;
    }
  
    .p-pb-lg-5 {
      padding-bottom: 2rem !important;
    }
  
    .p-pb-lg-6 {
      padding-bottom: 3rem !important;
    }
  
    .p-px-lg-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .p-px-lg-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
  
    .p-px-lg-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  
    .p-px-lg-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  
    .p-px-lg-4 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  
    .p-px-lg-5 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  
    .p-px-lg-6 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  
    .p-py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  
    .p-py-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
  
    .p-py-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  
    .p-py-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  
    .p-py-lg-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
  
    .p-py-lg-5 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  
    .p-py-lg-6 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  
    .p-p-lg-0 {
      padding: 0 !important;
    }
  
    .p-p-lg-1 {
      padding: 0.25rem !important;
    }
  
    .p-p-lg-2 {
      padding: 0.5rem !important;
    }
  
    .p-p-lg-3 {
      padding: 1rem !important;
    }
  
    .p-p-lg-4 {
      padding: 1.5rem !important;
    }
  
    .p-p-lg-5 {
      padding: 2rem !important;
    }
  
    .p-p-lg-6 {
      padding: 3rem !important;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-pt-xl-0 {
      padding-top: 0 !important;
    }
  
    .p-pt-xl-1 {
      padding-top: 0.25rem !important;
    }
  
    .p-pt-xl-2 {
      padding-top: 0.5rem !important;
    }
  
    .p-pt-xl-3 {
      padding-top: 1rem !important;
    }
  
    .p-pt-xl-4 {
      padding-top: 1.5rem !important;
    }
  
    .p-pt-xl-5 {
      padding-top: 2rem !important;
    }
  
    .p-pt-xl-6 {
      padding-top: 3rem !important;
    }
  
    .p-pr-xl-0 {
      padding-right: 0 !important;
    }
  
    .p-pr-xl-1 {
      padding-right: 0.25rem !important;
    }
  
    .p-pr-xl-2 {
      padding-right: 0.5rem !important;
    }
  
    .p-pr-xl-3 {
      padding-right: 1rem !important;
    }
  
    .p-pr-xl-4 {
      padding-right: 1.5rem !important;
    }
  
    .p-pr-xl-5 {
      padding-right: 2rem !important;
    }
  
    .p-pr-xl-6 {
      padding-right: 3rem !important;
    }
  
    .p-pl-xl-0 {
      padding-left: 0 !important;
    }
  
    .p-pl-xl-1 {
      padding-left: 0.25rem !important;
    }
  
    .p-pl-xl-2 {
      padding-left: 0.5rem !important;
    }
  
    .p-pl-xl-3 {
      padding-left: 1rem !important;
    }
  
    .p-pl-xl-4 {
      padding-left: 1.5rem !important;
    }
  
    .p-pl-xl-5 {
      padding-left: 2rem !important;
    }
  
    .p-pl-xl-6 {
      padding-left: 3rem !important;
    }
  
    .p-pb-xl-0 {
      padding-bottom: 0 !important;
    }
  
    .p-pb-xl-1 {
      padding-bottom: 0.25rem !important;
    }
  
    .p-pb-xl-2 {
      padding-bottom: 0.5rem !important;
    }
  
    .p-pb-xl-3 {
      padding-bottom: 1rem !important;
    }
  
    .p-pb-xl-4 {
      padding-bottom: 1.5rem !important;
    }
  
    .p-pb-xl-5 {
      padding-bottom: 2rem !important;
    }
  
    .p-pb-xl-6 {
      padding-bottom: 3rem !important;
    }
  
    .p-px-xl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .p-px-xl-1 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
  
    .p-px-xl-2 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  
    .p-px-xl-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  
    .p-px-xl-4 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  
    .p-px-xl-5 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  
    .p-px-xl-6 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  
    .p-py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  
    .p-py-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
  
    .p-py-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  
    .p-py-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  
    .p-py-xl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
  
    .p-py-xl-5 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  
    .p-py-xl-6 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  
    .p-p-xl-0 {
      padding: 0 !important;
    }
  
    .p-p-xl-1 {
      padding: 0.25rem !important;
    }
  
    .p-p-xl-2 {
      padding: 0.5rem !important;
    }
  
    .p-p-xl-3 {
      padding: 1rem !important;
    }
  
    .p-p-xl-4 {
      padding: 1.5rem !important;
    }
  
    .p-p-xl-5 {
      padding: 2rem !important;
    }
  
    .p-p-xl-6 {
      padding: 3rem !important;
    }
  }
  .p-mt-0 {
    margin-top: 0 !important;
  }
  
  .p-mt-1 {
    margin-top: 0.25rem !important;
  }
  
  .p-mt-2 {
    margin-top: 0.5rem !important;
  }
  
  .p-mt-3 {
    margin-top: 1rem !important;
  }
  
  .p-mt-4 {
    margin-top: 1.5rem !important;
  }
  
  .p-mt-5 {
    margin-top: 2rem !important;
  }
  
  .p-mt-6 {
    margin-top: 3rem !important;
  }
  
  .p-mt-auto {
    margin-top: auto !important;
  }
  
  .p-mr-0 {
    margin-right: 0 !important;
  }
  
  .p-mr-1 {
    margin-right: 0.25rem !important;
  }
  
  .p-mr-2 {
    margin-right: 0.5rem !important;
  }
  
  .p-mr-3 {
    margin-right: 1rem !important;
  }
  
  .p-mr-4 {
    margin-right: 1.5rem !important;
  }
  
  .p-mr-5 {
    margin-right: 2rem !important;
  }
  
  .p-mr-6 {
    margin-right: 3rem !important;
  }
  
  .p-mr-auto {
    margin-right: auto !important;
  }
  
  .p-ml-0 {
    margin-left: 0 !important;
  }
  
  .p-ml-1 {
    margin-left: 0.25rem !important;
  }
  
  .p-ml-2 {
    margin-left: 0.5rem !important;
  }
  
  .p-ml-3 {
    margin-left: 1rem !important;
  }
  
  .p-ml-4 {
    margin-left: 1.5rem !important;
  }
  
  .p-ml-5 {
    margin-left: 2rem !important;
  }
  
  .p-ml-6 {
    margin-left: 3rem !important;
  }
  
  .p-ml-auto {
    margin-left: auto !important;
  }
  
  .p-mb-0 {
    margin-bottom: 0 !important;
  }
  
  .p-mb-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .p-mb-2 {
    margin-bottom: 0.5rem !important;
  }
  
  .p-mb-3 {
    margin-bottom: 1rem !important;
  }
  
  .p-mb-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .p-mb-5 {
    margin-bottom: 2rem !important;
  }
  
  .p-mb-6 {
    margin-bottom: 3rem !important;
  }
  
  .p-mb-auto {
    margin-bottom: auto !important;
  }
  
  .p-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  .p-mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  
  .p-mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  
  .p-mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  
  .p-mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  
  .p-mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  
  .p-mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  
  .p-mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
  .p-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .p-my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  
  .p-my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  
  .p-my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  
  .p-my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  
  .p-my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  
  .p-my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  
  .p-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  
  .p-m-0 {
    margin: 0 !important;
  }
  
  .p-m-1 {
    margin: 0.25rem !important;
  }
  
  .p-m-2 {
    margin: 0.5rem !important;
  }
  
  .p-m-3 {
    margin: 1rem !important;
  }
  
  .p-m-4 {
    margin: 1.5rem !important;
  }
  
  .p-m-5 {
    margin: 2rem !important;
  }
  
  .p-m-6 {
    margin: 3rem !important;
  }
  
  .p-m-auto {
    margin: auto !important;
  }
  
  @media screen and (min-width: 576px) {
    .p-mt-sm-0 {
      margin-top: 0 !important;
    }
  
    .p-mt-sm-1 {
      margin-top: 0.25rem !important;
    }
  
    .p-mt-sm-2 {
      margin-top: 0.5rem !important;
    }
  
    .p-mt-sm-3 {
      margin-top: 1rem !important;
    }
  
    .p-mt-sm-4 {
      margin-top: 1.5rem !important;
    }
  
    .p-mt-sm-5 {
      margin-top: 2rem !important;
    }
  
    .p-mt-sm-6 {
      margin-top: 3rem !important;
    }
  
    .p-mt-sm-auto {
      margin-top: 3rem !important;
    }
  
    .p-mr-sm-0 {
      margin-right: 0 !important;
    }
  
    .p-mr-sm-1 {
      margin-right: 0.25rem !important;
    }
  
    .p-mr-sm-2 {
      margin-right: 0.5rem !important;
    }
  
    .p-mr-sm-3 {
      margin-right: 1rem !important;
    }
  
    .p-mr-sm-4 {
      margin-right: 1.5rem !important;
    }
  
    .p-mr-sm-5 {
      margin-right: 2rem !important;
    }
  
    .p-mr-sm-6 {
      margin-right: 3rem !important;
    }
  
    .p-mr-sm-auto {
      margin-right: auto !important;
    }
  
    .p-ml-sm-0 {
      margin-left: 0 !important;
    }
  
    .p-ml-sm-1 {
      margin-left: 0.25rem !important;
    }
  
    .p-ml-sm-2 {
      margin-left: 0.5rem !important;
    }
  
    .p-ml-sm-3 {
      margin-left: 1rem !important;
    }
  
    .p-ml-sm-4 {
      margin-left: 1.5rem !important;
    }
  
    .p-ml-sm-5 {
      margin-left: 2rem !important;
    }
  
    .p-ml-sm-6 {
      margin-left: 3rem !important;
    }
  
    .p-ml-sm-auto {
      margin-left: auto !important;
    }
  
    .p-mb-sm-0 {
      margin-bottom: 0 !important;
    }
  
    .p-mb-sm-1 {
      margin-bottom: 0.25rem !important;
    }
  
    .p-mb-sm-2 {
      margin-bottom: 0.5rem !important;
    }
  
    .p-mb-sm-3 {
      margin-bottom: 1rem !important;
    }
  
    .p-mb-sm-4 {
      margin-bottom: 1.5rem !important;
    }
  
    .p-mb-sm-5 {
      margin-bottom: 2rem !important;
    }
  
    .p-mb-sm-6 {
      margin-bottom: 3rem !important;
    }
  
    .p-mb-sm-auto {
      margin-bottom: auto !important;
    }
  
    .p-mx-sm-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    .p-mx-sm-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
  
    .p-mx-sm-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
  
    .p-mx-sm-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  
    .p-mx-sm-4 {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }
  
    .p-mx-sm-5 {
      margin-left: 2rem !important;
      margin-right: 2rem !important;
    }
  
    .p-mx-sm-6 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
  
    .p-mx-sm-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  
    .p-my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  
    .p-my-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
  
    .p-my-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
  
    .p-my-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  
    .p-my-sm-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
  
    .p-my-sm-5 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
  
    .p-my-sm-6 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
  
    .p-my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  
    .p-m-sm-0 {
      margin: 0 !important;
    }
  
    .p-m-sm-1 {
      margin: 0.25rem !important;
    }
  
    .p-m-sm-2 {
      margin: 0.5rem !important;
    }
  
    .p-m-sm-3 {
      margin: 1rem !important;
    }
  
    .p-m-sm-4 {
      margin: 1.5rem !important;
    }
  
    .p-m-sm-5 {
      margin: 2rem !important;
    }
  
    .p-m-sm-6 {
      margin: 3rem !important;
    }
  
    .p-m-sm-auto {
      margin: auto !important;
    }
  }
  @media screen and (min-width: 768px) {
    .p-mt-md-0 {
      margin-top: 0 !important;
    }
  
    .p-mt-md-1 {
      margin-top: 0.25rem !important;
    }
  
    .p-mt-md-2 {
      margin-top: 0.5rem !important;
    }
  
    .p-mt-md-3 {
      margin-top: 1rem !important;
    }
  
    .p-mt-md-4 {
      margin-top: 1.5rem !important;
    }
  
    .p-mt-md-5 {
      margin-top: 2rem !important;
    }
  
    .p-mt-md-6 {
      margin-top: 3rem !important;
    }
  
    .p-mt-md-auto {
      margin-top: 3rem !important;
    }
  
    .p-mr-md-0 {
      margin-right: 0 !important;
    }
  
    .p-mr-md-1 {
      margin-right: 0.25rem !important;
    }
  
    .p-mr-md-2 {
      margin-right: 0.5rem !important;
    }
  
    .p-mr-md-3 {
      margin-right: 1rem !important;
    }
  
    .p-mr-md-4 {
      margin-right: 1.5rem !important;
    }
  
    .p-mr-md-5 {
      margin-right: 2rem !important;
    }
  
    .p-mr-md-6 {
      margin-right: 3rem !important;
    }
  
    .p-mr-md-auto {
      margin-right: auto !important;
    }
  
    .p-ml-md-0 {
      margin-left: 0 !important;
    }
  
    .p-ml-md-1 {
      margin-left: 0.25rem !important;
    }
  
    .p-ml-md-2 {
      margin-left: 0.5rem !important;
    }
  
    .p-ml-md-3 {
      margin-left: 1rem !important;
    }
  
    .p-ml-md-4 {
      margin-left: 1.5rem !important;
    }
  
    .p-ml-md-5 {
      margin-left: 2rem !important;
    }
  
    .p-ml-md-6 {
      margin-left: 3rem !important;
    }
  
    .p-ml-md-auto {
      margin-left: auto !important;
    }
  
    .p-mb-md-0 {
      margin-bottom: 0 !important;
    }
  
    .p-mb-md-1 {
      margin-bottom: 0.25rem !important;
    }
  
    .p-mb-md-2 {
      margin-bottom: 0.5rem !important;
    }
  
    .p-mb-md-3 {
      margin-bottom: 1rem !important;
    }
  
    .p-mb-md-4 {
      margin-bottom: 1.5rem !important;
    }
  
    .p-mb-md-5 {
      margin-bottom: 2rem !important;
    }
  
    .p-mb-md-6 {
      margin-bottom: 3rem !important;
    }
  
    .p-mb-md-auto {
      margin-bottom: auto !important;
    }
  
    .p-mx-md-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    .p-mx-md-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
  
    .p-mx-md-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
  
    .p-mx-md-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  
    .p-mx-md-4 {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }
  
    .p-mx-md-5 {
      margin-left: 2rem !important;
      margin-right: 2rem !important;
    }
  
    .p-mx-md-6 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
  
    .p-mx-md-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  
    .p-my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  
    .p-my-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
  
    .p-my-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
  
    .p-my-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  
    .p-my-md-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
  
    .p-my-md-5 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
  
    .p-my-md-6 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
  
    .p-my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  
    .p-m-md-0 {
      margin: 0 !important;
    }
  
    .p-m-md-1 {
      margin: 0.25rem !important;
    }
  
    .p-m-md-2 {
      margin: 0.5rem !important;
    }
  
    .p-m-md-3 {
      margin: 1rem !important;
    }
  
    .p-m-md-4 {
      margin: 1.5rem !important;
    }
  
    .p-m-md-5 {
      margin: 2rem !important;
    }
  
    .p-m-md-6 {
      margin: 3rem !important;
    }
  
    .p-m-md-auto {
      margin: auto !important;
    }
  }
  @media screen and (min-width: 992px) {
    .p-mt-lg-0 {
      margin-top: 0 !important;
    }
  
    .p-mt-lg-1 {
      margin-top: 0.25rem !important;
    }
  
    .p-mt-lg-2 {
      margin-top: 0.5rem !important;
    }
  
    .p-mt-lg-3 {
      margin-top: 1rem !important;
    }
  
    .p-mt-lg-4 {
      margin-top: 1.5rem !important;
    }
  
    .p-mt-lg-5 {
      margin-top: 2rem !important;
    }
  
    .p-mt-lg-6 {
      margin-top: 3rem !important;
    }
  
    .p-mt-lg-auto {
      margin-top: 3rem !important;
    }
  
    .p-mr-lg-0 {
      margin-right: 0 !important;
    }
  
    .p-mr-lg-1 {
      margin-right: 0.25rem !important;
    }
  
    .p-mr-lg-2 {
      margin-right: 0.5rem !important;
    }
  
    .p-mr-lg-3 {
      margin-right: 1rem !important;
    }
  
    .p-mr-lg-4 {
      margin-right: 1.5rem !important;
    }
  
    .p-mr-lg-5 {
      margin-right: 2rem !important;
    }
  
    .p-mr-lg-6 {
      margin-right: 3rem !important;
    }
  
    .p-mr-lg-auto {
      margin-right: auto !important;
    }
  
    .p-ml-lg-0 {
      margin-left: 0 !important;
    }
  
    .p-ml-lg-1 {
      margin-left: 0.25rem !important;
    }
  
    .p-ml-lg-2 {
      margin-left: 0.5rem !important;
    }
  
    .p-ml-lg-3 {
      margin-left: 1rem !important;
    }
  
    .p-ml-lg-4 {
      margin-left: 1.5rem !important;
    }
  
    .p-ml-lg-5 {
      margin-left: 2rem !important;
    }
  
    .p-ml-lg-6 {
      margin-left: 3rem !important;
    }
  
    .p-ml-lg-auto {
      margin-left: auto !important;
    }
  
    .p-mb-lg-0 {
      margin-bottom: 0 !important;
    }
  
    .p-mb-lg-1 {
      margin-bottom: 0.25rem !important;
    }
  
    .p-mb-lg-2 {
      margin-bottom: 0.5rem !important;
    }
  
    .p-mb-lg-3 {
      margin-bottom: 1rem !important;
    }
  
    .p-mb-lg-4 {
      margin-bottom: 1.5rem !important;
    }
  
    .p-mb-lg-5 {
      margin-bottom: 2rem !important;
    }
  
    .p-mb-lg-6 {
      margin-bottom: 3rem !important;
    }
  
    .p-mb-lg-auto {
      margin-bottom: auto !important;
    }
  
    .p-mx-lg-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    .p-mx-lg-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
  
    .p-mx-lg-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
  
    .p-mx-lg-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  
    .p-mx-lg-4 {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }
  
    .p-mx-lg-5 {
      margin-left: 2rem !important;
      margin-right: 2rem !important;
    }
  
    .p-mx-lg-6 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
  
    .p-mx-lg-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  
    .p-my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  
    .p-my-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
  
    .p-my-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
  
    .p-my-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  
    .p-my-lg-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
  
    .p-my-lg-5 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
  
    .p-my-lg-6 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
  
    .p-my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  
    .p-m-lg-0 {
      margin: 0 !important;
    }
  
    .p-m-lg-1 {
      margin: 0.25rem !important;
    }
  
    .p-m-lg-2 {
      margin: 0.5rem !important;
    }
  
    .p-m-lg-3 {
      margin: 1rem !important;
    }
  
    .p-m-lg-4 {
      margin: 1.5rem !important;
    }
  
    .p-m-lg-5 {
      margin: 2rem !important;
    }
  
    .p-m-lg-6 {
      margin: 3rem !important;
    }
  
    .p-m-lg-auto {
      margin: auto !important;
    }
  }
  @media screen and (min-width: 1200px) {
    .p-mt-xl-0 {
      margin-top: 0 !important;
    }
  
    .p-mt-xl-1 {
      margin-top: 0.25rem !important;
    }
  
    .p-mt-xl-2 {
      margin-top: 0.5rem !important;
    }
  
    .p-mt-xl-3 {
      margin-top: 1rem !important;
    }
  
    .p-mt-xl-4 {
      margin-top: 1.5rem !important;
    }
  
    .p-mt-xl-5 {
      margin-top: 2rem !important;
    }
  
    .p-mt-xl-6 {
      margin-top: 3rem !important;
    }
  
    .p-mt-xl-auto {
      margin-top: 3rem !important;
    }
  
    .p-mr-xl-0 {
      margin-right: 0 !important;
    }
  
    .p-mr-xl-1 {
      margin-right: 0.25rem !important;
    }
  
    .p-mr-xl-2 {
      margin-right: 0.5rem !important;
    }
  
    .p-mr-xl-3 {
      margin-right: 1rem !important;
    }
  
    .p-mr-xl-4 {
      margin-right: 1.5rem !important;
    }
  
    .p-mr-xl-5 {
      margin-right: 2rem !important;
    }
  
    .p-mr-xl-6 {
      margin-right: 3rem !important;
    }
  
    .p-mr-xl-auto {
      margin-right: auto !important;
    }
  
    .p-ml-xl-0 {
      margin-left: 0 !important;
    }
  
    .p-ml-xl-1 {
      margin-left: 0.25rem !important;
    }
  
    .p-ml-xl-2 {
      margin-left: 0.5rem !important;
    }
  
    .p-ml-xl-3 {
      margin-left: 1rem !important;
    }
  
    .p-ml-xl-4 {
      margin-left: 1.5rem !important;
    }
  
    .p-ml-xl-5 {
      margin-left: 2rem !important;
    }
  
    .p-ml-xl-6 {
      margin-left: 3rem !important;
    }
  
    .p-ml-xl-auto {
      margin-left: auto !important;
    }
  
    .p-mb-xl-0 {
      margin-bottom: 0 !important;
    }
  
    .p-mb-xl-1 {
      margin-bottom: 0.25rem !important;
    }
  
    .p-mb-xl-2 {
      margin-bottom: 0.5rem !important;
    }
  
    .p-mb-xl-3 {
      margin-bottom: 1rem !important;
    }
  
    .p-mb-xl-4 {
      margin-bottom: 1.5rem !important;
    }
  
    .p-mb-xl-5 {
      margin-bottom: 2rem !important;
    }
  
    .p-mb-xl-6 {
      margin-bottom: 3rem !important;
    }
  
    .p-mb-xl-auto {
      margin-bottom: auto !important;
    }
  
    .p-mx-xl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    .p-mx-xl-1 {
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
  
    .p-mx-xl-2 {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
  
    .p-mx-xl-3 {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  
    .p-mx-xl-4 {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }
  
    .p-mx-xl-5 {
      margin-left: 2rem !important;
      margin-right: 2rem !important;
    }
  
    .p-mx-xl-6 {
      margin-left: 3rem !important;
      margin-right: 3rem !important;
    }
  
    .p-mx-xl-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  
    .p-my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  
    .p-my-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
  
    .p-my-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
  
    .p-my-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  
    .p-my-xl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
  
    .p-my-xl-5 {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
  
    .p-my-xl-6 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
  
    .p-my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  
    .p-m-xl-0 {
      margin: 0 !important;
    }
  
    .p-m-xl-1 {
      margin: 0.25rem !important;
    }
  
    .p-m-xl-2 {
      margin: 0.5rem !important;
    }
  
    .p-m-xl-3 {
      margin: 1rem !important;
    }
  
    .p-m-xl-4 {
      margin: 1.5rem !important;
    }
  
    .p-m-xl-5 {
      margin: 2rem !important;
    }
  
    .p-m-xl-6 {
      margin: 3rem !important;
    }
  
    .p-m-xl-auto {
      margin: auto !important;
    }
  }
  .p-shadow-1 {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-2 {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-3 {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-4 {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-5 {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-6 {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-7 {
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-8 {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-9 {
    box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-10 {
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-11 {
    box-shadow: 0 6px 7px -4px rgba(0, 0, 0, 0.2), 0 11px 15px 1px rgba(0, 0, 0, 0.14), 0 4px 20px 3px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-12 {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-13 {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-14 {
    box-shadow: 0 7px 9px -4px rgba(0, 0, 0, 0.2), 0 14px 21px 2px rgba(0, 0, 0, 0.14), 0 5px 26px 4px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-15 {
    box-shadow: 0 8px 9px -5px rgba(0, 0, 0, 0.2), 0 15px 22px 2px rgba(0, 0, 0, 0.14), 0 6px 28px 5px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-16 {
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-17 {
    box-shadow: 0 8px 11px -5px rgba(0, 0, 0, 0.2), 0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-18 {
    box-shadow: 0 9px 11px -5px rgba(0, 0, 0, 0.2), 0 18px 28px 2px rgba(0, 0, 0, 0.14), 0 7px 34px 6px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-19 {
    box-shadow: 0 9px 12px -6px rgba(0, 0, 0, 0.2), 0 19px 29px 2px rgba(0, 0, 0, 0.14), 0 7px 36px 6px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-20 {
    box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 20px 31px 3px rgba(0, 0, 0, 0.14), 0 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-21 {
    box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 21px 33px 3px rgba(0, 0, 0, 0.14), 0 8px 40px 7px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-22 {
    box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.2), 0 22px 35px 3px rgba(0, 0, 0, 0.14), 0 8px 42px 7px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-23 {
    box-shadow: 0 11px 14px -7px rgba(0, 0, 0, 0.2), 0 23px 36px 3px rgba(0, 0, 0, 0.14), 0 9px 44px 8px rgba(0, 0, 0, 0.12);
  }
  
  .p-shadow-24 {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  .auth{

    .btn-open-menu{
      cursor: pointer;
      display: block;
      position: absolute;
      top:10px;
      max-width:  36px;
      max-height: 36px;
      
      img{
        pointer-events: none;
      }
    }
    
  }
  .btn-open-menu{
    display: none;
  }
  